import { useLazyQuery, useQuery } from '@apollo/client';

import { GetCacheProfile, getJourneyByCodeQuery } from '../../models/graphql';
import { useDataLayer } from '../../services/hooks/useDataLayer';
import { useAddAction } from '../hooks/useAddAction';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useGetJourneyByCode = () => {
  const [
    getJourneyByCode,
    {
      refetch: getJourneyByCodeRefetch,
      called,
    },
  ] = useLazyQuery(
    getJourneyByCodeQuery,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const { data: cachedProfileData } = useQuery(GetCacheProfile);

  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const { trackEvent } = useDataLayer();
  const { addAction } = useAddAction();

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.getJourneyByDriverCode);
  };

  return (
    code: string,
    callback: (data: any) => void,
  ) => {
    if (cachedProfileData.accessToken) {
      return offlineProofQuery(() => authenticatedQuery((accessToken) => {
        if (called) {
          getJourneyByCodeRefetch({ code: code.toUpperCase(), accessToken })
            .then((res) => {
              handleNewData(res, callback);
            })
            .finally(() => {
              trackEvent({
                event: 'token-tracking',
                accessToken,
                url: window.location.href,
                fromRefetch: true,
              });
              addAction('token-tracking', {
                accessToken,
                url: window.location.href,
                fromRefetch: true,
              });
            });
        }

        getJourneyByCode({ variables: { code: code.toUpperCase(), accessToken } })
          .then((res) => {
            handleNewData(res, callback);
          })
          .finally(() => {
            trackEvent({
              event: 'token-tracking',
              accessToken,
              url: window.location.href,
              fromRefetch: false,
            });
            addAction('token-tracking', {
              accessToken,
              url: window.location.href,
              fromRefetch: false,
            });
          });
      }));
    }
    if (called) {
      return getJourneyByCodeRefetch({ code: code.toUpperCase() })
        .then((res) => {
          handleNewData(res, callback);
        })
        .finally(() => {
          trackEvent({
            event: 'token-tracking',
            url: window.location.href,
            fromRefetch: true,
          });
        });
    }

    return getJourneyByCode({ variables: { code: code.toUpperCase() } })
      .then((res) => {
        handleNewData(res, callback);
      });
  };
};
