import { gql } from '@apollo/client/core';

const createVehicle = gql`mutation createVehicle($driverId: Int!, $serieId: Int!, $plate: String!, $accessToken: String!) {
  createVehicle(driverId: $driverId, serieId: $serieId, plate: $plate, accessToken: $accessToken) {
    success
    message
    modelName
    makeName
    seriesName
    id
    driverId
    licensePlate
  }
}`;

export default createVehicle;
