import './fileUpload.scss';

import React, { ChangeEventHandler, FunctionComponent, ReactElement } from 'react';

type FileUploadProps = {
  onChange: ChangeEventHandler;
  file?: string;
  className?: string;
  text: string;
  icon?: ReactElement;
}

const FileUploadSecond: FunctionComponent<FileUploadProps> = ({
  onChange,
  file = '',
  className = '',
  text = 'Upload',
  icon,
}) => (
  <div datatest-id="file-upload" className={`d-flex flex-column ${className}`}>
    <div className="file-upload-component">
      <label htmlFor="file-upload-input-second">
        <span data-testid="upload_other-proof" className="d-flex">
          {icon && <span className="m-r-14 d-flex align-self-center">{icon}</span>}
          {text}
        </span>
      </label>
      <input
        id="file-upload-input-second"
        type="file"
        onChange={onChange}
      />
    </div>
  </div>
);

export default FileUploadSecond;
