import { gql } from '@apollo/client/core';

const getPhoneByLocationQuery = gql`
    query getPhoneByLocation($contactType: String!, $lat: String!, $lng: String!, $language: String) {
        getPhoneByLocation(contactType: $contactType, lat: $lat, lng: $lng, language: $language) {
          success
          message
          phone
      }
    }
`;

export default getPhoneByLocationQuery;
