import { useMutation } from '@apollo/client';

import { CacheProfileEmpty } from '../../models/CacheProfile';
import { NewSessionMutation } from '../../models/graphql';
import { useSetCacheProfile } from '../apolloCacheHooks';

export const useNewSession = () => {
  const setCacheProfile = useSetCacheProfile();
  const [newSession] = useMutation(NewSessionMutation, {
    variables: {
      userAgent: navigator.userAgent,
    },
    fetchPolicy: 'no-cache',
  });

  return (callback?: (data: any) => void) => {
    newSession().then((res: any) => {
      const token = res?.data?.newSession?.token;
      const id = res?.data?.newSession?.driverId;

      if (token) {
        setCacheProfile({
          ...CacheProfileEmpty,
          token,
          driverId: id,
        });

        if (callback) callback(token);
      }
      else if (callback) callback(null);
    });
  };
};
