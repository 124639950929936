import BriefcaseIcon from '@transferz/fe-component-library/briefcase-icon';
import CalendarIcon from '@transferz/fe-component-library/calendar-icon';
import CarRightIcon from '@transferz/fe-component-library/car-right-icon';
import ClockIcon from '@transferz/fe-component-library/clock-icon';
import EyeIcon from '@transferz/fe-component-library/eye-icon';
import HashtagIcon from '@transferz/fe-component-library/hashtag-icon';
import LocationIcon from '@transferz/fe-component-library/location-icon';
import MapIcon from '@transferz/fe-component-library/map-icon';
import MeetingPoint from '@transferz/fe-component-library/meeting-point';
import MeetingPointIcon from '@transferz/fe-component-library/meeting-point-icon';
import PersonIcon from '@transferz/fe-component-library/person-icon';
import PlaneIcon from '@transferz/fe-component-library/plane-icon';
import PointOfInterestIcon from '@transferz/fe-component-library/point-of-interest-icon';
import SeaportIcon from '@transferz/fe-component-library/seaport-icon';
import TrainIcon from '@transferz/fe-component-library/train-icon';
import { useLocalize } from 'localize-react';
import React, { FunctionComponent, useState } from 'react';

import { Journey } from '../../types/journey';
import { JourneyStatus } from '../../types/journeyStatus';
import { parseDate, parseString } from '../../utils/parsingFunctions';
import useIsIos from '../../utils/useIsIos';
import useIsMobile from '../../utils/useIsMobile';
import MeetSign from '../Meet&GreetSign';
import JourneyInfoDetail from './JourneyInfoDetail';

type Props = {
  journey: Journey;
  status: JourneyStatus;
};

const JourneyInfo: FunctionComponent<Props> = ({ journey, status }) => {
  const { translate } = useLocalize();
  const {
    execution: { pickupDate },
    traveller: { meetAndGreetSignText, passengerCount, luggageCount },
    execution: { vehicleCategory },
  } = journey;

  const date = parseDate(pickupDate);
  const time = pickupDate.split('T')[1].slice(0, -3);
  const isIos = useIsIos();
  const isIMobile = useIsMobile();
  const [showSignText, setShowSignText] = useState<boolean>(false);
  const isAirport = (hubType: string) => hubType === 'AIRPORT';

  const openMap = (mapLocation: any) => {
    const address = mapLocation?.address?.originalAddress;

    if (address) {
      if (isIMobile) {
        window.open(isIos ? `maps:q=${encodeURIComponent(address)}` : `geo:0,0?q=${encodeURIComponent(address)}`);
      }
      else {
        window.open(`https://maps.google.com/?q=${encodeURIComponent(address)}`);
      }
    }
  };

  const startedJourney = Object.keys(JourneyStatus).indexOf(status) > 1;

  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'AIRPORT':
        return <PlaneIcon width={24} />;
      case 'SEAPORT':
        return <SeaportIcon width={20} />;
      case 'TRAIN_STATION':
        return <TrainIcon width={24} className="m-r-3" />;
      case 'POINT_OF_INTEREST':
        return <PointOfInterestIcon width={20} />;
      default:
        return <LocationIcon height={20} />;
    }
  };

  const originLocation = journey?.originLocation?.address;
  const destinationLocation = journey?.destinationLocation?.address;

  const renderAddressHeader = (originalAddress: string, formattedAddress: string, hubType: string) => {
    if (originalAddress) {
      return isAirport(hubType) ? originalAddress.slice(0, originalAddress.indexOf(',')) : originalAddress;
    }
    return isAirport(hubType) ? formattedAddress.slice(0, formattedAddress.indexOf(',')) : formattedAddress;
  };

  return (
    <div className="journey-info p-16">
      <JourneyInfoDetail
        icon={renderIcon(journey?.originLocation?.hubInfo?.hubType || '')}
        title={
          renderAddressHeader(
            originLocation?.originalAddress,
            originLocation.formattedAddress,
            journey?.originLocation?.hubInfo?.hubType,
          ) || 'N/A'
        }
        text={
          !originLocation?.originalAddress || isAirport(journey?.originLocation?.hubInfo?.hubType)
            ? ''
            : originLocation.formattedAddress
        }
        rightIcon={<MapIcon width={24} color="light" />}
        onClick={() => openMap(journey?.originLocation)}
        className="pointer m-b-16"
      />
      {journey?.meetingPoint.title && (
        <MeetingPoint meetingPoint={journey.meetingPoint.title} className="m-b-16 m-l-38" />
      )}
      {journey.traveller.trainNumber && (
        <JourneyInfoDetail
          title={journey.traveller.trainNumber}
          icon={<TrainIcon width={20} className="m-r-3" />}
          className="m-b-20 pointer"
        />
      )}
      <JourneyInfoDetail
        title={
          renderAddressHeader(
            destinationLocation?.originalAddress,
            destinationLocation.formattedAddress,
            journey?.destinationLocation?.hubInfo?.hubType,
          ) || 'N/A'
        }
        icon={renderIcon(journey?.destinationLocation?.hubInfo?.hubType || '')}
        text={
          !destinationLocation?.originalAddress || isAirport(journey?.destinationLocation?.hubInfo?.hubType)
            ? ''
            : destinationLocation.formattedAddress
        }
        rightIcon={<MapIcon width={24} color="light" />}
        onClick={() => openMap(journey?.destinationLocation)}
        className="m-b-20 pointer"
      />
      <div className="d-flex flex-direction-row justify-content-space-between m-t-16 m-b-35">
        <JourneyInfoDetail title={date} icon={<CalendarIcon width={24} />} className="w-6" />
        <JourneyInfoDetail title={time} icon={<ClockIcon width={24} direction="right" />} className="w-6" />
      </div>
      <div className="d-flex flex-direction-row justify-content-space-between m-t-16 m-b-35">
        <JourneyInfoDetail className="w-6" title={passengerCount.toString()} icon={<PersonIcon width={24} />} />

        <JourneyInfoDetail className="w-6" title={luggageCount.toString()} icon={<BriefcaseIcon width={24} />} />
      </div>
      <JourneyInfoDetail
        title={translate(parseString(vehicleCategory))}
        icon={<CarRightIcon width={24} direction="right" />}
        className="m-t-16 m-b-20"
      />
      <JourneyInfoDetail
        title={meetAndGreetSignText ? `"${meetAndGreetSignText}"` : 'N/A'}
        icon={<MeetingPointIcon width={24} />}
        text={translate('sign')}
        rightIcon={startedJourney && meetAndGreetSignText ? <EyeIcon width={24} fill="white" /> : undefined}
        onClick={() => {
          if (startedJourney && meetAndGreetSignText) {
            setShowSignText(true);
          }
        }}
        className="m-t-16 m-b-8"
      />
      <JourneyInfoDetail
        title={journey?.journeyCode}
        icon={<HashtagIcon width={24} />}
        text={translate('journey_code')}
      />

      {showSignText && (
        <MeetSign
          name={meetAndGreetSignText ?? `${journey?.traveller.firstName} ${journey?.traveller.lastName}`}
          handleClick={() => {
            setShowSignText(false);
          }}
        />
      )}
    </div>
  );
};

export default JourneyInfo;
