import { gql } from '@apollo/client';

const SetCacheJourneys = gql`
  mutation setCachedJourneys {
    currentPage @client
    pageSize @client
    totalPages @client
    totalResults @client
    items @client
  }
`;

export default SetCacheJourneys;
