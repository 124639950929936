import Accordion from '@transferz/fe-component-library/accordion';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, { FunctionComponent } from 'react';

type FAQType = {
  title: string;
  text: string;
}

const FAQ: FunctionComponent = () => {
  const { translate } = useLocalize();

  const faqs: FAQType[] = [
    {
      title: translate('FAQ_title1'),
      text: translate('FAQ_text1'),
    },
    {
      title: translate('FAQ_title2'),
      text: translate('FAQ_text2'),
    },
    {
      title: translate('faq_no_show_title'),
      text: translate('faq_no_show_text'),
    },
    {
      title: translate('faq_running_late_title'),
      text: translate('faq_running_late_text'),
    },
  ];
  return (
    <div>
      <Typography
        variant="h5"
        color="dark"
        className="m-t-32 m-b-16"
      >
        {translate('asked_questions')}
      </Typography>
      {faqs.map((faqItem: FAQType, idx: number) => (
        <Accordion
          title={faqItem.title}
          text={faqItem.text}
          className="m-b-8"
          key={idx}
        />
      ))}
    </div>
  );
};

export default FAQ;
