import { gql } from '@apollo/client/core';

const createAction = gql`
  query createAction($journeyId: Int!, $type: String, $position: Position $accessToken: String!) {
    createAction(journeyId: $journeyId, type: $type, position: $position, accessToken: $accessToken) {
      success
      message
      id
    }
  }
`;

export default createAction;
