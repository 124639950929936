import { gql } from '@apollo/client/core';

const updateDriverPositionMutation = gql`mutation updateDriverPosition($id: Int!, $lat: Float!, $lng: Float!, $accessToken: String!) {
  updateDriverPosition(id: $id, lat: $lat, lng: $lng, accessToken: $accessToken) {
      success
      message
  }
}`;

export default updateDriverPositionMutation;
