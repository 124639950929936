import { gql } from '@apollo/client/core';

const pruneJourneysQuery = gql`mutation pruneJourneys($sessionToken: String!, $includedStatuses: [String!]) {
  pruneJourneys(sessionToken: $sessionToken, includedStatuses: $includedStatuses) {
    success
    message
  }
}`;

export default pruneJourneysQuery;
