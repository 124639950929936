import { gql } from '@apollo/client/core';

const UploadAttachment = gql`mutation uploadAttachment($id: String!, $file: Upload!, $accessToken: String!) {
  uploadAttachment(id: $id, file: $file, accessToken: $accessToken) { 
    success
    message
  }
}`;

export default UploadAttachment;
