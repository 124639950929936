import { gql } from '@apollo/client/core';

const getVehiclesByDriverId = gql`query getVehicles($driverId: Int!, $page: Int!, $size: Int!, $accessToken: String!) {
  getVehicles(driverId: $driverId, page: $page, size: $size, accessToken: $accessToken) {
    success
    message
    pagination {
      currentPage
      pageSize
      totalPages
      totalResults
    }
    results {
      modelName
      makeName
      seriesName
      id
      driverId
      licensePlate
      created
      updated
    }
  }
}`;

export default getVehiclesByDriverId;
