const localStorageTag = 'localStorage';
const cookieTag = 'cookie';
const sessionTokenKey = 'sessionToken';
const refreshTokenKey = 'refreshToken';
const accessTokenKey = 'accessToken';
const tokenExpirationTime = 'tokenExpiration';

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts?.length === 2) {
    return parts?.pop()?.split(';').shift();
  }

  return '';
}

function setCookie(cname: string, cvalue: string, domain?: string, exdays = 60) {
  const d = new Date();

  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

  const expires = `expires=${d.toUTCString()}`;

  domain = domain || document.location.host;
  domain = domain.split(':')[0];

  document.cookie = `${cname}=${cvalue};${expires};path=/;${domain === 'localhost' ? '' : `domain=.${domain};`}`;
}

export const checkStorageAndCookies = () => {
  function checkStorage() {
    const test = 'test';

    try {
      window?.localStorage?.setItem(test, test);
      window?.localStorage?.removeItem(test);

      return true;
    }
    catch (e) {
      return false;
    }
  }

  const localStorage = checkStorage();
  const cookies = navigator.cookieEnabled;

  if (localStorage) return localStorageTag;

  return cookies ? cookieTag : 'unavailable';
};

export const getSessionToken = () => {
  const store = checkStorageAndCookies();
  // Available store is localStorage
  if (store === localStorageTag) {
    return window?.localStorage?.getItem(sessionTokenKey);
  }
  // Available store is cookie
  if (store === cookieTag) {
    return getCookie(sessionTokenKey);
  }

  return null;
};

export const storeSessionToken = (token: string) => {
  const store = checkStorageAndCookies();
  // Available store is localStorage
  if (store === localStorageTag) {
    return window?.localStorage?.setItem(sessionTokenKey, token);
  }
  // Available store is cookie
  if (store === cookieTag) {
    return setCookie(sessionTokenKey, token);
  }

  return null;
};

export const getRefreshToken = () => {
  const store = checkStorageAndCookies();
  // Available store is localStorage
  if (store === localStorageTag) {
    return window?.localStorage?.getItem(refreshTokenKey);
  }
  // Available store is cookie
  if (store === cookieTag) {
    return getCookie(refreshTokenKey);
  }

  return null;
};

export const storeRefreshToken = (token: string) => {
  const store = checkStorageAndCookies();
  // Available store is localStorage
  if (store === localStorageTag) {
    return window?.localStorage?.setItem(refreshTokenKey, token);
  }
  // Available store is cookie
  if (store === cookieTag) {
    return setCookie(refreshTokenKey, token);
  }

  return null;
};

export const getAccessToken = () => {
  const store = checkStorageAndCookies();
  // Available store is localStorage
  if (store === localStorageTag) {
    return window?.localStorage?.getItem(accessTokenKey);
  }
  // Available store is cookie
  if (store === cookieTag) {
    return getCookie(accessTokenKey);
  }

  return null;
};

export const storeAccessToken = (token: string) => {
  const store = checkStorageAndCookies();
  // Available store is localStorage
  if (store === localStorageTag) {
    return window?.localStorage?.setItem(accessTokenKey, token);
  }
  // Available store is cookie
  if (store === cookieTag) {
    return setCookie(accessTokenKey, token);
  }

  return null;
};

export const storeLanguage = (languageCode: string) => {
  const store = checkStorageAndCookies();
  // Available store is localStorage
  if (store === localStorageTag) {
    return window?.localStorage?.setItem('lang', languageCode);
  }
  // Available store is cookie
  if (store === cookieTag) {
    return setCookie('lang', languageCode);
  }

  return null;
};

export const getLanguage = () => {
  const store = checkStorageAndCookies();
  // Available store is localStorage
  if (store === localStorageTag) {
    return window?.localStorage?.getItem('lang');
  }
  // Available store is cookie
  if (store === cookieTag) {
    return getCookie('lang');
  }

  return null;
};

export const getTokenExpirationTime = () => {
  const store = checkStorageAndCookies();
  // Available store is localStorage
  if (store === localStorageTag) {
    return window?.localStorage?.getItem(tokenExpirationTime);
  }
  // Available store is cookie
  if (store === cookieTag) {
    return getCookie(tokenExpirationTime);
  }

  return null;
};

export const storeTokenExpirationTime = (seconds: string) => {
  const store = checkStorageAndCookies();
  // Available store is localStorage
  if (store === localStorageTag) {
    return window?.localStorage?.setItem(tokenExpirationTime, seconds);
  }
  // Available store is cookie
  if (store === cookieTag) {
    return setCookie(tokenExpirationTime, seconds);
  }

  return null;
};
