import { gql } from '@apollo/client/core';

const validateAccessTokenQuery = gql`query validateAccessToken($accessToken: String!) {
  validateAccessToken(accessToken: $accessToken) {
    success
    message
    userId
    driverId
    name
    phone
    photo
    email
    created
  }
}`;

export default validateAccessTokenQuery;
