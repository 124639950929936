export const journeysPerPage = 999;

export const baseURL = import.meta.env.VITE_API_URL as string;
export const routes = {
  login: {
    email: '/email',
    phone: '/phone',
    pin: '/pin',
    name: '/name',
    welcome: '/welcome',
  },
  journeys: '/journeys',
  journey: '/journey',
  profile: '/profile',
  information: '/information',
  newVehicle: '/new-vehicle',
  updateVehicle: '/update-vehicle',
  editProfile: '/edit-profile',
  support: '/support',
  video: '/video',
};

export const storyblock = {
  baseUrl: 'https://api.storyblok.com/v2/cdn',
  token: 'nHYqtOzXUiZTEvkErPlKnAtt',
};

export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const filterStatuses: any = {
  upcoming: ['AVAILABLE', 'PLANNED', 'UNDERWAY_TO_PICKUP', 'ARRIVED_AT_PICKUP', 'JOURNEY_IN_PROGRESS'],
  completed: ['COMPLETED'],
  cancelled: ['CANCELLED', 'NO_LONGER_AVAILABLE', 'REASSIGNED_DRIVER', 'REASSIGNED_TRANSFER_COMPANY'],
};
