import React, { useContext, useEffect, useState } from 'react';

const PING_RESOURCE = '/flags/4x3/gb.svg';
const TIMEOUT_TIME_MS = 3000;
const onlinePollingInterval = 3000;

export const ONLINE_STATUS: {
  online: boolean;
  queries: any[];
} = {
  online: true,
  queries: [],
};

const timeout = (time: number, promise: Promise<any>) => new Promise((resolve, reject) => {
  setTimeout(() => {
    reject(new Error('Request timed out.'));
  }, time);
  promise.then(resolve, reject);
});

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  // If the browser has no network connection return offline
  if (!navigator.onLine) return navigator.onLine;

  //
  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(PING_RESOURCE, {
        method: 'GET',
        signal,
      }),
    );
    return true;
  }
  catch (error) {
    // Error Log
    console.error(error);

    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort();
  }
  return false;
};

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider: React.FC = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setOnlineStatus(online);
  };

  useEffect(() => {
    if (!ONLINE_STATUS.online && ONLINE_STATUS.queries.length && onlineStatus) {
      ONLINE_STATUS.queries.forEach((query: any) => query());

      ONLINE_STATUS.queries = [];
    }

    ONLINE_STATUS.online = onlineStatus;
  }, [onlineStatus]);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false);
    });

    // Add polling in case of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, onlinePollingInterval);

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false);
      });

      clearInterval(id);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => useContext(OnlineStatusContext);

export default useOnlineStatus;
