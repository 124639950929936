import { useMutation } from '@apollo/client';

import { RetrieveSessionQuery } from '../../models/graphql';
import { useSetCacheProfile } from '../apolloCacheHooks';

let called = false;

export const useRetrieveSession = () => {
  const setCacheProfile = useSetCacheProfile();
  const [retrieveSession] = useMutation(RetrieveSessionQuery);

  return (
    token: string,
    refreshToken: string,
    callback: (data: any) => void,
  ) => retrieveSession({
    variables: {
      token,
      refreshToken,
    },
  }).then(async (res) => {
    const driverData = { ...res?.data?.retrieveSession };

    called = true;

    if (driverData?.success) {
      driverData.id = driverData.userId;

      delete driverData.userId;
      delete driverData.created;
      delete driverData.updated;
      delete driverData.success;
      delete driverData.message;

      await setCacheProfile(driverData);
    }

    if (callback) callback(driverData);
  });
};
