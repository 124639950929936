import { useMutation, useQuery } from '@apollo/client';

import { CacheProfile } from '../../models/CacheProfile';
import { associateJourneyMutation, GetCacheProfile } from '../../models/graphql';
import { useDataLayer } from '../../services/hooks/useDataLayer';
import { useAddAction } from '../hooks/useAddAction';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useAssociateJourney = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const { trackEvent } = useDataLayer();
  const { addAction } = useAddAction();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const [associateJourney] = useMutation(associateJourneyMutation, {
    fetchPolicy: 'no-cache',
  });

  const sessionToken = cachedProfileData?.token;

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.associateJourney);
  };

  return (
    code: string,
    callback: (data: any) => void,
    profileData?: CacheProfile,
    omitAuthentication = false,
  ) => {
    if (omitAuthentication) {
      trackEvent({
        event: 'token-tracking',
        sessionToken,
        accessToken: profileData?.accessToken,
        url: window.location.href,
      });
      addAction('token-tracking', {
        sessionToken,
        accessToken: profileData?.accessToken,
        url: window.location.href,
      });
      return associateJourney({
        variables: {
          code: code.toUpperCase(),
          token: profileData ? profileData.token : sessionToken,
          accessToken: profileData?.accessToken ?? cachedProfileData?.accessToken,
        },
      }).then((res) => {
        handleNewData(res, callback);
      });
    }

    return offlineProofQuery(() => authenticatedQuery((accessToken) => {
      console.log('#### DEBUGGING start ####');
      console.log(' useAssociateJourney - mutation - profileData = ', profileData);
      console.log(' useAssociateJourney - mutation - accessToken = ', accessToken);
      console.log('#### DEBUGGING end ####');
      trackEvent({
        event: 'token-tracking',
        accessToken,
        url: window.location.href,
      });
      addAction('token-tracking', {
        accessToken,
        url: window.location.href,
      });
      associateJourney({
        variables: {
          code: code.toUpperCase(),
          token: profileData ? profileData.token : sessionToken,
          accessToken,
        },
      }).then((res) => {
        handleNewData(res, callback);
      });
    }, profileData));
  };
};
