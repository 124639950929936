import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { CacheProfile } from '../../models/CacheProfile';
import { GetCacheProfile } from '../../models/graphql';
import { getAccessToken, getRefreshToken } from '../store';
import { validateJwt } from '../validateJwt';
import { useValidateAccessToken } from './index';

const tokenInitialValue = 'initialValue';
let gqlAuthenticatedQueriesWaiting: any = [];
let profile: CacheProfile = {};

export const useAuthenticatedQuery = () => {
  const [validToken, setValidToken] = useState<string | undefined>(tokenInitialValue);
  const [validationInProgress, setValidationInProgress] = useState(false);
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const validateAccessToken = useValidateAccessToken();

  const triggerQueries = (token?: string) => {
    if (token && token !== tokenInitialValue && gqlAuthenticatedQueriesWaiting?.length) {
      gqlAuthenticatedQueriesWaiting.forEach((authenticatedQuery: any) => {
        if (authenticatedQuery) authenticatedQuery(token);
      });

      gqlAuthenticatedQueriesWaiting = [];
    }
    else if (!token) {
      gqlAuthenticatedQueriesWaiting = [];
    }
  };

  useEffect(() => {
    // Resetting token to empty string means we need to check validity via API
    if (!validToken) {
      console.log('AUTHENTICATED QUERY LAYER - validateAccessToken - profile - ', profile);

      validateAccessToken(
        {
          ...profile,
          accessToken: getAccessToken(),
          refreshToken: getRefreshToken(),
        },
        (response: any) => {
          // API validated token or generated new one that's valid
          if (response?.accessToken) {
            setValidToken(response?.accessToken);
          }

          setTimeout(() => {
            setValidationInProgress(false);
          }, 50);
        },
      );
    }
    else if (!validationInProgress) {
      triggerQueries(validToken);
    }
  }, [validToken, validationInProgress]);

  return (query: (validAccessToken: string) => any, profileData?: CacheProfile) => {
    profile = profileData ?? cachedProfileData;
    const tokenExpired = validateJwt(profile?.accessToken);

    gqlAuthenticatedQueriesWaiting.push(query);

    if (gqlAuthenticatedQueriesWaiting.length > 1) {
      setTimeout(() => {
        if (profile?.accessToken) triggerQueries(profile?.accessToken);
      }, 100);
    }
    // If array isn't empty, that means other query triggered validation
    // In that case just push new query to wait for execution
    else if (
      gqlAuthenticatedQueriesWaiting.length === 1 &&
      !validationInProgress
    ) {
      if (tokenExpired) {
        setValidationInProgress(true);

        setTimeout(() => {
          setValidToken('');
        }, 50);
      }
      else {
        setValidationInProgress(true);
        setValidToken(profile?.accessToken);

        setTimeout(() => {
          triggerQueries(profile?.accessToken);
          setValidationInProgress(false);
        }, 50);
      }
    }
  };
};
