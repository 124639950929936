import './fileUpload.scss';

import React, { ChangeEventHandler, FunctionComponent, ReactElement } from 'react';

type FileUploadProps = {
  onChange: ChangeEventHandler;
  file?: string;
  className?: string;
  text: string;
  icon?: ReactElement;
  secondary?: boolean;
}

const FileUpload: FunctionComponent<FileUploadProps> = ({
  onChange,
  file = '',
  className = '',
  text = 'Upload',
  icon,
  secondary,
}) => (
  <div datatest-id="file-upload" className={`d-flex flex-column ${className}`}>
    <div className={`file-upload-component ${secondary && 'secondary'}`}>
      <label htmlFor="file-upload-input">
        <span data-testid="text_file-upload" className="d-flex">
          {icon && <span className="m-r-14 d-flex align-self-center">{icon}</span>}
          {text}
        </span>
      </label>
      <input
        id="file-upload-input"
        className="file-upload-input"
        type="file"
        onChange={onChange}
      />
    </div>
  </div>
);

export default FileUpload;
