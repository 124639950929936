import { useQuery } from '@apollo/client';
import ArrowIcon from '@transferz/fe-component-library/arrow-icon';
import { Button } from '@transferz/fe-component-library/button';
import Input from '@transferz/fe-component-library/input';
import Loader from '@transferz/fe-component-library/loader';
import ProfileIcon from '@transferz/fe-component-library/profile-icon';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { GetCacheProfile } from '../models/graphql';
import { useSetCacheProfile } from '../services/apolloCacheHooks';
import { useAssociateJourney, useEditDriverData } from '../services/apolloNetworkHooks';
import { routes } from '../services/constant';

const DriverName: FunctionComponent = () => {
  const navigate = useNavigate();
  const setCacheProfile = useSetCacheProfile();
  const associateJourney = useAssociateJourney();
  const updateDriver = useEditDriverData();
  const { data: cachedProfileData, loading: loadingProfileData } = useQuery(GetCacheProfile);
  const [loading, setLoading] = useState(false);
  const { register, watch } = useForm({ defaultValues: { driverName: '' } });
  const driverName = watch('driverName');
  const { translate } = useLocalize();

  const handleClick = () => {
    setLoading(true);

    const driverData = { ...cachedProfileData };

    driverData.name = driverName;

    updateDriver(
      driverData.id,
      driverData.phone,
      driverData.name,
      () => {
        if (cachedProfileData?.lastJourneyView) {
          const code = cachedProfileData?.lastJourneyView;

          associateJourney(code, () => {
            setCacheProfile({
              lastJourneyView: '',
            }).then(() => {
              setTimeout(() => {
                setLoading(false);

                navigate(
                  `${routes.journey}/${code}`,
                  { state: { notification: 'addJourneySuccess' } },
                );
              }, 250);
            });
          }, driverData, true);
        }
        else {
          setTimeout(() => {
            setLoading(false);

            navigate(routes.journeys);
          }, 250);
        }
      },
    );
  };

  useEffect(() => {
    if (!loadingProfileData && !cachedProfileData?.phone) {
      navigate(routes.login.phone);
    }
  }, [loadingProfileData]);

  if (loading) {
    return (<Loader text={translate('please_wait')} fullScreen />);
  }

  return (
    <div data-testid="driver-name-page" className="driver-name-page">
      <Typography variant="h4" className="m-b-35">
        {translate('your_name')}
      </Typography>
      <Input
        {...register('driverName', { required: 'This field is required.' })}
        label={translate('your_name_label')}
        type="text"
        className="m-b-20"
        placeholder={translate('your_name_label')}
      />
      <div className="d-flex align-items-center">
        <div
          className="profile-icon-container d-flex align-items-center justify-content-center bg-color-asphalt5"
        >
          <ProfileIcon color="light" />
        </div>
        <Typography variant="body1" className="m-h-10">
          {translate('name_number_saved')}
        </Typography>
        <div className="button-wrapper position-fixed p-16">
          <Button
            label={translate('save_continue')}
            icon={<ArrowIcon width={15} />}
            iconPosition="right"
            priority="primary"
            variant="confirm"
            fullWidth
            disabled={driverName.length < 2}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverName;
