export enum JourneyActionTypeEnum {
  DRIVER_DELAYED = 'DRIVER_DELAYED',
  TRAVELLER_NO_SHOW = 'TRAVELLER_NO_SHOW',
  ADDITIONAL_PROOF_TRAVELLER_NO_SHOW = 'ADDITIONAL_PROOF_TRAVELLER_NO_SHOW',
  DRIVER_LEFT_PICKUP = 'DRIVER_LEFT_PICKUP',
}

export enum JourneyActionTitles {
  DRIVER_DELAYED = 'reported_driver_late_less',
  TRAVELLER_NO_SHOW = 'reported_traveller_no_show',
  ADDITIONAL_PROOF_TRAVELLER_NO_SHOW = 'submitted_additional_proof',
  DRIVER_LEFT_PICKUP = 'l_issues_reported_driverLeftPickup',
}

export type Pagination = {
  currentPage: number,
  pageSize: number,
  totalPages: number,
  totalResults: number
}

export type JourneyAction = {
  id: number,
  journeyId: number,
  created: string,
  updated: string,
  type: JourneyActionTypeEnum,
  attachmentFileIds: number[];
}

export type GetActionsResponse = {
  pagination: Pagination,
  results: JourneyAction[];
}
