import { Button } from '@transferz/fe-component-library/button';
import CarIcon from '@transferz/fe-component-library/car-icon';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React from 'react';
import { useNavigate } from 'react-router';

import { CacheDriverVehicle } from '../models/CacheDriverVehicles';
import { routes } from '../services/constant';

type Props = {
  vehicle: CacheDriverVehicle;
  handleClick?: (id: number) => any;
  selected?: number;
  nonEditable?: boolean;
};

const Vehicle = ({
  vehicle, handleClick, selected, nonEditable,
}: Props) => {
  const navigate = useNavigate();
  const { translate } = useLocalize();

  const handleEditVehicle = (vehicleID: number, license: string, carBrand: string, carType: string) => {
    navigate(routes.updateVehicle, {
      state: {
        vehicleID,
        license,
        carBrand,
        carType,
        route: nonEditable ? 'profile' : 'information',
      },
    });
  };
  return (
    <div
      key={vehicle.id}
      className={`vehicle-item d-flex b-r-8 bg-asphalt1 p-8 m-b-8 ${selected === vehicle.id ? 'selected' : ''}`}
      onClick={() => {
        if (handleClick) handleClick(vehicle.id);
        console.log(`DEBUG User selected vehicle: ${vehicle.makeName} ${vehicle.modelName}`);
      }}
    >
      {!nonEditable ? (
        <div data-testid="vehicle_radio-button" className="circle-wrapper d-flex align-center justify-center w-2">
          <div className="circle circle-1">
            <div className="circle circle-2">
              <div className="circle circle-3 w-12 h-12" />
            </div>
          </div>
        </div>
      ) : (
        <div data-testid="vehicle_car-icon" className="icon-wrapper d-flex align-center justify-center w-2 m-r-12">
          <CarIcon height={24} fill="atlantis5" color="atlantis5" />
        </div>
      )}
      <div className="car-name-license w-7">
        <Typography variant="body1">{`${vehicle.makeName} ${vehicle.modelName}`}</Typography>
        <Typography variant="body2" className="uppercase license">
          {vehicle.licensePlate}
        </Typography>
      </div>
      <Button
        label={translate('edit')}
        priority="tertiary"
        textColor="blue5"
        data-testid="edit-vehicle"
        className="w-3 align-self-center"
        onClick={() => handleEditVehicle(
          vehicle.id,
          vehicle.licensePlate,
          vehicle.makeName,
          `${vehicle.seriesName} ${vehicle.modelName}`,
        )}
      />
    </div>
  );
};

export default Vehicle;
