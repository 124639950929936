import { useMutation } from '@apollo/client';

import { assignDriverToJourneyQuery } from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useAssignJourney = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const [assignJourney] = useMutation(assignDriverToJourneyQuery, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.assignJourney);
  };

  return (
    id: number,
    driverCode: string,
    callback: (data: any) => void,
  ) => offlineProofQuery(() => authenticatedQuery((accessToken) => {
    assignJourney({
      variables: {
        id,
        driverCode: driverCode.toUpperCase(),
        accessToken,
      },
    }).then((res) => {
      handleNewData(res, callback);
    });
  }));
};
