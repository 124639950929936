import { useLazyQuery } from '@apollo/client';

import { CacheVehicleMake } from '../../models/CacheVehicleMakes';
import { getVehicleMakes } from '../../models/graphql';
import { useSetCacheVehicleMakes } from '../apolloCacheHooks';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useGetVehicleMakes = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const setCacheVehicleMakes = useSetCacheVehicleMakes();
  const [
    getVehiclesMakes,
    {
      refetch: getVehiclesMakesRefetch,
      called,
    },
  ] = useLazyQuery(getVehicleMakes, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = (
    res: any,
    callback?: (data: any) => void,
  ) => {
    const data = res?.data?.getVehicleMakes?.results;
    const vehicleMakes: CacheVehicleMake[] = [];

    data?.forEach((item: { name: string; id: number }) => {
      vehicleMakes.push({
        value: item.id,
        label: item.name,
      });
    });

    setCacheVehicleMakes(vehicleMakes);

    if (callback) callback(vehicleMakes);
  };

  return (
    callback?: (data: any) => void,
  ) => {
    if (called) {
      return offlineProofQuery(() => authenticatedQuery((accessToken) => getVehiclesMakesRefetch({ accessToken })
        .then((res) => {
          handleNewData(res, callback);
        })));
    }

    return offlineProofQuery(() => authenticatedQuery((accessToken) => getVehiclesMakes({
      variables: { accessToken },
    }).then((res) => {
      handleNewData(res, callback);
    })));
  };
};
