import { Button } from '@transferz/fe-component-library/button';
import ChevronIcon from '@transferz/fe-component-library/chevron-icon';
import { useLocalize } from 'localize-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

type StickyBackButtonProps = {
  buttonText?: string;
  onClick?: () => void;
};

const StickyBackButton: FunctionComponent<StickyBackButtonProps> = ({ buttonText, onClick }) => {
  const navigate = useNavigate();
  const { translate } = useLocalize();
  const [isSticky, setIsSticky] = useState<boolean>(false);

  useEffect(() => {
    const onPageScroll = (e: any) => {
      if (window.scrollY > 0 && !isSticky) {
        setIsSticky(true);
      }
      else {
        setIsSticky(false);
      }
    };

    document.addEventListener('scroll', onPageScroll);

    return () => {
      document.removeEventListener('scroll', onPageScroll);
    };
  }, []);

  return (
    <div className="sticky-button-container has-height">
      <div className="back-button-wrapper sticky">
        <Button
          label={buttonText || translate('back')}
          priority="tertiary"
          icon={<ChevronIcon direction="left" height={15} />}
          iconPosition="left"
          textColor="asphalt6"
          onClick={
            onClick ||
            (() => {
              console.log('DEBUG User clicked back button, navigating to previous page...');
              navigate(-1);
            })
          }
          className="back-button"
          data-testid="button_page-back"
        />
      </div>
    </div>
  );
};

export default StickyBackButton;
