import { gql } from '@apollo/client/core';

const changeJourneyStatusQuery = gql`
  mutation changeJourneyStatus($status: String!, $id: Int!, $lat: String, $long: String, $accessToken: String, $acknowledgeCompletingPickupDateInFuture: Boolean) {
    changeJourneyStatus(status: $status, id: $id, lat: $lat, long: $long, accessToken: $accessToken, acknowledgeCompletingPickupDateInFuture: $acknowledgeCompletingPickupDateInFuture) {
      success
      message
    }
  }
`;

export default changeJourneyStatusQuery;
