import { Button } from '@transferz/fe-component-library/button';
import ChevronIcon from '@transferz/fe-component-library/chevron-icon';
import { useLocalize } from 'localize-react';
import React, { FunctionComponent } from 'react';
import YouTube from 'react-youtube';

type InstuctionalVideoProps = {
  isOpen: boolean;
  videoId: string;
  onClose: () => void;
}

const InstructionalVideo: FunctionComponent<InstuctionalVideoProps> = ({ videoId, onClose, isOpen }) => {
  const { translate } = useLocalize();

  const onVideoReady = (e: any) => {
    e.target.playVideo();
  };

  return (
    <div className={`${isOpen ? 'open' : ''} full-screen-video-container`}>
      <div className="back-button-wrapper">
        <Button
          label={translate('back')}
          priority="tertiary"
          icon={<ChevronIcon direction="left" height={15} />}
          iconPosition="left"
          textColor="asphalt6"
          onClick={onClose}
          className="back-button m-t-11"
        />
      </div>
      <YouTube
        videoId={videoId}
        onReady={onVideoReady}
        onEnd={onClose}
        className="video-player"
        opts={{
          height: `${window.innerHeight - 140}px`,
          playerVars: {
            autoplay: 1,
            iv_load_policy: 3,
            modestbranding: 1,
            playsinline: 1,
          },
        }}
      />
    </div>
  );
};

export default InstructionalVideo;
