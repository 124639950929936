import { useQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';

import { GetCacheProfile } from '../../models/graphql';

export const useAddAction = () => {
  const { data: cachedProfileData } = useQuery(GetCacheProfile);

  const addAction = (name: string, options: any) => {
    datadogRum.addAction(name, {
      ...options,
      driverId: cachedProfileData?.driverId,
      name: cachedProfileData?.name,
      email: cachedProfileData?.email,
      userId: cachedProfileData?.id,
    });
  };

  return {
    addAction,
  };
};
