import Accordion from '@transferz/fe-component-library/accordion';
import DangerIcon from '@transferz/fe-component-library/danger-icon';
import Divider from '@transferz/fe-component-library/divider';
import NotesIcon from '@transferz/fe-component-library/notes-icon';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, { useMemo } from 'react';

import { JourneyAction, JourneyActionTitles, JourneyActionTypeEnum } from '../../types/actions';
import { JourneyStatus } from '../../types/journeyStatus';
import { Traveller } from '../../types/traveller';
import { parseDate } from '../../utils/parsingFunctions';
import JourneyHeaderBox from './JourneyHeaderBox';
import JourneyInfoAddons from './JourneyInfoAddons';
import JourneyInfoBoxButtons from './JourneyInfoBoxButtons';

interface JourneyOverviewBoxProps {
  onClick?: () => void;
  driverCode: string;
  status?: JourneyStatus;
  setJourneyIssuesDrawer: () => void;
  traveller: Traveller;
  addons: any[];
  className?: string;
  actions: JourneyAction[];
  dangerIcon?: boolean;
  availableForStarting?: boolean;
}

const JourneyInfoBox = ({
  onClick,
  driverCode,
  status,
  setJourneyIssuesDrawer,
  traveller,
  addons,
  className = '',
  actions,
  dangerIcon = false,
  availableForStarting,
}: JourneyOverviewBoxProps) => {
  const { translate } = useLocalize();
  const notYetStarted = !status || status === JourneyStatus.PLANNED || status === JourneyStatus.AVAILABLE;
  const isCancelled =
    status === JourneyStatus.CANCELLED ||
    status === JourneyStatus.NO_LONGER_AVAILABLE ||
    status === JourneyStatus.REASSIGNED_TRANSFER_COMPANY ||
    status === JourneyStatus.REASSIGNED_DRIVER;

  const infoMessage = notYetStarted || isCancelled ? translate('notification_message') : driverCode || 'N/A';
  // TODO Add a condition for journey issues once theyre added in api
  const actionsCount = actions?.length;
  const showDivider = traveller.driverComments || traveller.meetAndGreetSignText || !!actionsCount;
  const reportedIssues = useMemo(
    () => (
      <div className="issues">
        {actions &&
          actions.length > 0 &&
          actions.map((action: JourneyAction, index: number) => {
            const createdDateString = `${action.created}+00:00`;
            const createdDate = new Date(createdDateString);
            const date = parseDate(createdDateString);
            const hours = createdDate.getHours().toString();
            const minutes = createdDate.getMinutes().toString();
            const time = `${hours.length > 1 ? hours : `0${hours}`}:${minutes.length > 1 ? minutes : `0${minutes}`}`;
            return (
              <div className="issue" key={action.id}>
                <Typography variant="body2" color="red5">
                  {translate(JourneyActionTitles[action.type])}
                </Typography>
                <Typography variant="body2" color="red5">{`${date}, ${time}`}</Typography>
                {index < actionsCount - 1 && <Divider className="red-divider" />}
              </div>
            );
          })}
      </div>
    ),
    [actions],
  );

  return (
    <div className={`journey-info-box ${className}`}>
      <JourneyHeaderBox status={status} dangerIcon={dangerIcon} />
      <div className="main-wrapper">
        <Typography variant="h6" color="dark" className="d-flex justify-content-center">
          {notYetStarted || isCancelled ? driverCode : `${traveller.firstName} ${traveller.lastName}`}
        </Typography>
        <Typography variant="body2" color="asphalt7" className="d-flex justify-content-center text-center">
          {infoMessage}
        </Typography>
        <JourneyInfoBoxButtons
          setJourneyIssuesDrawer={() => setJourneyIssuesDrawer()}
          travellerPhone={traveller?.phoneVerificationInfo?.formattedPhone || traveller.phone}
          status={status!}
          notYetStarted={notYetStarted}
          isCancelled={isCancelled}
          availableForStarting={availableForStarting}
        />
        {!!addons.length && <JourneyInfoAddons addons={addons} />}
        {showDivider && <Divider />}
        {traveller.driverComments && (
          <Accordion
            icon={<NotesIcon width={16} className="m-r-14 m-t-4" />}
            text={traveller.driverComments}
            className={`comment-accordion ${!actionsCount ? 'no-border' : ''}`}
          />
        )}
        {!!actionsCount && (
          <Accordion
            icon={<DangerIcon width={16} color="red5" className="m-r-14" />}
            title={`${actionsCount} ${translate(actionsCount > 1 ? 'issues_reported' : 'issue_reported_singular')}`}
            nodeElement={reportedIssues}
            className="issues-accordion"
          />
        )}
      </div>
    </div>
  );
};

export default JourneyInfoBox;
