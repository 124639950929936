import { useLazyQuery } from '@apollo/client';

import { getVehicleSeries } from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useGetVehicleSeries = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const [
    getVehiclesSeries,
    {
      refetch: getVehiclesSeriesRefetch,
      called,
    },
  ] = useLazyQuery(getVehicleSeries, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = (
    res: any,
    callback?: (data: any) => void,
  ) => {
    const data = res?.data?.getVehicleSeries?.results;
    const vehicleSeries: any[] = [];

    data?.forEach((item: {
      id: number;
      name: string;
      makeName: string;
      modelName: string;
    }) => {
      vehicleSeries.push({
        value: item.id,
        label: `${item.name} ${item.modelName}`,
      });
    });

    if (callback) callback(vehicleSeries);
  };

  return (
    makeId: number,
    callback?: (data: any) => void,
  ) => {
    const variables = {
      makeId,
    };

    if (called) {
      return offlineProofQuery(() => authenticatedQuery((accessToken) => getVehiclesSeriesRefetch({
        ...variables,
        accessToken,
      }).then((res) => {
        handleNewData(res, callback);
      })));
    }

    return offlineProofQuery(() => authenticatedQuery((accessToken) => getVehiclesSeries({
      variables: {
        ...variables,
        accessToken,
      },
    }).then((res) => {
      handleNewData(res, callback);
    })));
  };
};
