import BabySeatIcon from '@transferz/fe-component-library/baby-seat-icon';
import BoosterSeatIcon from '@transferz/fe-component-library/booster-seat-icon';
import CirclePlusIcon from '@transferz/fe-component-library/circle-plus-icon';
import LocationIcon from '@transferz/fe-component-library/location-icon';
import LuggageIcon from '@transferz/fe-component-library/luggage-icon';
import MeetAndGreetIcon from '@transferz/fe-component-library/meet-and-greet-icon';
import PetsIcon from '@transferz/fe-component-library/pets-icon';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, {
  FunctionComponent, ReactElement, useEffect, useState,
} from 'react';

enum JourneyAddons {
  BOOSTER_SEAT = 'booster_seat',
  PETS = 'pets',
  BABY_SEAT = 'baby_seat',
  SPECIAL_LUGGAGE = 'special_luggage',
  EXTRA_STOP = 'extra_stop',
  MEET_GREET = 'meet_greet'
}

// TODO prebaciti u types
type AddonType = {
  addonType: keyof typeof JourneyAddons,
  amount: number,
  price: any
}
interface JourneyInfoAddonsProps {
  addons: AddonType[],
  className?: string
}

type AddonsIconsType = {
  [key: string]: ReactElement;
}

const icons: AddonsIconsType = {
  BOOSTER_SEAT: <BoosterSeatIcon width={16} color="blue5" className="m-b-2" />,
  PETS: <PetsIcon width={16} color="blue5" className="m-b-2" />,
  BABY_SEAT: <BabySeatIcon width={16} color="blue5" className="m-b-2" />,
  SPECIAL_LUGGAGE: <LuggageIcon width={16} color="blue5" className="m-b-2" />,
  EXTRA_STOP: <LocationIcon width={16} color="blue5" className="m-b-2" />,
  MEET_GREET: <MeetAndGreetIcon width={16} color="blue5" className="m-b-2" />,
};

const JourneyInfoAddons: FunctionComponent<JourneyInfoAddonsProps> = ({ addons, className }) => {
  const { translate } = useLocalize();

  return (
    <div className={`display-flex ${className}`}>
      <div className="plus m-t-2 m-r-17">
        <CirclePlusIcon width={16} />
      </div>

      <div className="journey-info-addons w-12">

        {addons.map((addon: AddonType) => (
          <div key={addon.addonType} className="addon d-flex align-items-center p-t-2 p-b-2">
            {icons[addon.addonType]}
            <Typography
              variant="body2"
              color="asphalt6"
              className="m-l-8"
            >
              {translate(JourneyAddons[addon.addonType])}
              {addon.amount > 1 && (
              <span className="m-l-2 ">
                {' '}
                {`(${(addon.amount)})`}
              </span>
              )}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JourneyInfoAddons;
