import { gql } from '@apollo/client';

const GetCacheJourneys = gql`
  query getCachedJourneys {
    currentPage @client
    pageSize @client
    totalPages @client
    totalResults @client
    items @client
  }
`;

export default GetCacheJourneys;
