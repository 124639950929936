import { gql } from '@apollo/client/core';

const deleteVehicleQuery = gql`mutation deleteVehicle($id: Int!, $accessToken: String!) {
  deleteVehicle(id: $id, accessToken: $accessToken) {
    success
    message
  }
}`;

export default deleteVehicleQuery;
