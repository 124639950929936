import { gql } from '@apollo/client/core';

const searchActions = gql`query searchActions($journeyId: Int!, $page: Int!, $size: Int!, $accessToken: String!) {
  searchActions(journeyId: $journeyId, page: $page, size: $size, accessToken: $accessToken) {
    success
    message
    pagination {
      currentPage
      pageSize
      totalPages
      totalResults
    }
    results {
      id
      journeyId
      type
      created
      attachments {
        id
        externalKey
        contentType
      }
    }
  }
}`;

export default searchActions;
