import { useMutation, useQuery } from '@apollo/client';

import {
  GetCacheProfile,
} from '../../models/graphql';
import UploadDriverPhotoQuery from '../../models/graphql/UploadDriverPhoto';
import { useSetCacheProfile } from '../apolloCacheHooks';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useSetDriverPhoto = () => {
  const setCacheProfile = useSetCacheProfile();
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const [uploadPhoto] = useMutation(UploadDriverPhotoQuery);

  const handleNewData = async (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (res.data.uploadDriverPhoto.success) {
      await setCacheProfile({
        photo: res?.url,
      });
    }

    if (callback) callback(res);
  };

  return (
    file: any,
    callback: (data: any) => void,
  ) => {
    const fileUrl = URL.createObjectURL(file);

    return offlineProofQuery(() => authenticatedQuery((accessToken) => uploadPhoto({
      variables: {
        id: String(cachedProfileData?.driverId),
        file,
        accessToken,
      },
    }).then((res) => handleNewData({ ...res, url: fileUrl }, callback))));
  };
};
