export const langs = [
  { name: 'Afar', code: 'aa', flag: 'dj' },
  { name: 'Abkhazian', code: 'ab', flag: 'ge' },
  { name: 'Afrikaans', code: 'af', flag: 'za' },
  { name: 'Akan', code: 'ak', flag: 'gh' },
  { name: 'Amharic', code: 'am', flag: 'et' },
  { name: 'Arabic', code: 'ar', flag: 'sa' },
  { name: 'Assamese', code: 'as', flag: 'in' },
  { name: 'Awadhi', code: 'aw', flag: 'in' },
  { name: 'Aymara', code: 'ay', flag: 'bo' },
  { name: 'Azerbaijani', code: 'az', flag: 'az' },
  { name: 'Bhojpuri', code: 'b1', flag: 'in' },
  { name: 'Maithili', code: 'b2', flag: 'np' },
  { name: 'Bashkir', code: 'ba', flag: 'ru' },
  { name: 'Belarussian', code: 'be', flag: 'by' },
  { name: 'Bulgarian', code: 'bg', flag: 'bg' },
  { name: 'Bihari', code: 'bh', flag: 'in' },
  { name: 'Bislama', code: 'bi', flag: 'vu' },
  { name: 'Balochi', code: 'bl', flag: 'pk' },
  { name: 'Bengali', code: 'bn', flag: 'bd' },
  { name: 'Tibetan', code: 'bo', flag: 'cn' },
  { name: 'Breton', code: 'br', flag: 'fr' },
  { name: 'Catalan', code: 'ca', flag: 'ca' },
  { name: 'Cebuano', code: 'cb', flag: 'ph' },
  { name: 'Chechen', code: 'ce', flag: 'ce' },
  { name: 'Corsican', code: 'co', flag: 'fr' },
  { name: 'Czech', code: 'cs', flag: 'cz' },
  { name: 'Welsh', code: 'cy', flag: 'cy' },
  { name: 'Danish', code: 'da', flag: 'dk' },
  { name: 'German', code: 'de', flag: 'de' },
  { name: 'Dakhini', code: 'dk', flag: 'in' },
  { name: 'Bhutani', code: 'dz', flag: 'bt' },
  { name: 'Greek', code: 'el', flag: 'gr' },
  { name: 'English', code: 'en', flag: 'gb' },
  { name: 'Esperanto', code: 'eo', flag: 'eo' },
  { name: 'Spanish', code: 'es', flag: 'es' },
  { name: 'Estonian', code: 'et', flag: 'ee' },
  { name: 'Basque', code: 'eu', flag: 'eu' },
  { name: 'Persian', code: 'fa', flag: 'ir' },
  { name: 'Finnish', code: 'fi', flag: 'fi' },
  { name: 'Fiji', code: 'fj', flag: 'fj' },
  { name: 'Faeroese', code: 'fo', flag: 'fo' },
  { name: 'French', code: 'fr', flag: 'fr' },
  { name: 'Frisian', code: 'fy', flag: 'nl' },
  { name: 'Irish', code: 'ga', flag: 'ie' },
  { name: 'Scottish Gaelic', code: 'gd', flag: 'gd' },
  { name: 'Galician', code: 'gl', flag: 'gl' },
  { name: 'Guarani', code: 'gn', flag: 'py' },
  { name: 'Gujarati', code: 'gu', flag: 'in' },
  { name: 'Hausa', code: 'ha', flag: 'ne' },
  { name: 'Hindi', code: 'hi', flag: 'in' },
  { name: 'Croatian', code: 'hr', flag: 'hr' },
  { name: 'Haitian Creole', code: 'ht', flag: 'ht' },
  { name: 'Hungarian', code: 'hu', flag: 'hu' },
  { name: 'Armenian', code: 'hy', flag: 'am' },
  { name: 'Interlingua', code: 'ia', flag: 'ia' },
  { name: 'Interlingue', code: 'ie', flag: 'ie' },
  { name: 'Inupiak', code: 'ik', flag: 'ik' },
  { name: 'Indonesian', code: 'in', flag: 'id' },
  { name: 'Icelandic', code: 'is', flag: 'is' },
  { name: 'Italian', code: 'it', flag: 'it' },
  { name: 'Hebrew', code: 'iw', flag: 'il' },
  { name: 'Japanese', code: 'ja', flag: 'jp' },
  { name: 'Yiddish', code: 'ji', flag: 'ji' },
  { name: 'Javanese', code: 'jw', flag: 'id' },
  { name: 'Georgian', code: 'ka', flag: 'ge' },
  { name: 'Kabyle', code: 'kb', flag: 'dz' },
  { name: 'Konkani', code: 'ki', flag: 'in' },
  { name: 'Kazakh', code: 'kk', flag: 'kz' },
  { name: 'Greenlandic', code: 'kl', flag: 'gl' },
  { name: 'Khmer', code: 'km', flag: 'kh' },
  { name: 'Kannada', code: 'kn', flag: 'in' },
  { name: 'Korean', code: 'ko', flag: 'kr' },
  { name: 'Kashmiri', code: 'ks', flag: 'in' },
  { name: 'Kurdish', code: 'ku', flag: 'iq' },
  { name: 'Kirghiz', code: 'ky', flag: 'kg' },
  { name: 'Latin', code: 'la', flag: 'va' },
  { name: 'Luxembourgish', code: 'lb', flag: 'lu' },
  { name: 'Lombard', code: 'lm', flag: 'it' },
  { name: 'Lingala', code: 'ln', flag: 'cg' },
  { name: 'Laothian', code: 'lo', flag: 'la' },
  { name: 'Lithuanian', code: 'lt', flag: 'lt' },
  { name: 'Latvian', code: 'lv', flag: 'lv' },
  { name: 'Malagasy', code: 'mg', flag: 'mg' },
  { name: 'Maori', code: 'mi', flag: 'nz' },
  { name: 'Macedonian', code: 'mk', flag: 'mk' },
  { name: 'Malayalam', code: 'ml', flag: 'in' },
  { name: 'Mongolian', code: 'mn', flag: 'mn' },
  { name: 'Moldavian', code: 'mo', flag: 'md' },
  { name: 'Marathi', code: 'mr', flag: 'in' },
  { name: 'Malay', code: 'ms', flag: 'my' },
  { name: 'Maltese', code: 'mt', flag: 'mt' },
  { name: 'Makhuwa', code: 'mu', flag: 'mz' },
  { name: 'Marwari', code: 'mw', flag: 'in' },
  { name: 'Burmese', code: 'my', flag: 'mm' },
  { name: 'Nauru', code: 'na', flag: 'nr' },
  { name: 'Nepali', code: 'ne', flag: 'np' },
  { name: 'Dutch', code: 'nl', flag: 'nl' },
  { name: 'Norwegian', code: 'no', flag: 'no' },
  { name: 'Occitan', code: 'oc', flag: 'fr' },
  { name: 'Oromo', code: 'om', flag: 'et' },
  { name: 'Oriya', code: 'or', flag: 'in' },
  { name: 'Punjabi', code: 'pa', flag: 'pk' },
  { name: 'Polish', code: 'pl', flag: 'pl' },
  { name: 'Pashto', code: 'ps', flag: 'af' },
  { name: 'Portuguese', code: 'pt', flag: 'pt' },
  { name: 'Quechua', code: 'qu', flag: 'pe' },
  { name: 'Rifian', code: 'ri', flag: 'ma' },
  { name: 'Rhaeto-Romance', code: 'rm', flag: 'ch' },
  { name: 'Kirundi', code: 'rn', flag: 'bi' },
  { name: 'Romanian', code: 'ro', flag: 'ro' },
  { name: 'Russian', code: 'ru', flag: 'ru' },
  { name: 'Kinyarwanda', code: 'rw', flag: 'rw' },
  { name: 'Sanskrit', code: 'sa', flag: 'in' },
  { name: 'Sindhi', code: 'sd', flag: 'pk' },
  { name: 'Sangro', code: 'sg', flag: 'cf' },
  { name: 'Serbo-Croatian', code: 'sh', flag: 'rs' },
  { name: 'Sinhalese', code: 'si', flag: 'lk' },
  { name: 'Slovak', code: 'sk', flag: 'sk' },
  { name: 'Slovenian', code: 'sl', flag: 'si' },
  { name: 'Samoan', code: 'sm', flag: 'ws' },
  { name: 'Shona', code: 'sn', flag: 'zw' },
  { name: 'Somali', code: 'so', flag: 'so' },
  { name: 'Albanian', code: 'sq', flag: 'al' },
  { name: 'Serbian', code: 'sr', flag: 'rs' },
  { name: 'Siswati', code: 'ss', flag: 'sz' },
  { name: 'Sesotho', code: 'st', flag: 'ls' },
  { name: 'Sundanese', code: 'su', flag: 'id' },
  { name: 'Swedish', code: 'sv', flag: 'se' },
  { name: 'Swahili', code: 'sw', flag: 'ke' },
  { name: 'Tamil', code: 'ta', flag: 'lk' },
  { name: 'Telugu', code: 'te', flag: 'in' },
  { name: 'Tajik', code: 'tg', flag: 'tj' },
  { name: 'Thai', code: 'th', flag: 'th' },
  { name: 'Tigrinya', code: 'ti', flag: 'er' },
  { name: 'Turkmen', code: 'tk', flag: 'tm' },
  { name: 'Tagalog', code: 'tl', flag: 'ph' },
  { name: 'Tuareg', code: 'tm', flag: 'tm' },
  { name: 'Setswana', code: 'tn', flag: 'bw' },
  { name: 'Tonga', code: 'to', flag: 'to' },
  { name: 'Turkish', code: 'tr', flag: 'tr' },
  { name: 'Tsonga', code: 'ts', flag: 'za' },
  { name: 'Tatar', code: 'tt', flag: 'ru' },
  { name: 'Twi', code: 'tw', flag: 'gh' },
  { name: 'Tamazight', code: 'tz', flag: 'tz' },
  { name: 'Uyghur', code: 'ug', flag: 'cn' },
  { name: 'Ukrainian', code: 'uk', flag: 'ua' },
  { name: 'Urdu', code: 'ur', flag: 'pk' },
  { name: 'Uzbek', code: 'uz', flag: 'uz' },
  { name: 'Vietnamese', code: 'vi', flag: 'vn' },
  { name: 'Volapuk', code: 'vo', flag: 'vo' },
  { name: 'Wolof', code: 'wo', flag: 'sn' },
  { name: 'Xhosa', code: 'xh', flag: 'za' },
  { name: 'Yoruba', code: 'yo', flag: 'ng' },
  { name: 'Chinese', code: 'zh', flag: 'cn' },
  { name: 'Zulu', code: 'zu', flag: 'za' },
];
