import React from 'react';

import Header from '../components/Header';

type Props = {
  children: JSX.Element | JSX.Element[];
}

const MainLayout: React.FC<Props> = ({ children }) => (
  <div className="main-layout">
    <Header />
    <div className="main-layout-content d-flex flex-column">
      {children}
    </div>
  </div>
);

export default MainLayout;
