import { gql } from '@apollo/client/core';

const getDriverById = gql`query getDriver($id: Int!, $accessToken: String!) {
  getDriver(id: $id, accessToken: $accessToken) {
    success
    message
    driverId
    name
    phone
    userId
    created
    updated
  }
}`;

export default getDriverById;
