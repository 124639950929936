import { gql } from '@apollo/client/core';

const getVehicleSeries = gql`
  query getVehicleSeries($makeId: Int!, $accessToken: String!) {
    getVehicleSeries(makeId: $makeId, accessToken: $accessToken) {
      success
      message
      pagination {
        currentPage
        pageSize
        totalPages
        totalResults
      }
      results {
        modelName
        makeName
        name
        id
      }
    }
  }
`;

export default getVehicleSeries;
