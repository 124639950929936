import { storyblock } from '../services/constant';

export const translations: { [key: string]: { [key: string]: string } } = {};

export const languageCodes: Array<string> = [];

export const fetchTranslations = async (lng = 'en') => {
  console.log('loading translations');
  if (translations[lng]) return;

  const queryParams = `language=${lng}&token=${storyblock.token}&version=published`;
  let result = null;

  try {
    console.log('loading translations 2');
    result = await fetch(
      `${storyblock.baseUrl}/stories/driver-app/driver-app-pages/?${queryParams}`,
    ).then((res) => res.json());

    console.log('loading translations 3');

    if (result?.story?.content) {
      console.log('loading translations 4');
      translations[lng] = result.story.content;
    }
  }
  catch (e) {
    console.log('loading translations 5', e);
    console.log('Error in fetching translations from Storyblock - ', e);
  }

  return result;
};

export const getAllLanguageCodes = async () => {
  let result = null;

  try {
    result = await fetch(
      `${storyblock.baseUrl}/spaces/me/?token=${storyblock.token}`,
    ).then((res) => res.json());

    if (result?.space?.language_codes) {
      result.space.language_codes.forEach((langCode: string) => {
        languageCodes.push(langCode);
      });
    }
  }
  catch (e) {
    console.log('Error in fetching language codes from Storyblock - ', e);
  }

  return result;
};
