import { useQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';

import { GetCacheProfile } from '../../models/graphql';

export const useDataLayer = () => {
  const { data: cachedProfileData } = useQuery(GetCacheProfile);

  const trackEvent = (dataLayer: any) => {
    TagManager.dataLayer({
      ...dataLayer,
      driverId: cachedProfileData?.driverId,
      name: cachedProfileData?.name,
      email: cachedProfileData?.email,
      userId: cachedProfileData?.id,
    });
  };

  return {
    trackEvent,
  };
};
