import { useMutation } from '@apollo/client';

import { deleteVehicleQuery } from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useDeleteVehicle = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const [deleteVehicle] = useMutation(deleteVehicleQuery, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.deleteVehicle);
  };

  return (
    id: number,
    callback: (data: any) => void,
  ) => offlineProofQuery(() => authenticatedQuery((accessToken) => {
    deleteVehicle({
      variables: {
        id,
        accessToken,
      },
    }).then((res) => {
      handleNewData(res, callback);
    });
  }));
};
