import { useLazyQuery } from '@apollo/client';

import { getPhoneByLocationQuery } from '../../models/graphql';

export const useGetPhoneByLocation = () => {
  const [
    getPhoneByLocation,
    {
      refetch: getPhoneByLocationRefetch,
      called,
    },
  ] = useLazyQuery(
    getPhoneByLocationQuery,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const handleNewData = async (
    res: any,
    callback?: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.getPhoneByLocation);
  };

  return (
    contactType: string,
    lat:string,
    lng:string,
    language?: string,
    callback?: (data: any) => void,
  ) => {
    if (called) {
      return getPhoneByLocationRefetch({
        contactType,
        lat,
        lng,
        language,
      }).then((res:any) => {
        handleNewData(res, callback);
      });
    }
    return getPhoneByLocation({
      variables: {
        contactType,
        lat,
        lng,
        language,
      },
    }).then((res:any) => {
      handleNewData(res, callback);
    });
  };
};
