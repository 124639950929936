import { gql } from '@apollo/client/core';

const UploadDriverPhotoQuery = gql`mutation uploadDriverPhoto($id: String!, $file: Upload!, $accessToken: String!) {
  uploadDriverPhoto(id: $id, file: $file, accessToken: $accessToken) { 
    success
    message
  }
}`;

export default UploadDriverPhotoQuery;
