import PhoneRingIcon from '@transferz/fe-component-library/phone-ring-icon';
import PlayIcon from '@transferz/fe-component-library/play-icon';
import QuestionMarkIcon from '@transferz/fe-component-library/question-mark-icon';
import Tabs from '@transferz/fe-component-library/tabs';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, { FunctionComponent, useState } from 'react';

import StickyBackButton from '../../components/StickyBackButton';
import Contact from './Contact';
import FAQ from './FAQ';
import InstructionalVideos from './Videos';

const Support: FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState<string>('videos');
  const { translate } = useLocalize();

  const tabs = [
    {
      label: translate('videos'),
      icon: <PlayIcon width={24} color={activeTab === 'videos' ? 'blue5' : 'asphalt7'} />,
      active: activeTab === 'videos',
      tab: 'videos',
    },
    {
      label: translate('faq'),
      icon: <QuestionMarkIcon width={24} color={activeTab === 'faq' ? 'blue5' : 'asphalt7'} />,
      active: activeTab === 'faq',
      tab: 'faq',
    },
    {
      label: translate('contact'),
      icon: <PhoneRingIcon width={24} color={activeTab === 'contact' ? 'blue5' : 'asphalt7'} />,
      active: activeTab === 'contact',
      tab: 'contact',
    },
  ];

  const handleTabClick = (tab: string) => {
    console.log(`DEBUG User clicked on tab: ${tab}`);
    setActiveTab(tab);
  };

  return (
    <div data-testid="support-page" className="support-page">
      <StickyBackButton />
      <div>
        <Typography variant="h4" color="dark">
          {translate('help')}
        </Typography>
      </div>
      <div className="m-t-31">
        <Tabs tabs={tabs} onChange={handleTabClick} />
      </div>
      <div>
        {activeTab === 'contact' && <Contact />}
        {activeTab === 'videos' && <InstructionalVideos />}
        {activeTab === 'faq' && <FAQ />}
      </div>
    </div>
  );
};

export default Support;
