import { useLazyQuery, useQuery } from '@apollo/client';

import { GetCacheProfile } from '../../models/graphql';
import validateAccessTokenQuery from '../../models/graphql/ValidateAccessTokenQuery';
import { useSetCacheProfile } from '../apolloCacheHooks';
import { getAccessToken, getRefreshToken, getSessionToken } from '../store';
import { useNewSession } from './useNewSession';
import { useRetrieveSession } from './useRetrieveSession';

export const useValidateAccessToken = () => {
  const newSession = useNewSession();
  const retrieveSession = useRetrieveSession();
  const setCacheProfile = useSetCacheProfile();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const [
    validateAccessToken,
    {
      refetch: validateAccessTokenRefetch,
      called,
    },
  ] = useLazyQuery(validateAccessTokenQuery, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = async (
    profileData = cachedProfileData,
    res: any,
    callback?: (data: any) => void,
  ) => {
    const driverData = { ...res?.data?.validateAccessToken };

    if (driverData?.success && driverData?.userId) {
      driverData.id = driverData.userId;
      driverData.accessToken = profileData.accessToken;

      delete driverData.userId;
      delete driverData.created;
      delete driverData.updated;
      delete driverData.success;
      delete driverData.message;

      await setCacheProfile(driverData);

      if (callback) callback(driverData);
    }
    // If accessToken is not valid, retrieve new one from refreshToken
    else {
      const storedSessionToken = getSessionToken();
      const storedRefreshToken = getRefreshToken();

      if (!storedRefreshToken || !storedSessionToken) {
        console.log('SESSION or REFRESH token not available');

        return newSession(() => {
          if (callback) callback(null);
        });
      }

      console.log('RETRIEVE NEW ACCESS TOKEN - refreshToken - from localStorage');

      retrieveSession(
        profileData?.token,
        storedRefreshToken,
        (newProfile: any) => {
          if (newProfile.driverId) {
            if (callback) callback(newProfile);
          }
          else {
            newSession(() => {
              if (callback) callback(null);
            });
          }
        },
      );
    }
  };

  return (
    profileData = cachedProfileData,
    callback?: (data: any) => void,
  ) => {
    const variables = { accessToken: profileData?.accessToken };

    if (called) {
      return validateAccessTokenRefetch(variables).then((res) => {
        handleNewData(profileData, res, callback);
      });
    }

    return validateAccessToken({ variables }).then((res) => {
      handleNewData(profileData, res, callback);
    });
  };
};
