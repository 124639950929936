import { useMutation } from '@apollo/client';

import {
  GetCacheProfile,
} from '../../models/graphql';
import UploadAttachment from '../../models/graphql/UploadAttachment';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';

export const useUploadAttachment = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const [uploadPhoto] = useMutation(UploadAttachment);

  const handleNewData = async (
    res: any,
    callback: (data: any) => void,
  ) => {
    console.log(res, 'data u upload attachment');

    if (callback) callback(res);
  };

  return (
    id:string,
    file: any,
    callback: (data: any) => void,
  ) => {
    const fileUrl = URL.createObjectURL(file);

    return authenticatedQuery((accessToken) => uploadPhoto({
      variables: {
        id: String(id),
        file,
        accessToken,
      },
    }).then((res) => handleNewData({ ...res, url: fileUrl }, callback)));
  };
};
