import {
  ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { CacheProfileEmpty } from '../models/CacheProfile';
import {
  GetCacheDriverVehicles,
  GetCacheJourneys, GetCacheProfile, GetCacheVehicleMakes,
} from '../models/graphql';
import GetCacheNotifications from '../models/graphql/GetCacheApiError';
import { baseURL } from './constant';

const uploadLink = createUploadLink({ uri: baseURL });
let Client: ApolloClient<NormalizedCacheObject> | null = null;

export const setInitialCache:
  (client: ApolloClient<NormalizedCacheObject>) => ApolloClient<NormalizedCacheObject> =
  (client: ApolloClient<NormalizedCacheObject>) => {
    client.writeQuery({
      query: GetCacheProfile,
      data: CacheProfileEmpty,
    });

    client.writeQuery({
      query: GetCacheJourneys,
      data: {
        success: 0,
        message: '',
        currentPage: 0,
        pageSize: 0,
        totalPages: 0,
        totalResults: 0,
        items: [],
      },
    });

    client.writeQuery({
      query: GetCacheVehicleMakes,
      data: {
        makes: [],
      },
    });

    client.writeQuery({
      query: GetCacheDriverVehicles,
      data: {
        vehicles: [],
      },
    });
    // Ovdje sad ide samo state za notifikacije za api error-e
    client.writeQuery({
      query: GetCacheNotifications,
      data: {
        status: false,
      },
    });

    return client;
  };

export const initializeClient: () => ApolloClient<NormalizedCacheObject> = () => {
  Client = new ApolloClient({
    link: ApolloLink.from([uploadLink]),
    cache: new InMemoryCache(),
  });

  Client = setInitialCache(Client);

  return Client;
};
