import Typography from '@transferz/fe-component-library/typography';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';

type Props = {
  title: string,
  icon: ReactElement,
  onClick?: () => void,
  text?: ReactNode,
  rightIcon?: ReactElement,
  className?: string,
  dataTestId?: string,
}

const JourneyInfoDetail: FunctionComponent<Props> = ({
  title,
  icon,
  onClick,
  text,
  rightIcon,
  dataTestId,
  className = '',
}) => (
  <div className={`pointer d-flex flex-direction-row justify-content-space-between ${className}`} onClick={onClick}>
    <div className="w-12 d-flex">
      <span className="d-flex justify-center">
        {icon}
      </span>
      <div className="d-flex flex-direction-column m-l-14">
        <Typography data-testid={dataTestId} variant="body1" color="dark">{title}</Typography>
        <Typography variant="body1" color="asphalt7">
          {text}
        </Typography>
      </div>
    </div>
    {rightIcon && (
      <div className="m-l-12">
        <div className="frame d-flex justify-content-center align-items-center">{rightIcon}</div>
      </div>
    )}
  </div>
);
export default JourneyInfoDetail;
