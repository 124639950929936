import { useLazyQuery } from '@apollo/client';

import SearchActionsQuery from '../../models/graphql/SearchActions';
// import { useSetCacheProfile } from '../apolloCacheHooks';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';

export const useSearchActions = () => {
  // const setCacheProfile = useSetCacheProfile();
  const authenticatedQuery = useAuthenticatedQuery();
  const [
    searchActions,
    {
      called,
      refetch: searchActionsRefetch,
    },
  ] = useLazyQuery(SearchActionsQuery, {
    fetchPolicy: 'no-cache',
  });

  return (
    journeyId: number,
    page: number,
    size: number,
    callback?: (data: any) => void,
  ) => {
    if (called) {
      return authenticatedQuery((accessToken) => searchActionsRefetch({
        variables: {
          journeyId,
          page,
          size,
          accessToken,
        },
      }).then((res) => {
        if (callback) {
          callback(res.data.searchActions.results);
        }
      }));
    }
    return authenticatedQuery((accessToken) => searchActions({
      variables: {
        journeyId,
        page,
        size,
        accessToken,
      },
    }).then((res) => {
      if (callback) {
        callback(res.data.searchActions.results);
      }
    }));
  };
};
