import { gql } from '@apollo/client/core';

const NewSessionMutation = gql`mutation newSession($userAgent: String!) {
  newSession(userAgent: $userAgent) {
    success
    driverId
    token
    message
  }
}`;

export default NewSessionMutation;
