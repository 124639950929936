import { useMutation } from '@apollo/client';

import { sendOtpQuery } from '../../models/graphql';
import { standardizePhoneNumber } from '../../utils/parsingFunctions';

export const useSendOTP = () => {
  const [sendOtp] = useMutation(sendOtpQuery);

  const handleNewData = async (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.sendOTP);
  };

  return (
    params: {
      phoneNumber: string,
      captchaToken?: string,
    },
    callback: (data: any) => void,
  ) => sendOtp({
    variables: {
      phone: standardizePhoneNumber(params.phoneNumber),
      captchaToken: params.captchaToken,
    },
  }).then((res) => {
    handleNewData(res, callback);
  });
};
