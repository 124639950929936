import { gql } from '@apollo/client/core';

const getAssociatedJourneysQuery = gql`
  query getAssociatedJourneys(
    $token: String!, 
    $accessToken: String!, 
    $page: Int!, 
    $size: Int!, 
    $textSearch: String, 
    $pickupDateAfter: String, 
    $pickupDateBefore: String, 
    $includedStatuses: [String]
  ){
  getAssociatedJourneys(
      token: $token, 
      accessToken: $accessToken, 
      page: $page, 
      size: $size,
      textSearch: $textSearch, 
      pickupDateAfter: $pickupDateAfter, 
      pickupDateBefore: $pickupDateBefore, 
      includedStatuses: $includedStatuses
    ){
    success
    message
    currentPage
    pageSize
    totalPages
    totalResults
    items {
      addons {
        addonType
        amount
        price {
            currencyCode
            fareCurrencyCode
            partnerBusinessModel
            price
            priceVat
            fare
            fareVatLevel
            partnerCommission
            platformFee
            fixedPrice
        }
      }
      totalPrice
      totalFare
      canBeCompleted
      canBeCancelled
      completedOn
      nextAction
      status
      destinationLocation {
        id
        address {
            formattedAddress
            originalAddress
            establishment
            streetName
            streetNumber
            city
            postalCode
            region
            countryCode
        }
        hubInfo {
          name
          hubType
        }
        hub
      }
      originLocation {
        id
        address {
            formattedAddress
            originalAddress
            establishment
            streetName
            streetNumber
            city
            postalCode
            region
            countryCode
        }
        hubInfo {
          name
          hubType
          details {
            code
            value
          }
        }
        hub
      }
      quoteId
      journeyCode
      driverCode
      bookingId
      hubId
      id
      created
      updated
      traveller {
        firstName
        lastName
        email
        phone
        flightNumber
        trainNumber
        shipName
        languageIsoCode
        passengerCount
        luggageCount
      }
      price {
        currencyCode
        fareCurrencyCode
        partnerBusinessModel
        price
        priceVat
        fare
        fareVatLevel
        partnerCommission
        platformFee
        fixedPrice
      }
      execution {
        vehicleCategory
        pickupDate
        pickupTimeZone
        fleetId
        assignedDriverId
        assignedDriverVehicleId
        transferCompanyId
        meetingPointId
        meetingPointType
        durationInSeconds
        executionCode
        distance {
          value
          unit
        }
      }
      flightInfo {
        status 
        scheduledTime 
        estimatedTime 
        airline
        delay 
        city
      }
    }
  }
}`;

export default getAssociatedJourneysQuery;
