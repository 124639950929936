import { useMutation } from '@apollo/client';

import { changeJourneyStatusQuery } from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useSetJourneyStatus = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const [setJourneyStatus] = useMutation(changeJourneyStatusQuery, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.changeJourneyStatus);
  };

  return (
    id: number,
    status: string,
    lat: string,
    long: string,
    callback: (data: any) => void,
    acknowledgeCompletingPickupDateInFuture?: boolean,
  ) => offlineProofQuery(() => authenticatedQuery((accessToken) => setJourneyStatus({
    variables: {
      id,
      status,
      lat,
      long,
      acknowledgeCompletingPickupDateInFuture,
      accessToken,
    },
  }).then((res) => {
    handleNewData(res, callback);
  })));
};
