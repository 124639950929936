import { gql } from '@apollo/client/core';

const updateVehicleQuery = gql`mutation updateVehicle($id: Int!, $serieId: Int!, $plate: String!, $accessToken: String!) {
  updateVehicle(id: $id, serieId: $serieId, plate: $plate, accessToken: $accessToken) {
    success
    message
    modelName
    makeName
    seriesName
    id
    driverId
    licensePlate
  }
}`;

export default updateVehicleQuery;
