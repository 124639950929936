import CircleIcon from '@transferz/fe-component-library/circle-icon';
import Input from '@transferz/fe-component-library/input';
import { useLocalize } from 'localize-react';
import React from 'react';

import { JourneyStatus, JourneyStatusStrings } from '../types/journeyStatus';

type Props = {
  status: JourneyStatus | undefined;
  withoutLabel?: boolean;
};

const StatusIndicator = ({ status, withoutLabel }: Props) => {
  const { translate } = useLocalize();
  const isCancelledOrCompleted =
    status === JourneyStatus.CANCELLED ||
    status === JourneyStatus.COMPLETED ||
    status === JourneyStatus.NO_LONGER_AVAILABLE ||
    status === JourneyStatus.REASSIGNED_TRANSFER_COMPANY ||
    status === JourneyStatus.REASSIGNED_DRIVER;
  const color = isCancelledOrCompleted ? 'asphalt7' : 'atlantis5';
  return (
    <div className="w-12 m-b-16 status-indicator">
      {!withoutLabel ? (
        <Input
          hasValue
          type="text"
          nonEditable
          label={withoutLabel ? '' : translate('status')}
          defaultValue={status && translate(JourneyStatusStrings[status])}
          className="no-padding"
          iconLeft={<CircleIcon width={16} color={color} />}
        />
      ) : (
        <div className="status">
          <CircleIcon width={16} color={color} />
          {status && (
            <div className={`custom-status default-font ${isCancelledOrCompleted ? 'cancelled' : ''}`}>
              {translate(JourneyStatusStrings[status])}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StatusIndicator;
