import React, { useEffect, useMemo, useState } from 'react';

import { getLanguage, storeLanguage } from '../services/store';
import { LanguageContext } from './LanguageContext';
import { fetchTranslations } from './translations';

const WithLanguage = ({ children }: {children: JSX.Element[] | JSX.Element}) => {
  const [language, setLanguage] = useState('');
  const browserLanguage: string = navigator?.language?.split('-')[0];

  useEffect(() => {
    const storedLanguage = getLanguage();

    const lng = storedLanguage || browserLanguage;

    setLanguage(lng);
    fetchTranslations(lng);
  }, []);

  // useEffect(() => {
  //   fetchTranslations(language);
  //   storeLanguage(language);
  // }, [language]);

  const contextObj = useMemo(() => ({ language, setLanguage }), [language]);

  return <LanguageContext.Provider value={contextObj}>{ children }</LanguageContext.Provider>;
};

export default WithLanguage;
