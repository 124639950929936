import { useMutation, useQuery } from '@apollo/client';

import { GetCacheProfile, pruneJourneysQuery } from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const usePruneJourneys = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const [pruneJourneys] = useMutation(pruneJourneysQuery, {
    fetchPolicy: 'no-cache',
  });
  const { data: cachedProfileData } = useQuery(GetCacheProfile);

  const sessionToken = cachedProfileData?.token;

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.pruneJourneys);
  };

  return (
    includedStatuses: string[],
    callback: (data: any) => void,
  ) => offlineProofQuery(() => authenticatedQuery(() => {
    pruneJourneys({
      variables: {
        sessionToken,
        includedStatuses,
      },
    }).then((res) => {
      handleNewData(res, callback);
    });
  }));
};
