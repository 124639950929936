import CarRightIcon from '@transferz/fe-component-library/car-right-icon';
import CircleCheckIcon from '@transferz/fe-component-library/circle-check-icon';
import LocationIcon from '@transferz/fe-component-library/location-icon';
import React from 'react';

import { JourneyStatus } from '../types/journeyStatus';

interface ProgressBarStatusProps {journeyStatus?: JourneyStatus, assignedToAnotherDriver?: boolean}

const ProgressBarStatus = ({ journeyStatus = JourneyStatus.AVAILABLE, assignedToAnotherDriver = true }: ProgressBarStatusProps) => {
  const currentStatus = () => {
    if (!assignedToAnotherDriver) return '';
    const status = journeyStatus?.toString().toLocaleLowerCase();
    return status;
  };

  return (
    <div className={`d-flex progress-bar w-12 m-l-0 p-l-32 p-r-32 p-t-14 p-b-14 bg-color justify-content-space-between status-icons-before position-absolute ${currentStatus()}`}>
      <div className="circle-with-icons carOne">
        <CarRightIcon width={24} color="asphalt4" />
      </div>
      <div className="circle-with-icons location">
        <LocationIcon width={22} color="asphalt4" />
      </div>
      <div className="circle-with-icons carTwo">
        <CarRightIcon width={24} color="asphalt4" />
      </div>
      <div className="circle-with-icons check">
        <CircleCheckIcon width={22} color="asphalt4" />
      </div>
    </div>
  );
};

export default ProgressBarStatus;
