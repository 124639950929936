import { gql } from '@apollo/client';

const SetCacheProfile = gql`
  mutation setCachedProfile {
    name @client
    email @client
    phone @client
    token @client
    refreshToken @client
    accessToken @client
    id @client
    driverId @client
    photo @client
    lastJourneyView @client
    lastJourneyId @client
    created @client
    videoLang @client
  }
`;

export default SetCacheProfile;
