import '@transferz/fe-component-library/style';
import './style/main.scss';

import { useQuery } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import ErrorScreen from '@transferz/fe-component-library/error-screen';
import Loader from '@transferz/fe-component-library/loader';
import Typography from '@transferz/fe-component-library/typography';
import { LocalizationConsumer, LocalizationProvider } from 'localize-react';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Navigate, Route, Routes, useLocation,
  useNavigate,
} from 'react-router-dom';

import { LanguageContext } from './language/LanguageContext';
import { translations } from './language/translations';
import MainLayout from './layout/MainLayout';
import { GetCacheProfile } from './models/graphql';
import GetCacheApiError from './models/graphql/GetCacheApiError';
import DriverInformation from './pages/DriverInformation';
import DriverName from './pages/DriverName';
import EditProfile from './pages/EditProfile';
import JourneyOverview from './pages/JourneyOverview';
import JourneyPage from './pages/JourneyPage';
import Login from './pages/LogIn';
import PinScreen from './pages/PinScreen';
import ProfilePage from './pages/Profile';
import Support from './pages/Support';
import VehiclePage from './pages/VehiclePage';
import WelcomePage from './pages/WelcomePage';
import { useSetApiError, useSetCacheProfile } from './services/apolloCacheHooks';
import { useRetrieveSession, useValidateAccessToken } from './services/apolloNetworkHooks';
import { useNewSession } from './services/apolloNetworkHooks/useNewSession';
import { routes } from './services/constant';
import {
  checkStorageAndCookies,
  getAccessToken,
  getRefreshToken,
  getSessionToken,
  getTokenExpirationTime,
} from './services/store';
import useOnlineStatus from './utils/useOnlineStatus';

const App = () => {
  const navigate = useNavigate();
  const [storageStatus, setStorageStatus] = useState<string>('');
  const [authorizationInProgress, setAuthorizationInProgress] = useState<boolean>(false);
  const [authenticatedUser, setAuthenticatedUser] = useState<boolean>(false);
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const { data: getCacheApiErrorData } = useQuery(GetCacheApiError);
  const location: { [key: string]: any } = useLocation();
  const { language } = useContext(LanguageContext);
  const isOnline = useOnlineStatus();
  const [tokenRefreshTime, setTokenRefreshTime] = useState(0);
  const newSession = useNewSession();
  const validateAccessToken = useValidateAccessToken();
  const setCacheProfile = useSetCacheProfile();
  const setApiError = useSetApiError();
  const retrieveSession = useRetrieveSession();
  const tokenExpirationTime = getTokenExpirationTime();
  const refreshTimeInMilliseconds = tokenExpirationTime ? (Number(tokenExpirationTime) - 60) * 1000 : 0;

  const offlineOverlay = (
    <div id="offline-overlay">
      <ErrorScreen
        title="You are offline!"
        text="Please check your internet connection before further using the app..."
        buttonLabel="Check status"
        onClick={() => {
          if (!isOnline) alert('You are not online still, sorry. :(');
        }}
      />
    </div>
  );

  const handleNewSession = () => {
    if (authorizationInProgress) return;

    newSession(() => {
      setAuthorizationInProgress(false);
    });
  };

  useEffect(() => {
    // User is authenticated
    if (cachedProfileData?.token && cachedProfileData?.refreshToken && cachedProfileData?.driverId) {
      setAuthenticatedUser(true);
      const { driverId, name, email } = cachedProfileData;
      datadogRum.setUser({
        name, email, id: driverId,
      });
      datadogLogs.setUser({
        name, email, id: driverId,
      });
    }
    // User is un-authenticated
    else if (authenticatedUser) {
      if (!cachedProfileData?.token) handleNewSession();

      setAuthenticatedUser(false);
    }
  }, [cachedProfileData]);

  const validateToken = useCallback(() => {
    const availableStore: string = checkStorageAndCookies();
    setStorageStatus(availableStore);

    if (availableStore && availableStore !== 'unavailable') {
      const sessionToken = getSessionToken();
      const refreshToken = getRefreshToken();
      const accessToken = getAccessToken();

      setAuthorizationInProgress(true);
      setCacheProfile({
        token: sessionToken || '',
        refreshToken: refreshToken || '',
        accessToken: accessToken || '',
      }).then((newCacheProfile: any) => {
        validateAccessToken(newCacheProfile, (response: any) => {
          if (!response && location.pathname === '/') {
            navigate('/');
          }
          setAuthorizationInProgress(false);
          if (response?.driverId) {
            setTokenRefreshTime(refreshTimeInMilliseconds);
          }
        });
      });
    }
    else {
      navigate('/');
    }
  }, []);

  const handleRefreshToken = () => {
    const sessionToken = getSessionToken();
    const refreshToken = getRefreshToken();
    const accessToken = getAccessToken();
    setCacheProfile({
      token: sessionToken || '',
      refreshToken: refreshToken || '',
      accessToken: accessToken || '',
    }).then((newCacheProfile: any) => {
      retrieveSession(newCacheProfile.token, newCacheProfile.refreshToken, (response: any) => {
        if (!response && location.pathname === '/') {
          navigate('/');
        }
        const refreshTimeMilliseconds = response?.expiresInSeconds ? (response.expiresInSeconds - 60) * 1000 : 0;
        setTokenRefreshTime(refreshTimeMilliseconds);
      });
    });
  };

  useEffect(() => {
    // Immediately validate the token on component mount
    validateToken();
    if (tokenRefreshTime > 0 && refreshTimeInMilliseconds > 0) {
      const intervalId = setInterval(handleRefreshToken, tokenRefreshTime || refreshTimeInMilliseconds);
      return () => clearInterval(intervalId);
    }
  }, [tokenRefreshTime, refreshTimeInMilliseconds]);

  const removeApiError = () => {
    const pathname = location.pathname;

    if (pathname !== '/journeys' || pathname !== '') {
      navigate(-1);
    }

    setApiError(false);
  };

  // If localStorage and cookies aren't available don't render the application.
  if (storageStatus === 'unavailable') {
    return (
      <LocalizationProvider disableCache locale={language} translations={translations}>
        <MainLayout>
          <div>
            <Typography variant="h4">
              <LocalizationConsumer>{({ translate }) => translate('localStorage_cookies')}</LocalizationConsumer>
            </Typography>
          </div>
        </MainLayout>
      </LocalizationProvider>
    );
  }

  // While waiting for localStorage and cookies availability check don't load the application.
  if (!storageStatus) {
    return (
      <LocalizationProvider disableCache locale={language} translations={translations}>
        <MainLayout>
          <div>
            <Typography variant="h4">
              <LocalizationConsumer>{({ translate }) => translate('checking_settings')}</LocalizationConsumer>
            </Typography>
          </div>
        </MainLayout>
      </LocalizationProvider>
    );
  }

  // While waiting for sessionToken to be created don't load the application.
  if (!cachedProfileData?.token || authorizationInProgress) {
    return (
      <LocalizationProvider disableCache locale={language} translations={translations}>
        <MainLayout>
          <Loader
            // @ts-ignore
            text={<LocalizationConsumer>{({ translate }) => translate('please_wait')}</LocalizationConsumer>}
            fullScreen
          />
        </MainLayout>
      </LocalizationProvider>
    );
  }
  /* ROUTES FOR UN-AUTHENTICATED USERS */
  if (!authenticatedUser) {
    return (
      <LocalizationProvider disableCache locale={language} translations={translations}>
        <MainLayout>
          <Routes>
            {/* ROOT ROUTES */}
            <Route path="/" element={<Login loginType="phone" />} />
            <Route path="*" element={<Navigate to="/" />} />

            {/* SUPPORT */}
            <Route path={routes.support} element={<Support />} />

            {/* LOGIN ROUTES */}
            <Route path={routes.login.email} element={<Login loginType="email" />} />
            <Route path={routes.login.phone} element={<Login loginType="phone" />} />
            <Route
              path={routes.login.pin}
              element={<PinScreen onLoginSuccess={(expiration) => setTokenRefreshTime(expiration)} />}
            />
            <Route path={routes.login.name} element={<DriverName />} />

            {/* JOURNEY ROUTES */}
            <Route path="/:code" element={<JourneyPage />} />
          </Routes>

          {getCacheApiErrorData.status && <ErrorScreen onClick={removeApiError} />}

          {!isOnline && offlineOverlay}
        </MainLayout>
      </LocalizationProvider>
    );
  }

  /* ROUTES FOR AUTHENTICATED USERS */
  return (
    <LocalizationProvider disableCache locale={language} translations={translations}>
      <MainLayout>
        <Routes>
          {/* ROOT ROUTES */}
          <Route path="/" element={<JourneyOverview />}>
            <Route path=":code" element={<JourneyPage />} />
          </Route>

          {/* SUPPORT */}
          <Route path={routes.support} element={<Support />} />

          <Route path={routes.login.name} element={<DriverName />} />
          <Route path={routes.login.welcome} element={<WelcomePage />} />
          <Route path="*" element={<Navigate to="/" />} />

          {/* JOURNEY ROUTES */}
          <Route path={routes.journeys} element={<JourneyOverview />} />
          <Route path={routes.journey} element={<JourneyPage />}>
            <Route path=":code" element={<JourneyPage />} />
          </Route>

          {/* PROFILE ROUTES */}
          <Route path={routes.profile} element={<ProfilePage />} />
          <Route path={routes.information} element={<DriverInformation />} />
          <Route path={`${routes.editProfile}/:path`} element={<EditProfile />} />

          {/* VEHICLE ROUTES */}
          <Route path={routes.newVehicle} element={<VehiclePage vehicleType="new-car" />} />
          <Route path={routes.updateVehicle} element={<VehiclePage vehicleType="update-car" />} />
        </Routes>

        {getCacheApiErrorData.status && <ErrorScreen onClick={removeApiError} />}

        {!isOnline && offlineOverlay}
      </MainLayout>
    </LocalizationProvider>
  );
};

export default App;
