import { gql } from '@apollo/client/core';

const updateDriverQuery = gql`mutation updateDriver($id: Int!, $phone: String!, $name: String!, $accessToken: String!) {
  updateDriver(id: $id, phone: $phone, name: $name, accessToken: $accessToken) {
    success
    message
  }
}`;

export default updateDriverQuery;
