import { useLazyQuery, useQuery } from '@apollo/client';

import { GetCacheProfile, getDriverById } from '../../models/graphql';
import { useSetCacheProfile } from '../apolloCacheHooks';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useGetDriverData = () => {
  const setCacheProfile = useSetCacheProfile();
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const [
    getDriver,
    {
      refetch: getDriverRefetch,
      called,
    },
  ] = useLazyQuery(getDriverById, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = async (
    res: any,
    callback?: (data: any) => void,
  ) => {
    const driverData = { ...res?.data?.getDriver };

    driverData.id = driverData.userId;

    delete driverData.userId;
    delete driverData.created;
    delete driverData.updated;

    await setCacheProfile(driverData);

    if (callback) callback(driverData);
  };

  return (
    callback?: (data: any) => void,
  ) => {
    if (called) {
      return offlineProofQuery(() => authenticatedQuery((accessToken) => {
        getDriverRefetch({
          id: cachedProfileData?.driverId,
          accessToken,
        }).then((res) => {
          handleNewData(res, callback);
        });
      }));
    }

    return offlineProofQuery(() => authenticatedQuery((accessToken) => getDriver({
      variables: {
        id: cachedProfileData?.driverId,
        accessToken,
      },
    }).then((res) => {
      handleNewData(res, callback);
    })));
  };
};
