import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@transferz/fe-component-library/button';
import CameraIcon from '@transferz/fe-component-library/camera-icon';
import CarIcon from '@transferz/fe-component-library/car-icon';
import CarRightIcon from '@transferz/fe-component-library/car-right-icon';
import CircleCheckIcon from '@transferz/fe-component-library/circle-check-icon';
import Dropdown from '@transferz/fe-component-library/dropdown';
import Input from '@transferz/fe-component-library/input';
import Loader from '@transferz/fe-component-library/loader';
import LogoIcon from '@transferz/fe-component-library/logo-icon';
import Notification from '@transferz/fe-component-library/notification';
import PhoneIcon from '@transferz/fe-component-library/phone-icon';
import DialPhoneDropdown from '@transferz/fe-component-library/phone-input';
import ProfileIcon from '@transferz/fe-component-library/profile-icon';
import Typography from '@transferz/fe-component-library/typography';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useLocalize } from 'localize-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import FileUpload from '../components/fileUpload/fileUpload';
import { CacheVehicleMake } from '../models/CacheVehicleMakes';
import { GetCacheProfile } from '../models/graphql';
import { useSetCacheProfile } from '../services/apolloCacheHooks';
import {
  useAddVehicle,
  useAssociateJourney,
  useEditDriverData,
  useGetDriverVehicles,
  useGetVehicleMakes,
  useGetVehicleSeries,
  useLoginEmail,
  useSendOTP,
  useSetDriverPhoto,
} from '../services/apolloNetworkHooks';
import { routes } from '../services/constant';

type FormFields = {
  name: string;
  photo: string;
  licence: string;
  carBrand: number;
  carType: number;
};

type FileType = {
  file: File | null,
  url: string,
}
interface IData {
  value: number;
  label: string;
}

const WelcomePage: () => ReactElement = function () {
  console.log('Debug loading welcome page');
  const classBase = 'welcome-page';
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const setCacheProfile = useSetCacheProfile();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const associateJourney = useAssociateJourney();
  const loginWithEmail = useLoginEmail();
  const sendOTP = useSendOTP();
  const { translate } = useLocalize();
  const [screen, setScreen] = useState<string>('welcome');
  const [profilePicture, setProfilePicture] = useState<FileType>();
  const [carMakes, setCarMakes] = useState<IData[]>([]);
  const [carSeriesDropdown, setCarSeriesDropdown] = useState<IData[]>([]);
  const getVehicleMakes = useGetVehicleMakes();
  const getVehicleSeries = useGetVehicleSeries();
  const addVehicle = useAddVehicle();
  const updateDriverPhoto = useSetDriverPhoto();
  const updateDriver = useEditDriverData();

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>();
  const licenceValue = watch('licence');
  const carBrandValue = watch('carBrand');
  const carTypeValue = watch('carType');
  const driverName = watch('name');

  const changeScreen = (nextScreen: string) => {
    setScreen(nextScreen);
  };

  const navigate = useNavigate();

  const uploadPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = (e.target as HTMLInputElement).files![0];
    const url = URL.createObjectURL(file);
    updateDriverPhoto(file, (data: any) => {
      if (data?.data?.uploadDriverPhoto?.success) {
        setProfilePicture({
          file,
          url,
        });
      }
    });
  };

  const handleInputFocus = () => {
    setError('');
  };

  const handleAddVehicle = () => {
    addVehicle(
      cachedProfileData?.driverId,
      carTypeValue,
      licenceValue,
      () => {
        changeScreen('completed');
      },
    );
  };

  const startJourney = () => {
    console.log('Debug start journey');
    setLoading(true);

    const driverData = { ...cachedProfileData };

    driverData.name = driverName;

    updateDriver(
      driverData.id,
      driverData.phone,
      driverData.name,
      () => {
        console.log('Debug start journey 22');
        if (cachedProfileData?.lastJourneyView) {
          const code = cachedProfileData?.lastJourneyView;
          associateJourney(code, () => {
            setCacheProfile({
              lastJourneyView: '',
            }).then(() => {
              setTimeout(() => {
                setLoading(false);

                navigate(
                  `${routes.journey}/${code}`,
                  { state: { notification: 'addJourneySuccess' } },
                );
              }, 250);
            });
          }, driverData, true);
        }
        else {
          console.log('Debug start journey 33');
          setTimeout(() => {
            setLoading(false);

            navigate(routes.journeys);
          }, 250);
        }
      },
    );
  };

  useEffect(() => {
    console.log('Debug car brand');
    if (!carBrandValue) return;
    // @ts-ignore
    const value = carBrandValue.value ? carBrandValue.value : carBrandValue;
    getVehicleSeries(value, (data) => {
      setCarSeriesDropdown(data);
    });
  }, [carBrandValue]);

  useEffect(() => {
    console.log('Debug getVehicleMakes');
    getVehicleMakes((vehicleMakes: CacheVehicleMake[]) => {
      if (vehicleMakes?.length) {
        setCarMakes(vehicleMakes);
      }
      else {
        console.log('Debug error no vehicles');
        // @TODO write some kind of an error to the user
      }
    });
  }, []);

  if (loading) {
    console.log('Debug loading');
    return (
      <Loader text={translate('please_wait')} fullScreen />
    );
  }

  return (
    <div
      className={`${classBase} ${screen !== 'welcome' && screen !== 'completed' && 'blur'} d-flex flex-column justify-content-center align-items-center`}
      data-testid={classBase}
    >
      {screen !== 'welcome' && (
        <div className="small-logo d-flex align-items-center">
          <LogoIcon className="logo-icon" width={25} />
          <Typography variant="h6" color="asphalt6" className="m-l-11">
            {translate('driver_app')}
          </Typography>
        </div>
      )}

      <div className="logo-wrapper bg-color-atlantis4 d-flex flex-column justify-content-center align-items-center">
        {screen === 'welcome' && <LogoIcon className="logo-icon" width={50} />}
        {screen === 'name' && <ProfileIcon width={50} color="light" />}
        {screen === 'photo' && !profilePicture ? <CameraIcon width={50} color="light" /> :
          screen === 'photo' && profilePicture && <img src={profilePicture?.url} alt="profilePicture" width="100%" height="100%" />}
        {screen === 'vehicle' && <CarRightIcon width={50} color="light" />}
        {screen === 'completed' && <CircleCheckIcon width={50} color="light" />}
      </div>

      <div className="title m-t-24 text-center">
        <Typography variant="h4">
          {screen === 'welcome' && translate('welcome_app_title')}
          {screen === 'name' && translate('whats_your_name')}
          {screen === 'photo' && !profilePicture ? translate('picture_time_title') :
            screen === 'photo' && profilePicture && `${translate('you_look_great')}, ${driverName}!`}
          {screen === 'vehicle' && translate('onboarding_your_vehicle_title')}
          {screen === 'completed' && translate('onboarding_awesome_title')}

        </Typography>
      </div>

      {screen !== 'name' && screen !== 'vehicle' && (
        <div className="subtitle m-t-16">
          <Typography variant="body1">
            {screen === 'welcome' && translate('enjoy_the_app')}
            {screen === 'photo' && !profilePicture && translate('take_picture')}
            {screen === 'completed' && translate('set_for_first_journey')}

          </Typography>
        </div>
      )}

      <div className="content m-t-48 w-12">
        {screen === 'welcome' && (
          <Button
            label={translate('lets_start_button')}
            priority="primary"
            variant="confirm"
            onClick={() => changeScreen('name')}
            data-testid="button_lets-start"
            fullWidth
          />
        )}
        {screen === 'name' && (
          <div>
            <div className="m-b-16">
              <Input
                {...register('name')}
                hasValue={Boolean(watch('name'))}
                type="text"
                fullWidth
                label={translate('onboarding_your_name_label')}
                placeholder={translate('type_your_name')}
                error={errors?.name?.message}
                onFocus={handleInputFocus}
                data-testid="input_your-name"
              />
            </div>
            <Button
              label={translate('next_button')}
              priority="primary"
              variant="confirm"
              disabled={!driverName || driverName?.length < 3}
              onClick={() => changeScreen('photo')}
              data-testid="button_your-name-next"
              fullWidth
            />
          </div>
        )}

        {screen === 'photo' && !profilePicture ? (
          <FileUpload
            text={translate('take_picture_button')}
            onChange={uploadPhoto}
            secondary
          />
        ) : screen === 'photo' && profilePicture && (
          <div>
            <div data-testid="notification_uploaded-successfully">
              <Notification
                variant="success"
                message={translate('photo_uploaded_successfully')}
                className="d-flex justify-content-center"
              />
            </div>
            <Button
              label={translate('next_button')}
              priority="primary"
              variant="confirm"
              fullWidth
              onClick={() => changeScreen('vehicle')}
              className="m-t-16"
            />
          </div>

        )}

        {screen === 'vehicle' && (
          <>
            <Input
              {...register('licence')}
              hasValue={!!licenceValue}
              type="text"
              upperCase
              label={translate('your_plate_number')}
              placeholder={translate('plate_number')}
              className="m-b-24 repetitive"
              defaultValue={licenceValue}
              iconLeft={<CarIcon />}
              confirmed={licenceValue?.length >= 3}
              data-testid="input_license-number"
            />

            <Controller
              name="carBrand"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <Dropdown
                  placeholder={translate('choose_car_brand')}
                  data={carMakes}
                  label={translate('car_brand')}
                  className="m-b-24"
                  value={value}
                  onChange={(brand: IData) => {
                    onChange(brand.value);
                  }}
                  onBlur={onBlur}
                  confirmed={!!carBrandValue}
                  isSearchable
                />
              )}
            />
            <Controller
              name="carType"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <Dropdown
                  placeholder={translate('choose_car_type')}
                  data={carSeriesDropdown}
                  label={translate('car_type')}
                  className="m-t-24"
                  disabled={carBrandValue === 0}
                  value={value}
                  onChange={(type: IData) => {
                    onChange(type.value);
                  }}
                  onBlur={onBlur}
                  confirmed={!!carTypeValue}
                  isSearchable
                />
              )}
            />
            <Button
              label={translate('add_vehicle')}
              className="m-t-24"
              priority="primary"
              variant="confirm"
              disabled={!licenceValue || carBrandValue === 0 || !carTypeValue || carTypeValue === 0}
              fullWidth
              data-testid="button_add-vehicle"
              onClick={handleAddVehicle}
            />
            <div className="later-button p-h-20">
              <Button
                label={translate('do_later_button')}
                priority="secondary"
                variant="confirm"
                fullWidth
                onClick={() => changeScreen('completed')}
              />
            </div>

          </>
        )}

        {screen === 'completed' && (
          <Button
            label={translate('start_first_journey_button')}
            priority="primary"
            variant="confirm"
            data-testid="button_start-first-journey"
            fullWidth
            onClick={startJourney}
          />
        )}

      </div>

    </div>
  );
};

export default withLDConsumer()(WelcomePage);
