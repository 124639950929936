import { Button } from '@transferz/fe-component-library/button';
import PhoneRingIcon from '@transferz/fe-component-library/phone-ring-icon';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, { useEffect, useRef, useState } from 'react';

import { useGetPhoneByCountry, useGetPhoneByLocation } from '../../services/apolloNetworkHooks';

const Contact = () => {
  const { translate } = useLocalize();
  const [customerServicePhone, setCustomerServicePhone] = useState<string>('');
  const getPhoneByCountry = useGetPhoneByCountry();
  const getPhoneByLocation = useGetPhoneByLocation();
  const callLinkRef = useRef<any>();

  const getPhone = (position: any) => {
    if (position.coords) {
      getPhoneByLocation(
        'DRIVER',
        String(position.coords.latitude),
        String(position.coords.longitude),
        '',
        (data: any) => {
          if (data?.success) {
            setCustomerServicePhone(data?.phone);
          }
        },
      );
    }
    else {
      getPhoneByCountry(
        'US',
        'DRIVER',
        '',
        (data: any) => {
          if (data?.success) {
            setCustomerServicePhone(data?.phone);
          }
        },
      );
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // success callback
        getPhone,
        // error callback (position will be undefined)
        getPhone,
      );
    }
  }, []);
  return (
    <div>
      <Typography
        variant="h5"
        color="dark"
        className="m-t-32"
      >
        {translate('contact_us')}
      </Typography>
      <Button
        label={translate('call_service')}
        priority="primary"
        variant="info"
        fullWidth
        icon={<PhoneRingIcon direction="left" height={15} />}
        iconPosition="left"
        onClick={() => {
          callLinkRef?.current?.click();
        }}
        className="m-t-32 mb-20"
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <a
        style={{ display: 'none' }}
        ref={callLinkRef}
        href={`tel:${customerServicePhone}`}
      >
        .
      </a>
    </div>
  );
};

export default Contact;
