import { useLazyQuery, useQuery } from '@apollo/client';

import { GetCacheProfile, getVehiclesByDriverIdQuery } from '../../models/graphql';
import { useSetCacheDriverVehicles } from '../apolloCacheHooks';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useGetDriverVehicles = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const setCacheDriverVehicles = useSetCacheDriverVehicles();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const [
    getVehicles,
    {
      refetch: getVehiclesRefetch,
      called,
    },
  ] = useLazyQuery(getVehiclesByDriverIdQuery, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = (
    res: any,
    callback?: (data: any) => void,
  ) => {
    const data = res?.data?.getVehicles?.results;

    setCacheDriverVehicles(data);

    if (callback) callback(data);
  };

  return (
    callback?: (data: any) => void,
  ) => {
    const variables = {
      driverId: cachedProfileData?.driverId,
      page: 0,
      size: 999,
    };

    if (called) {
      return offlineProofQuery(() => authenticatedQuery((accessToken) => getVehiclesRefetch({
        ...variables,
        accessToken,
      }).then((res) => {
        handleNewData(res, callback);
      })));
    }

    return offlineProofQuery(() => authenticatedQuery((accessToken) => getVehicles({
      variables: {
        ...variables,
        accessToken,
      },
    }).then((res) => {
      handleNewData(res, callback);
    })));
  };
};
