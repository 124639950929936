import { gql } from '@apollo/client/core';

const assignDriverToJourneyQuery = gql`mutation assignJourney($id: Int!, $driverCode: String!, $accessToken: String!) {
  assignJourney(id: $id, code: $driverCode, accessToken: $accessToken) {
    success
    message
    id
    driverId
    driverCode
    created
    updated
    info {
      totalPrice
      totalFare
      canBeCompleted
      canBeCancelled
      completedOn
      nextAction
      status
      destinationLocation {
        id
        address {
          formattedAddress
          originalAddress
          establishment
          streetName
          streetNumber
          city
          postalCode
          region
          countryCode
        }
        hub
      }
      originLocation {
        id
        address {
          formattedAddress
          originalAddress
          establishment
          streetName
          streetNumber
          city
          postalCode
          region
          countryCode
        }
        hub
      }
      quoteId
      journeyCode
      bookingId
      hubId
      id
      created
      updated
      traveller {
        firstName
        lastName
        email
        phone
        flightNumber
        trainNumber
        languageIsoCode
        passengerCount
        luggageCount
      }
      price {
        currencyCode
        fareCurrencyCode
        partnerBusinessModel
        price
        priceVat
        fare
        fareVatLevel
        partnerCommission
        platformFee
        fixedPrice
      }
      execution {
        vehicleCategory
        pickupDate
        pickupTimeZone
        fleetId
        assignedDriverId
        assignedDriverVehicleId
        transferCompanyId
        meetingPointId
        meetingPointType
        durationInSeconds
        executionCode
        distance {
          value
          unit
        }
      }
    }
  }
}`;

export default assignDriverToJourneyQuery;
