import BottomDrawer from '@transferz/fe-component-library/bottom-drawer';
import { Button } from '@transferz/fe-component-library/button';
import Dropdown from '@transferz/fe-component-library/dropdown';
import Input from '@transferz/fe-component-library/input';
import PenIcon from '@transferz/fe-component-library/pen-icon';
import RangeDatePicker from '@transferz/fe-component-library/range-date-picker';
import SortSizeIcon from '@transferz/fe-component-library/sort-size-icon';
import Typography from '@transferz/fe-component-library/typography';
import XIcon from '@transferz/fe-component-library/x-icon';
import { useLocalize } from 'localize-react';
import { debounce } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { filterStatuses } from '../../services/constant';
import { JourneyStatus } from '../../types/journeyStatus';

type JourneyFiltersProps = {
  filterJourneys: (filters: any) => void;
};

const JourneyFilters: FunctionComponent<JourneyFiltersProps> = ({ filterJourneys }) => {
  const { translate } = useLocalize();
  const [isOpen, setIsOpen] = useState(false);
  const [showFlags, setShowFlags] = useState(false);

  const {
    setValue, watch, register, control, reset,
  } = useFormContext();

  const { filters, language, driverCode } = watch();

  const data = [
    {
      value: JourneyStatus.AVAILABLE,
      label: translate('upcoming_filter'),
    },
    {
      value: JourneyStatus.UNDERWAY_TO_PICKUP,
      label: translate('on_way_to_pickup_filter'),
    },
    {
      value: JourneyStatus.ARRIVED_AT_PICKUP,
      label: translate('arrived_at_pickup_filter'),
    },
    {
      value: JourneyStatus.JOURNEY_IN_PROGRESS,
      label: translate('on_way_to_dropoff_filter'),
    },
  ];

  const clearDates = () => {
    setValue('filters', { ...filters, pickupDateAfter: '', pickupDateBefore: '' });
    filterJourneys({ ...filters, pickupDateAfter: '', pickupDateBefore: '' });
  };

  const onResetFilters = () => {
    reset({
      filters: {
        ...filters,
        pickupDateAfter: '',
        pickupDateBefore: '',
        includedStatuses: filterStatuses.upcoming,
      },
      language,
      driverCode,
    });
    filterJourneys({
      textSearch: filters.textSearch,
      pickupDateAfter: '',
      pickupDateBefore: '',
      includedStatuses: filterStatuses.upcoming,
    });
    setShowFlags(false);
  };

  const clearStatus = () => {
    setValue('filters', { ...filters, includedStatuses: filterStatuses.upcoming });
    filterJourneys({ ...filters, includedStatuses: filterStatuses.upcoming });
  };

  const clearSearch = () => {
    setValue('filters', { ...filters, textSearch: '' });
    filterJourneys({ ...filters, textSearch: '' });
  };

  return (
    <div className="journey-filters">
      <div className="m-t-16 m-b-16 d-flex">
        <Input
          {...register('filters.textSearch')}
          className="hiden-label m-r-16 w-12"
          placeholder={translate('search_by_code_location')}
          search
          clearButtonShow={!!filters?.textSearch}
          data-testid="input_text-search"
          clear={clearSearch}
          onChange={(e) => {
            // @ts-ignore
            if (!e.target.value) {
              clearSearch();
            }
            else {
              debounce(() => {
                // @ts-ignore
                setValue('filters.textSearch', e.target.value);
              }, 400)();
            }
          }}
          autoComplete="off"
        />
        <Button
          icon={<SortSizeIcon color="blue5" />}
          label=""
          circle
          onClick={() => setIsOpen(true)}
          className="journey-filters-button"
          priority="secondary"
          data-testid="input_filters-button"
        />
      </div>
      <div className="d-flex justify-content-center align-center">
        <BottomDrawer isOpen={isOpen} title={translate('select_filter_header')} onClose={() => setIsOpen(false)}>
          <div className="p-24 wrapper">
            {/* @ts-ignore */}
            <RangeDatePicker
              label={translate('pickup_date_label')}
              startDateValue={filters?.pickupDateAfter}
              endDateValue={filters?.pickupDateBefore}
              onRangeChange={(start, end) => {
                setValue('filters.pickupDateAfter', start);
                setValue('filters.pickupDateBefore', end);
              }}
              className="m-b-24"
              onClear={clearDates}
            />

            <Controller
              name="filters.includedStatuses"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  value={value}
                  data={data}
                  placeholder="All"
                  label={translate('status')}
                  className="m-b-24 m-t-24"
                  onChange={(e) => onChange(e.value)}
                />
              )}
            />

            <div className="d-flex justify-content-space-between">
              <Button
                label={translate('reset_button')}
                className="m-r-12"
                priority="secondary"
                onClick={onResetFilters}
                data-testid="filters_reset-button"
              />
              <Button
                label={`${translate('show_button')} ${translate('journeys_button')}`}
                className=""
                priority="primary"
                type="submit"
                fullWidth
                data-testid="filters_submit-button"
                onClick={() => {
                  setIsOpen(false);
                  setShowFlags(true);
                }}
              />
            </div>
          </div>
        </BottomDrawer>
      </div>
      {(!!filters.pickupDateAfter || !!filters.pickupDateBefore || !!filters.includedStatuses) && showFlags && (
        <div className="d-flex align-center m-b-16 w-12">
          <Typography variant="subtitle1" className="m-r-16">
            {translate('set_filter_label')}
            :
          </Typography>
          <div className="d-flex w-12">
            {!!(filters.pickupDateAfter || filters.pickupDateBefore) && (
              <div className="p-9 d-flex align-center filter-flag m-r-16">
                <PenIcon color="blue5" className="m-r-10" />
                <Typography className="m-r-12" variant="subtitle1">
                  {translate('remove_date_filter')}
                </Typography>
                <XIcon color="red5" onClick={clearDates} />
              </div>
            )}
            {!!filters.includedStatuses.length && (
              <div className="p-9 d-flex align-center filter-flag">
                <PenIcon color="blue5" className="m-r-10" />
                <Typography className="m-r-12" variant="subtitle1">
                  {translate('remove_status_filter')}
                </Typography>
                <XIcon color="red5" onClick={clearStatus} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default JourneyFilters;
