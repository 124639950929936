import { useMutation } from '@apollo/client';

import {
  createVehicleQuery,
} from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useGetDriverVehicles } from './useGetDriverVehicles';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useAddVehicle = () => {
  const [createVehicle] = useMutation(createVehicleQuery, {
    fetchPolicy: 'no-cache',
  });
  const getDriverVehicles = useGetDriverVehicles();
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    // getDriverVehicles();
    if (callback) callback(res?.data?.createVehicle);
  };

  return (
    driverId: number,
    serieId: number,
    plate: string,
    callback: (data: any) => void,
  ) => offlineProofQuery(() => authenticatedQuery((accessToken) => createVehicle({
    variables: {
      driverId,
      serieId,
      plate: plate.toUpperCase().replace(/ /g, ''),
      accessToken,
    },
  }).then((res) => {
    handleNewData(res, callback);
  })));
};
