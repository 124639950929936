import { useMutation, useQuery } from '@apollo/client';

import { CacheAssociatedJourneys } from '../models/CacheAssociatedJourneys';
import { CacheDriverVehicle } from '../models/CacheDriverVehicles';
import { CacheProfile, CacheProfileEmpty } from '../models/CacheProfile';
import { CacheVehicleMake } from '../models/CacheVehicleMakes';
import {
  GetCacheProfile, SetCacheJourneys, SetCacheProfile, SetCacheVehicleMakes,
} from '../models/graphql';
import GetCacheApiError from '../models/graphql/GetCacheApiError';
import SetCacheApiError from '../models/graphql/SetCacheApiError';
import SetCacheDriverVehicles from '../models/graphql/SetCacheDriverVehicles';
import { storeAccessToken, storeRefreshToken, storeSessionToken } from './store';

export const useSetCacheProfile = () => {
  const [setCacheProfile] = useMutation(SetCacheProfile);

  return (profile: CacheProfile) => new Promise((resolve) => {
    setCacheProfile({
      variables: profile,
      update: (cache) => {
        const ProfileCache = cache.readQuery({
          query: GetCacheProfile,
        });
        let newData: CacheProfile = CacheProfileEmpty;

        // Read existing data
        if (typeof ProfileCache === 'object') newData = { ...ProfileCache };
        // Append changed property values
        Object.keys(profile).forEach((property: any) => {
          // @ts-ignore
          newData[property] = profile[property];
        });

        cache.writeQuery({
          query: SetCacheProfile,
          data: newData,
        });

        storeSessionToken(newData.token ? newData.token : '');
        storeRefreshToken(newData.refreshToken ? newData.refreshToken : '');
        storeAccessToken(newData.accessToken ? newData.accessToken : '');

        resolve(newData);
      },
    });
  });
};

export const useSetCacheAssociatedJourneys = () => {
  const [setCacheJourneys] = useMutation(SetCacheJourneys);

  return (journeysPageData: CacheAssociatedJourneys) => setCacheJourneys({
    variables: journeysPageData,
    update: (cache) => {
      cache.writeQuery({
        query: SetCacheJourneys,
        data: journeysPageData,
      });
    },
  });
};

export const useSetCacheVehicleMakes = () => {
  const [setCacheVehiclesMakes] = useMutation(SetCacheVehicleMakes);

  return (vehicleMakes: CacheVehicleMake[]) => setCacheVehiclesMakes({
    variables: { makes: vehicleMakes },
    update: (cache) => {
      cache.writeQuery({
        query: SetCacheVehicleMakes,
        data: { makes: vehicleMakes },
      });
    },
  });
};

export const useSetCacheDriverVehicles = () => {
  const [setCacheDriverVehicles] = useMutation(SetCacheDriverVehicles);

  return (vehicles: CacheDriverVehicle[]) => setCacheDriverVehicles({
    variables: { vehicles },
    update: (cache) => {
      cache.writeQuery({
        query: SetCacheVehicleMakes,
        data: { vehicles },
      });
    },
  });
};

export const useSetApiError = () => {
  const [setApiError] = useMutation(SetCacheApiError);
  const { data: getCacheApiErrorData } = useQuery(GetCacheApiError);

  return (active: boolean) => setApiError({
    variables: { status: active },
    update: (cache) => {
      cache.writeQuery({
        query: SetCacheApiError,
        data: { status: active },
      });
    },
  });
};
