import { useLazyQuery } from '@apollo/client';

import { getFlightInfoQuery } from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useGetFlightInfo = () => {
  const [
    getFlightInfo,
    {
      refetch: getFlightInfoRefetch,
      called,
    },
  ] = useLazyQuery(
    getFlightInfoQuery,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.getFlightInfo);
  };

  return (
    params: any,
    callback: (data: any) => void,
  ) => offlineProofQuery(() => authenticatedQuery((accessToken) => {
    if (called) {
      getFlightInfoRefetch({ params, accessToken })
        .then((res) => {
          handleNewData(res, callback);
        });
    }

    getFlightInfo({ variables: { params, accessToken } })
      .then((res) => {
        handleNewData(res, callback);
      });
  }));
};
