import { gql } from '@apollo/client/core';

const assignJourneyVehicleQuery = gql`
  mutation assignJourneyVehicle($journeyId: Int!, $vehicleId: Int!, $accessToken: String!) {
    assignJourneyVehicle(journeyId: $journeyId, vehicleId: $vehicleId, accessToken: $accessToken) {
      success
      message
    }
  }
`;

export default assignJourneyVehicleQuery;
