import '@transferz/fe-component-library/style';

import { useQuery } from '@apollo/client';
import BottomDrawer from '@transferz/fe-component-library/bottom-drawer';
import { Button } from '@transferz/fe-component-library/button';
import ChevronIcon from '@transferz/fe-component-library/chevron-icon';
import CircleCheckIcon from '@transferz/fe-component-library/circle-check-icon';
import CircleXIcon from '@transferz/fe-component-library/circle-x-icon';
import ClockIcon from '@transferz/fe-component-library/clock-icon';
import Divider from '@transferz/fe-component-library/divider';
import Dropdown from '@transferz/fe-component-library/dropdown';
import Input from '@transferz/fe-component-library/input';
import Loader from '@transferz/fe-component-library/loader';
import NoResultsIcon from '@transferz/fe-component-library/no-results-icon';
import Notification from '@transferz/fe-component-library/notification';
import QuestionMarkIcon from '@transferz/fe-component-library/question-mark-icon';
import Tabs from '@transferz/fe-component-library/tabs';
import TrashIcon from '@transferz/fe-component-library/trash-icon';
import Typography from '@transferz/fe-component-library/typography';
import VideoCard from '@transferz/fe-component-library/video-card';
import { useLocalize } from 'localize-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import JourneyFilters from '../components/Journey/JourneyFilters';
import JourneyOverviewBox from '../components/JourneyOverviewBox';
import { GetCacheProfile } from '../models/graphql';
import { useSetApiError, useSetCacheProfile } from '../services/apolloCacheHooks';
import {
  useAssociateJourney,
  useDeleteJourney,
  useGetAssociatedJourneys,
  useGetJourneyByCode,
  usePruneJourneys,
} from '../services/apolloNetworkHooks';
import { filterStatuses, routes } from '../services/constant';
import { useAddAction } from '../services/hooks/useAddAction';
import { useDataLayer } from '../services/hooks/useDataLayer';
import { Journey } from '../types/journey';
import { JourneyStatus } from '../types/journeyStatus';
import InstructionalVideo from './InstructionalVideo';
import { InstructionalVideoType } from './Support/Videos';

type JourneyFiltersType = {
  textSearch: string;
  pickupDateAfter: string;
  pickupDateBefore: string;
  includedStatuses: string[];
};

export const JourneyFiltersDefault: JourneyFiltersType = {
  textSearch: '',
  pickupDateAfter: '',
  pickupDateBefore: '',
  includedStatuses: [],
};

const JourneyOverview: () => ReactElement = function () {
  const { translate } = useLocalize();
  const navigate = useNavigate();
  const setCacheProfile = useSetCacheProfile();
  const { trackEvent } = useDataLayer();
  const { addAction } = useAddAction();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const { code } = useParams();
  const getAssociatedJourneys = useGetAssociatedJourneys();
  const getJourneyByCode = useGetJourneyByCode();
  const associateJourney = useAssociateJourney();
  const deleteJourney = useDeleteJourney();
  const pruneJourneys = usePruneJourneys();
  const [codeInputError, setCodeInputError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('upcoming');
  const [journeys, setJourneys] = useState<Journey[]>();
  const [helpDrawer, setHelpDrawer] = useState(false);
  const [videoId, setVideoId] = useState<string>('');
  const setApiError = useSetApiError();
  const [uncompletedJourneysNotification, setUncompletedJourneysNotification] = useState(false);
  const [deleteJourneyDrawer, setDeleteJourneyDrawer] = useState(false);
  const [pruneJourneysDrawer, setPruneJourneysDrawer] = useState(false);
  const [deleteJourneyCode, setDeleteJourneyCode] = useState('');
  const [loadingJourneys, setLoadingJourneys] = useState(true);

  const methods = useForm({
    defaultValues: {
      language: cachedProfileData.videoLang || 'en',
      driverCode: '',
      filters: JourneyFiltersDefault,
    },
  });

  const filters = methods.watch('filters');

  const tabs = [
    {
      label: translate('upcoming'),
      icon: <ClockIcon color="atlantis5" direction="top" />,
      active: activeTab === 'upcoming',
      tab: 'upcoming',
    },
    {
      label: translate('completed'),
      icon: <CircleCheckIcon color="blue4" />,
      active: activeTab === 'completed',
      tab: 'completed',
    },
    {
      label: translate('cancelled'),
      icon: <CircleXIcon color="red4" />,
      active: activeTab === 'cancelled',
      tab: 'cancelled',
    },
  ];

  const languages = [
    { value: 'en', label: `${translate('english')} (EN)`, icon: 'gb' },
    { value: 'it', label: `${translate('italian')} (IT)`, icon: 'it' },
    { value: 'es', label: `${translate('spanish')} (ES)`, icon: 'es' },
    { value: 'pt', label: `${translate('portuguese')} (PT)`, icon: 'pt' },
    { value: 'de', label: `${translate('german')} (DE)`, icon: 'de' },
  ];

  const videos: { [key: string]: InstructionalVideoType } = {
    en: {
      title: 'How to add driver codes using the transferz Driver app',
      videoId: '6pKXSAv4J8g',
      duration: 83,
      cover: 'phone',
    },
    it: {
      title: "Come aggiungere i codici per conducenti utilizzando l'applicazione di Transferz per conducenti",
      videoId: 'xpidbqj1O8s',
      duration: 91,
      cover: 'phone',
    },
    es: {
      title: 'Cómo agregar códigos de conductor usando la aplicación Transferz Driver',
      videoId: 'F8fztwfVuRM',
      duration: 84,
      cover: 'phone',
    },
    pt: {
      title: "Como adicionar códigos de motorista usando o 'Driver App' da Transferz",
      videoId: '4ESlyjtUNts',
      duration: 342,
      cover: 'phone',
    },
    de: {
      title: 'Hinzufügen von Fahrercodes mit der transferz Driver App',
      videoId: 'RPi4M4zooPc',
      duration: 119,
      cover: 'phone',
    },
  };

  const codeValue = methods.watch('driverCode');
  const currentLanguage = methods.watch('language');
  const sortUpcomingJourneys = (items: Journey[]) => {
    const currentDate = new Date();
    const delayed = items.filter((journey: Journey) => new Date(journey.execution.pickupDate) < currentDate);
    const onTime = items.filter((journey: Journey) => new Date(journey.execution.pickupDate) >= currentDate);

    return [
      ...delayed.sort((a, b) => Date.parse(a.execution.pickupDate) - Date.parse(b.execution.pickupDate)),
      ...onTime.sort((a, b) => Date.parse(a.execution.pickupDate) - Date.parse(b.execution.pickupDate)),
    ];
  };

  const getJourneys = () => {
    if (filters.includedStatuses.length === 0) return setLoading(false);

    getAssociatedJourneys(filters, (data: any) => {
      if (data.success) {
        const items: Journey[] = data?.items;
        const sortedJourneys: Journey[] =
          activeTab === 'upcoming'
            ? sortUpcomingJourneys(items)
            : items.sort((a, b) => Date.parse(b.execution.pickupDate) - Date.parse(a.execution.pickupDate));
        setJourneys(sortedJourneys);
        setLoadingJourneys(false);
        console.log(`INFO Retrieved ${sortedJourneys.length} ${activeTab} journeys`);
      }
      else {
        setApiError(true);
        setLoading(false);
      }
    });
  };

  const addJourney = () => {
    const driverCode = codeValue.replace(/ /g, '').toUpperCase();
    console.log(`DEBUG User clicked on Add button. Adding journey with driver code ${driverCode}`);

    const handleFailedResponse = () => {
      setLoading(false);
      setIsCodeValid(false);
      setCodeInputError(translate('incorrect_code'));
    };

    setLoading(true);

    getJourneyByCode(driverCode, (getJourneyData: any) => {
      if (getJourneyData?.success) {
        associateJourney(driverCode, (associateJourneyData: any) => {
          if (associateJourneyData.success) {
            setCodeInputError('');
            setIsCodeValid(true);
            getJourneys();
            trackEvent({
              event: 'journey-added',
              driverCode,
              journeyCode: associateJourneyData?.info?.journeyCode,
            });
            addAction('journey-added', {
              driverCode,
              journeyCode: associateJourneyData?.info?.journeyCode,
            });
            setTimeout(() => {
              console.log(`INFO Navigating to journey details page for journey ${driverCode}`);
              navigate(`${routes.journey}/${driverCode}`, { state: { notification: 'addJourneySuccess' } });
            }, 500);
          }
          else {
            handleFailedResponse();
          }
        });
      }
      else {
        handleFailedResponse();
      }
    });
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    setLoadingJourneys(true);
    console.log(`DEBUG Retrieving the list of ${tab} journeys...`);

    methods.setValue('filters', {
      ...JourneyFiltersDefault,
      includedStatuses: filterStatuses[tab],
    });
  };

  const toggleHelpDrawer = () => {
    setHelpDrawer(!helpDrawer);
  };

  const navigateToSupportVideos = () => {
    navigate(routes.support);
  };

  const openDeleteJourneyDrawer = (driverCode: string) => {
    setDeleteJourneyDrawer(true);
    setDeleteJourneyCode(driverCode);
  };

  const handleDeleteJourney = () => {
    deleteJourney(deleteJourneyCode, (deleteJourneyData: any) => {
      if (deleteJourneyData.success) {
        getJourneys();
        setDeleteJourneyDrawer(false);
        setDeleteJourneyCode('');
      }
    });
  };

  const openPruneJourneysDrawer = () => {
    setPruneJourneysDrawer(true);
  };

  const handlePruneJourneys = () => {
    const statuses =
      activeTab === 'completed'
        ? [JourneyStatus.COMPLETED]
        : [JourneyStatus.CANCELLED, JourneyStatus.NO_LONGER_AVAILABLE];

    pruneJourneys(statuses, (pruneJourneysData: any) => {
      if (pruneJourneysData.success) {
        getJourneys();
        setPruneJourneysDrawer(false);
      }
    });
  };

  useEffect(() => {
    if (activeTab === 'upcoming') {
      journeys?.forEach((journey: Journey) => {
        const journeyStarted =
          journey.status === JourneyStatus.JOURNEY_IN_PROGRESS ||
          journey.status === JourneyStatus.UNDERWAY_TO_PICKUP ||
          journey.status === JourneyStatus.ARRIVED_AT_PICKUP;

        const refDate = Date.now() - 1000 * 60 * 60 * 3;

        if (journeyStarted && new Date(journey.execution.pickupDate) < new Date(refDate)) {
          setUncompletedJourneysNotification(true);
        }
      });
    }
  }, [activeTab, journeys]);

  useEffect(() => {
    if (isCodeValid) {
      setIsCodeValid(false);
    }
  }, [codeValue]);

  useEffect(() => {
    getJourneys();
  }, [filters, filters.textSearch]);

  useEffect(() => {
    // If driver opened a driver link with a code
    console.log('INFO Journey overview page loaded');
    if (code && code.length >= 6) {
      return navigate(`${routes.journey}/${code}`);
    }
  }, []);

  if (loading) {
    console.log('INFO Loading Journey Overview page...');
    return <Loader text={translate('please_wait')} fullScreen />;
  }

  return (
    <div className="journey-overview-page">
      <Typography variant="h4" className="m-b-21" noMargins>
        {translate('page_title')}
      </Typography>
      <div className="d-flex align-items-center m-b-21">
        <Typography variant="h5" className="m-r-10">
          {translate('page_subtitle')}
        </Typography>
        <QuestionMarkIcon className="pointer" onClick={toggleHelpDrawer} />
      </div>
      <div className={`d-flex justify-content-space-between ${codeInputError === '' ? 'm-b-24' : 'm-b-44'}`}>
        <div className="d-flex flex-column flex-grow-1 align-items-stretch justify-content-center">
          <Input
            hasValue={Boolean(methods.watch('driverCode'))}
            type="text"
            className="margin-right-13"
            placeholder={translate('code_placeholder')}
            fullWidth
            confirmed={isCodeValid}
            label={translate('driver_code')}
            upperCase
            error={codeInputError}
            data-testid="input_driver-code"
            onChange={(e: any) => {
              e.target.value = e.target.value
                .replace(/ /g, '')
                .replace(/(.{3})/g, '$1 ')
                .trim();
              methods.setValue('driverCode', e.target.value);
            }}
            maxLength={11}
          />
        </div>
        <div
          className="d-flex flex-column align-items-stretch justify-content-center p-l-8"
          style={{
            width: '120px',
          }}
        >
          <Button
            label={translate('add')}
            onClick={addJourney}
            className="m-l-8"
            variant="info"
            priority="primary"
            disabled={!codeValue}
            data-testid="button_add-driver-code"
          />
        </div>
      </div>
      {uncompletedJourneysNotification && (
        <div>
          <Notification variant="error" message={translate('uncompleted_journeys_message')} />
        </div>
      )}
      <div className="m-t-24 m-b-16">
        <Tabs tabs={tabs} onChange={handleTabClick} />
      </div>
      {loadingJourneys ? (
        <div className="m-t-100">
          <Loader />
        </div>
      ) : (
        <>
          {activeTab === 'upcoming' ? (
            <>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(() => getJourneys())}>
                  <JourneyFilters filterJourneys={getJourneys} />
                </form>
              </FormProvider>
              {!journeys?.length && (
                <Notification
                  icon={<NoResultsIcon />}
                  variant="info"
                  message={translate('no_journeys_found_title')}
                  additionalText={translate('No_journeys_found_text')}
                />
              )}
            </>
          ) : (
            <div className="m-v-29">
              <div
                className="d-flex align-items-center pointer justify-content-center"
                onClick={openPruneJourneysDrawer}
              >
                <CircleXIcon color="asphalt7" width={22} />
                <Typography
                  data-testid={`${activeTab === 'completed' ? 'clear_completed_button' : 'clear_cancelled_button'}`}
                  variant="body1"
                  color="asphalt6"
                  className="m-l-13"
                >
                  {translate(activeTab === 'completed' ? 'clear_completed_button' : 'clear_cancelled_button')}
                </Typography>
              </div>
            </div>
          )}

          {journeys ? (
            <div>
              {journeys?.map((journey: Journey) => (
                <JourneyOverviewBox
                  driverCode={journey.driverCode}
                  key={journey.journeyCode}
                  onClick={() => {
                    console.log(`DEBUG User clicked on journey ${journey.driverCode}, opening details page...`);
                    navigate(`${routes.journey}/${journey.driverCode}`);
                  }}
                  startLocation={journey?.originLocation?.address?.formattedAddress || translate('N_A')}
                  endLocation={journey?.destinationLocation?.address?.formattedAddress || translate('N_A')}
                  status={journey.status}
                  pickupDate={journey?.execution?.pickupDate}
                  startLocationHub={journey?.originLocation?.hubInfo?.hubType}
                  endLocationHub={journey?.destinationLocation?.hubInfo?.hubType}
                  addons={journey?.addons}
                  handleDelete={() => openDeleteJourneyDrawer(journey?.driverCode)}
                  flightNumber={journey.traveller.flightNumber}
                  estimatedTime={journey.flightInfo?.estimatedTime || ''}
                  scheduledTime={journey.flightInfo?.scheduledTime || ''}
                  delay={journey.flightInfo?.delay}
                  flightStatus={journey.flightInfo?.status}
                  shipName={journey?.traveller?.shipName}
                />
              ))}
            </div>
          ) : (
            activeTab === 'upcoming' && (
              <div>
                <Typography variant="h5" className="margin-bottom-21">
                  {translate('available_journeys')}
                </Typography>
                <Typography variant="body1">{translate('no_upcoming_journeys')}</Typography>
              </div>
            )
          )}
        </>
      )}

      <BottomDrawer
        isOpen={helpDrawer}
        onClose={toggleHelpDrawer}
        title={translate('how_to_add_codes')}
        text={translate('how_to_add_codes_explanation')}
      >
        <Divider className="m-b-24" />
        <Controller
          name="language"
          control={methods.control}
          render={({ field: { value, onChange, onBlur } }) => (
            <Dropdown
              label={translate('language')}
              data={languages}
              value={value}
              onChange={(lang: any) => {
                // @ts-ignore
                setCacheProfile({ videoLang: lang.value }).then(() => {
                  onChange(lang.value);
                });
              }}
              onBlur={onBlur}
              className="m-b-24"
              noValidation
            />
          )}
        />
        <VideoCard
          title={videos[currentLanguage].title}
          duration={videos[currentLanguage].duration}
          onCardClick={() => setVideoId(videos[currentLanguage].videoId)}
          cover={videos[currentLanguage].cover as any}
          className="m-b-24"
        />
        <Divider className="m-b-24" />
        <Button
          fullWidth
          label={translate('all_instructional_videos')}
          icon={<ChevronIcon direction="right" color="light" width={15} />}
          iconPosition="right"
          variant="info"
          priority="primary"
          className="m-b-10"
          onClick={navigateToSupportVideos}
        />
      </BottomDrawer>
      <BottomDrawer
        isOpen={deleteJourneyDrawer}
        title={translate('delete_journey_title')}
        text={translate('delete_journey_text')}
        onClose={() => setDeleteJourneyDrawer(false)}
      >
        <Button
          label={translate('delete_journey_button')}
          priority="primary"
          variant="cancel"
          icon={<TrashIcon fill="light" height={15} />}
          iconPosition="left"
          fullWidth
          onClick={handleDeleteJourney}
          className="m-b-10"
        />
      </BottomDrawer>
      <BottomDrawer
        isOpen={pruneJourneysDrawer}
        title={translate(activeTab === 'completed' ? 'clear_completed_title' : 'clear_cancelled_title')}
        text={translate(activeTab === 'completed' ? 'clear_completed_text' : 'clear_cancelled_text')}
        onClose={() => setPruneJourneysDrawer(false)}
      >
        <Button
          label={translate('clear_confirmation_button')}
          priority="primary"
          variant="cancel"
          icon={<CircleXIcon fill="light" height={22} />}
          iconPosition="left"
          fullWidth
          onClick={handlePruneJourneys}
          className="m-b-10"
        />
      </BottomDrawer>
      <InstructionalVideo isOpen={!!videoId} videoId={videoId} onClose={() => setVideoId('')} />
    </div>
  );
};

export default JourneyOverview;
