import { useQuery } from '@apollo/client';
import AvatarImageUpload from '@transferz/fe-component-library/avatar-image-upload';
import BottomDrawer from '@transferz/fe-component-library/bottom-drawer';
import { Button } from '@transferz/fe-component-library/button';
import PlusCircleIcon from '@transferz/fe-component-library/circle-plus-icon';
import Input from '@transferz/fe-component-library/input';
import Loader from '@transferz/fe-component-library/loader';
import LogoutIcon from '@transferz/fe-component-library/logout-icon';
import Notification from '@transferz/fe-component-library/notification';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import StickyBackButton from '../components/StickyBackButton';
import Vehicle from '../components/Vehicle';
import { CacheDriverVehicle } from '../models/CacheDriverVehicles';
import { CacheProfileEmpty } from '../models/CacheProfile';
import { GetCacheProfile } from '../models/graphql';
import { useSetCacheProfile } from '../services/apolloCacheHooks';
import { useGetDriverData, useGetDriverVehicles, useSetDriverPhoto } from '../services/apolloNetworkHooks';
import { routes } from '../services/constant';
import { NotificationType } from '../types/global';

const ProfilePage: FunctionComponent = () => {
  const location: { [key: string]: any } = useLocation();
  const navigate = useNavigate();
  const { translate } = useLocalize();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);

  const [vehicles, setVehicles] = useState<CacheDriverVehicle[]>([]);
  const [displayPage, setDisplayPage] = useState<boolean>(false);
  const [logoutBottomDrawer, setLogoutBottomDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [notification, showNotification] = useState<NotificationType>();

  const setCacheProfile = useSetCacheProfile();
  const getDriverVehicles = useGetDriverVehicles();
  const getDriverData = useGetDriverData();
  const updateDriverPhoto = useSetDriverPhoto();

  const handleLogout = () => {
    console.log('DEBUG User clicked on logout, login out...');
    setCacheProfile(CacheProfileEmpty).then(() => {
      navigate('/');
    });
  };

  const handleAddNewVehicle = () => {
    navigate(routes.newVehicle, { state: { route: 'profile' } });
  };

  const handleUploadPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('DEBUG User clicked on upload photo');
    const file = (e.target as HTMLInputElement).files![0];
    setLoading(true);

    updateDriverPhoto(file, (data: any) => {
      if (data?.data?.uploadDriverPhoto?.success) {
        console.log(`INFO Successfully changed photo to: ${file?.name} file size: ${file?.size} `);
        if (!data?.url) {
          showNotification({ text: translate('uploaded_photo'), variant: 'success' });
        }
        else {
          showNotification({ text: translate('changed_photo'), variant: 'success' });
        }
        setLoading(false);
      }
      else {
        showNotification({ text: translate('could_not_save'), variant: 'error' });
      }
    });
  };

  useEffect(() => {
    if (cachedProfileData?.email) {
      setDisplayPage(true);

      getDriverVehicles((newVehicles: CacheDriverVehicle[]) => {
        newVehicles.sort((a, b) => Date.parse(b.updated) - Date.parse(a.updated));
        setVehicles(newVehicles);
        setLoading(false);
      });
    }
    // If user is driver with driverId (without email) fetch driver data
    else if (cachedProfileData?.driverId && !displayPage) {
      getDriverData(() => {
        setLoading(false);

        getDriverVehicles((newVehicles: CacheDriverVehicle[]) => {
          newVehicles.sort((a, b) => Date.parse(b.updated) - Date.parse(a.updated));
          setVehicles(newVehicles);
          setLoading(false);
        });

        setDisplayPage(true);
      });
    }
    if (notification) {
      setTimeout(() => {
        showNotification(undefined);
      }, 20000);
    }
  }, [cachedProfileData, notification]);

  useEffect(() => {
    console.log('INFO Profile page loaded');
    if (location?.state?.notification) {
      window.history.replaceState({}, document.title);

      switch (location?.state?.notification) {
        case 'updateProfile':
          showNotification({ text: translate('settings_updated'), variant: 'success' });
          break;
        case 'delete':
          showNotification({ text: translate('deleted_vehicle'), variant: 'success' });
          break;
        case 'update':
          showNotification({ text: translate('changed_details'), variant: 'success' });
          break;
        case 'create':
          showNotification({ text: translate('add_new_vehicle'), variant: 'success' });
          break;
        default:
          return;
      }

      setTimeout(() => {
        showNotification(undefined);
      }, 2000);
    }
  }, []);

  if (loading) {
    return <Loader text={translate('please_wait')} fullScreen />;
  }

  return (
    <div data-testid="profile-page" className="profile-page d-flex flex-column m-b-80">
      <StickyBackButton
        buttonText={translate('overview')}
        onClick={() => {
          console.log('DEBUG User clicked back button, navigating to journey overview page...');
          navigate('/journeys');
        }}
      />

      {!displayPage ? (
        <Typography variant="h6">{translate('loading')}</Typography>
      ) : (
        <div className="position-relative h-12">
          <div className="d-flex justify-content-space-between m-b-15">
            <Typography variant="h4" className="margin-bottom-34">
              {translate('your_profile')}
            </Typography>

            <AvatarImageUpload photo={cachedProfileData.photo} onChange={handleUploadPhoto} />
          </div>

          <div className="d-flex justify-content-space-between align-items-center m-b-20">
            <Input
              label={translate('your_name_label')}
              defaultValue={cachedProfileData?.name}
              type="text"
              nonEditable
              data-testid="input_profile-name"
            />
            <span onClick={() => navigate(`${routes.editProfile}/name`)}>
              <Typography variant="button" color="blue5" className="p-r-34 pointer" data-testid="edit_name_button">
                {translate('edit')}
              </Typography>
            </span>
          </div>

          <div className="d-flex justify-content-space-between align-items-center m-b-20">
            <Input
              label={cachedProfileData?.phone ? translate('mobile_number') : translate('email')}
              defaultValue={cachedProfileData?.phone || cachedProfileData?.email || 'N/A'}
              data-testid="input_phone-number"
              type="text"
              nonEditable
            />

            <span onClick={() => navigate(`${routes.editProfile}/phone`)}>
              <Typography variant="button" color="blue5" className="p-r-34 pointer" data-testid="edit_phone_button">
                {translate('edit')}
              </Typography>
            </span>
          </div>

          <div className="vehicles-wrapper">
            <Typography variant="caption">{translate('your_vehicles')}</Typography>

            {!!vehicles.length &&
              vehicles.map((vehicle: CacheDriverVehicle) => (
                <div key={vehicle.id} className="m-t-5">
                  <Vehicle vehicle={vehicle} nonEditable />
                </div>
              ))}

            <div className="d-flex align-items-center justify-content-center m-t-22">
              <div
                className="d-flex align-items-center pointer"
                onClick={handleAddNewVehicle}
                data-testid="add-vehicle_button"
              >
                <PlusCircleIcon color="asphalt7" />
                <Typography variant="button" color="asphalt6" className="m-l-15 m-t-1">
                  {translate('new_vehicle')}
                </Typography>
              </div>
            </div>
          </div>

          {!notification ? (
            <div className="d-flex align-items-center justify-content-center m-t-22 button-wrapper-bottom position-fixed bottom-0 left-0 p-16 w-12 z-3">
              <div
                className="d-flex align-items-center pointer"
                onClick={() => setLogoutBottomDrawer(true)}
                data-testid="button_logout"
              >
                <Typography variant="button" color="red5">
                  {translate('log_out')}
                </Typography>
              </div>
            </div>
          ) : (
            <div className="button-wrapper-bottom position-fixed bottom-0 left-0 p-16 w-12 z-3">
              <Notification variant={notification.variant} message={notification.text} isSnackBar />
            </div>
          )}
        </div>
      )}

      <BottomDrawer
        isOpen={logoutBottomDrawer}
        title={translate('log_out_driver_app')}
        text={translate('confirmation_question_for_logout')}
        onClose={() => setLogoutBottomDrawer(false)}
      >
        <Button
          priority="primary"
          iconPosition="left"
          variant="cancel"
          onClick={handleLogout}
          label={translate('log_out_button_confirmation')}
          icon={<LogoutIcon />}
          fullWidth
        />
      </BottomDrawer>
    </div>
  );
};

export default ProfilePage;
