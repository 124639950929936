import { gql } from '@apollo/client/core';

const loginWithPhoneQuery = gql`
    query authPhone($phone: String!, $otp: String!, $sessionToken: String!) {
        authPhone(phone: $phone, otp: $otp, sessionToken: $sessionToken) {
          success
          message
          userId
          driverId
          name
          phone
          created
          token
          refreshToken
          accessToken
          updated
          photo
          expiresInSeconds
      }
    }
`;

export default loginWithPhoneQuery;
