import { useLazyQuery, useQuery } from '@apollo/client';

import { getAssociatedJourneysQuery, GetCacheProfile } from '../../models/graphql';
import { useDataLayer } from '../../services/hooks/useDataLayer';
import { useSetCacheAssociatedJourneys } from '../apolloCacheHooks';
import { journeysPerPage } from '../constant';
import { useAddAction } from '../hooks/useAddAction';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useGetAssociatedJourneys = () => {
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const setCacheJourneys = useSetCacheAssociatedJourneys();
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const { trackEvent } = useDataLayer();
  const { addAction } = useAddAction();

  const [
    getAssociatedJourneys,
    {
      refetch: associatedJourneysRefetch,
      called,
    },
  ] = useLazyQuery(getAssociatedJourneysQuery, {
    fetchPolicy: 'no-cache',
  });

  const sessionToken = cachedProfileData?.token;

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    const journeysData = res?.data?.getAssociatedJourneys;

    delete journeysData?.message;

    setCacheJourneys(journeysData).then(() => {
      if (callback) callback(journeysData);
    });
  };

  return (
    filters: any,
    callback: (data: any) => void,
  ) => {
    if (filters.pickupDateAfter) {
      filters.pickupDateAfter.setHours(23, 59, 59);
    }
    if (filters.pickupDateAfter) {
      filters.pickupDateBefore.setHours(23, 59, 59);
    }
    if (called) {
      return offlineProofQuery(authenticatedQuery((accessToken) => associatedJourneysRefetch({
        page: 0,
        token: sessionToken,
        accessToken,
        size: journeysPerPage,
        textSearch: filters.textSearch,
        pickupDateAfter: filters.pickupDateAfter ? filters.pickupDateAfter.toISOString() : '',
        pickupDateBefore: filters.pickupDateBefore ? filters.pickupDateBefore.toISOString() : '',
        includedStatuses: filters.includedStatuses,
      }).then((res) => {
        handleNewData(res, callback);
      }).finally(() => {
        trackEvent({
          event: 'token-tracking',
          sessionToken,
          accessToken,
          url: window.location.href,
        });
        addAction('token-tracking', {
          sessionToken,
          accessToken,
          url: window.location.href,
        });
      })));
    }

    return offlineProofQuery(() => authenticatedQuery((accessToken) => getAssociatedJourneys({
      variables: {
        page: 0,
        token: sessionToken,
        accessToken,
        size: journeysPerPage,
        textSearch: filters.textSearch,
        pickupDateAfter: filters.pickupDateAfter ? filters.pickupDateAfter.toISOString() : '',
        pickupDateBefore: filters.pickupDateBefore ? filters.pickupDateBefore.toISOString() : '',
        includedStatuses: filters.includedStatuses,
      },
    }).then((res) => {
      handleNewData(res, callback);
    }).finally(() => {
      trackEvent({
        event: 'token-tracking',
        sessionToken,
        accessToken,
        url: window.location.href,
      });
      addAction('token-tracking', {
        sessionToken,
        accessToken,
        url: window.location.href,
      });
    })));
  };
};
