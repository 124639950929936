import { useLazyQuery } from '@apollo/client';

import { CAPTCHA_TOKEN } from '../../captchaTypeDefs';

export const useQueryCaptchaToken = (): any => {
  const [
    getCaptchaToken,
  ] = useLazyQuery(
    CAPTCHA_TOKEN,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.captchaToken);
  };

  return (callback: (data: any) => void) => getCaptchaToken({})
    .then((res) => {
      handleNewData(res, callback);
    });
};
