import { useMutation, useQuery } from '@apollo/client';

import { GetCacheProfile } from '../../models/graphql';
import updateDriverQuery from '../../models/graphql/UpdateDriver';
import { standardizePhoneNumber } from '../../utils/parsingFunctions';
import { useSetCacheProfile } from '../apolloCacheHooks';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useEditDriverData = () => {
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const setCacheProfile = useSetCacheProfile();
  const [updateDriver] = useMutation(updateDriverQuery, {
    fetchPolicy: 'no-cache',
  });

  return (
    id: number,
    phone: string,
    name: string,
    callback: (data: any) => void,
  ) => offlineProofQuery(() => authenticatedQuery((accessToken) => {
    updateDriver({
      variables: {
        id,
        phone: standardizePhoneNumber(phone),
        name,
        accessToken,
      },
    }).then(async (res) => {
      if (res?.data?.updateDriver?.success) {
        const driverData = {
          ...cachedProfileData,
          phone,
          name,
        };

        await setCacheProfile(driverData);
      }

      if (callback) callback(res?.data?.updateDriver);
    });
  }));
};
