import { gql } from '@apollo/client/core';

const getJourneyByCodeQuery = gql`query getJourneyByDriverCode($code: String!, $accessToken: String) {
  getJourneyByDriverCode(code: $code, accessToken: $accessToken) {
    success
    message
    totalPrice
    totalFare
    canBeCompleted
    canBeCancelled
    completedOn
    nextAction
    status
    destinationLocationId
    originLocationId
    quoteId
    journeyCode
    driverCode
    bookingId
    hubId
    id
    created
    updated
    destinationLocation {
        id
        address {
            formattedAddress
            originalAddress
            establishment
            streetName
            streetNumber
            city
            postalCode
            region
            countryCode
        }
        hubInfo {
          name
          hubType
        }
        hub
        position {
          lat
          lng
        }
    }
    originLocation {
        id
        address {
            formattedAddress
            originalAddress
            establishment
            streetName
            streetNumber
            city
            postalCode
            region
            countryCode
        }
        hubInfo {
          name
          hubType
          details {
            code
            value
          }
        }
        hub
        position {
          lat
          lng
        }
    }
    traveller {
        firstName
        lastName
        email
        phone
        flightNumber
        trainNumber
        shipName
        languageIsoCode
        passengerCount
        luggageCount
        meetAndGreetSignText
        driverComments
        phoneVerificationInfo {
          formattedPhone
        }
    }
    price {
        currencyCode
        fareCurrencyCode
        partnerBusinessModel
        price
        priceVat
        fare
        fareVatLevel
        partnerCommission
        platformFee
        fixedPrice
    }
    addons {
        addonType
        amount
        price {
            currencyCode
            fareCurrencyCode
            partnerBusinessModel
            price
            priceVat
            fare
            fareVatLevel
            partnerCommission
            platformFee
            fixedPrice
        }
    }
    execution {
        vehicleCategory
        pickupDate
        pickupTimeZone
        fleetId
        assignedDriverId
        assignedDriverVehicleId
        transferCompanyId
        meetingPointId
        meetingPointType
        durationInSeconds
        executionCode
        distance {
            value
            unit
        }
    }
    meetingPoint {
        id
        geographicLocation {
            lat
            lng
        }
        title
        description
    }
    transferCompany {
      id
      name
      transferCompany
      openingHoursFrom
      openingHoursTo
      fullTimeSupport
      supportPhone
      supportEmail
    }
    flightInfo {
      status 
      scheduledTime 
      estimatedTime 
      airline
      delay 
      city
    }
  }
}`;

export default getJourneyByCodeQuery;
