import { monthNames } from '../services/constant';

export const parseDate = (date: any) => {
  const newDate = new Date(date);

  const day = newDate.getDate();
  const month = newDate.getMonth();
  const year = newDate.getFullYear();

  return `${day} ${monthNames[month]} ${year}`;
};

export const parseDateTime = (date: any) => {
  const newDate = new Date(date);

  const day = newDate.getDate();
  const month = newDate.getMonth();
  const year = newDate.getFullYear();
  const hour = newDate.getHours().toString().padStart(2, '0');
  const minute = newDate.getMinutes().toString().padStart(2, '0');

  return `${day} ${monthNames[month]} ${year} ${hour}:${minute}`;
};

export const parseTime = (date: any) => {
  const newDate = new Date(date);

  const hour = newDate.getHours().toString().padStart(2, '0');
  const minute = newDate.getMinutes().toString().padStart(2, '0');

  return `${hour}:${minute} `;
};

export const daysInMonth = (date: Date) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  return new Date(year, month, 0).getDate();
};

export const tomorrowOrToday = (date: Date) => {
  const nowDate = new Date();
  let todayOrTomorrow = '';
  const journeyDate = new Date(date!);
  const timeDifference = (journeyDate.getTime() - nowDate.getTime()) / 1000 / 60 / 60;
  if (date && timeDifference > -24 && timeDifference < 48) {
    let dateNowDate; let dateJourneyDate;
    if (journeyDate.getMonth() === nowDate.getMonth()) {
      dateNowDate = nowDate.getDate();
      dateJourneyDate = journeyDate.getDate();
    }
    else if (journeyDate.getMonth() > nowDate.getMonth()) {
      dateNowDate = nowDate.getDate();
      dateJourneyDate = daysInMonth(journeyDate) + journeyDate.getDate();
    }
    else {
      dateNowDate = daysInMonth(nowDate) + nowDate.getDate();
      dateJourneyDate = journeyDate.getDate();
    }
    const betw = dateJourneyDate - dateNowDate;
    if (betw === 0) todayOrTomorrow = 'date_today';
    if (betw === 1) todayOrTomorrow = 'date_tomorrow';
  }
  return todayOrTomorrow;
};

export const before24Hours = (date: Date): boolean => {
  const nowDate = new Date();
  let is24Hours = false;
  const journeyDate = new Date(date!);
  const timeDifference = (journeyDate.getTime() - nowDate.getTime()) / 1000 / 60 / 60;

  if (timeDifference < 24) is24Hours = true;

  return is24Hours;
};

export const parseString = (string:string) => {
  const newString = string.replace('_', ' ').toLowerCase();
  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export const standardizePhoneNumber = (phone: string) => {
  const phoneParts = phone.split(' ');

  if (!phoneParts[1]) return phone;

  const phonePrefix = phoneParts[0];
  let phoneNumber = phoneParts[1]
    .replace(/ /g, '')
    .replace(/-/g, '')
    .replace(/\\/g, '')
    .replace(/\//g, '');

  // @ts-ignore
  if (String(phoneNumber[0]) === '0') phoneNumber = phoneNumber.slice(1, phoneNumber.length);

  return phonePrefix + phoneNumber;
};
