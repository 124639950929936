import { gql } from '@apollo/client/core';

const getVehicleModels = gql`
  query getVehicleModels($makeId: Int!) {
    getVehicleModels(makeId: $makeId, page: 0, size: 9999) {
      success
      message
      pagination {
        currentPage
        pageSize
        totalPages
        totalResults
      }
      results {
        makeName
        name
        id
      }
    }
  }
`;

export default getVehicleModels;
