import BottomDrawer from '@transferz/fe-component-library/bottom-drawer';
import { Button } from '@transferz/fe-component-library/button';
import Checkbox from '@transferz/fe-component-library/checkbox';
import ChevronIcon from '@transferz/fe-component-library/chevron-icon';
import MinusPersonIcon from '@transferz/fe-component-library/minus-person-icon';
import Notification from '@transferz/fe-component-library/notification';
import PhoneRingIcon from '@transferz/fe-component-library/phone-ring-icon';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { PickupIssuesEnum, SingleIssue } from '../../pages/JourneyPage';
import { useCreateAction } from '../../services/apolloNetworkHooks/useCreateJourneyAction';
import { useAddAction } from '../../services/hooks/useAddAction';
import { useDataLayer } from '../../services/hooks/useDataLayer';
import { JourneyAction, JourneyActionTypeEnum } from '../../types/actions';

type SingleIssueDrawerProps = {
  issue: SingleIssue | null;
  isOpen: boolean;
  onClose: () => void;
  goBack: () => void;
  callTransferCompany?: () => void;
  callCustomerService?: () => void;
  openSubmitProofDrawer?: () => void;
  actions: JourneyAction[];
  getActions: () => void;
  journeyId: number;
  journeyCode?: string;
  driverCode?: string;
};

const SingleIssueDrawer: FunctionComponent<SingleIssueDrawerProps> = ({
  issue,
  isOpen,
  onClose,
  goBack,
  callTransferCompany,
  callCustomerService,
  openSubmitProofDrawer,
  actions,
  getActions,
  journeyId,
  journeyCode,
  driverCode,
}) => {
  const [reportedDelayDrawer, setReportedDelayDrawer] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [position, setPosition] = useState({} as any);
  const { translate } = useLocalize();
  const createAction = useCreateAction();
  const { trackEvent } = useDataLayer();
  const { addAction } = useAddAction();

  const reportDriverDelayed = () => {
    console.log('DEBUG User clicked report driver delayed button, opened drawer');
    createAction(journeyId, JourneyActionTypeEnum.DRIVER_DELAYED, position, () => {
      console.log('INFO Delay reported action created successfully');
      getActions();
    });
    onClose();
    setReportedDelayDrawer(true);
    trackEvent({
      event: 'submitted-delay',
      journeyCode,
      driverCode,
    });
    addAction('submitted-delay', {
      journeyCode,
      driverCode,
    });
  };

  const reportedDriverDelayed =
    actions?.length && !!actions.find((action: JourneyAction) => action.type === JourneyActionTypeEnum.DRIVER_DELAYED);

  const reportedDriverLeft =
    actions?.length && !!actions.find((action: JourneyAction) => action.type === JourneyActionTypeEnum.DRIVER_LEFT_PICKUP);

  const onCheckboxChange = () => {
    setIsChecked(!isChecked);
    console.log(`DEBUG User clicked checkbox, changed to ${!isChecked}`);
  };

  useEffect(() => {
    setIsChecked(false);
  }, [isOpen]);

  useEffect(() => {
    if (!reportedDriverLeft && issue?.type === PickupIssuesEnum.cannot_find_traveller) {
      console.log('DEBUG User clicked report driver leave');
      createAction(journeyId, JourneyActionTypeEnum.DRIVER_LEFT_PICKUP, position, () => {
        console.log('INFO Driver left reported action created successfully');
        getActions();
      });
    }
  }, [issue]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setPosition({
            lat: pos?.coords?.latitude,
            lng: pos?.coords?.longitude,
          });
        },
        () => {
          setPosition({
            lat: null,
            lng: null,
          });
        },
      );
    }
  }, []);

  const renderContent = () => {
    if (isOpen) {
      console.log(`INFO Drawer opened with issue type: ${issue?.type}`);
    }
    switch (issue?.type) {
      case 'too_many_travellers':
      case 'too_many_suitcases':
      case 'missing_addons':
        return (
          <Button
            label={translate('call_transfer_company')}
            priority="primary"
            variant="info"
            fullWidth
            icon={<PhoneRingIcon direction="left" width={19} />}
            iconPosition="left"
            onClick={callTransferCompany && callTransferCompany}
          />
        );
      case 'im_too_late':
      case 'blockage_at_pickup_point':
        return (
          <>
            {reportedDriverDelayed ? (
              <Notification variant="neutral" message={translate('sent_message_late')} className="m-b-24" />
            ) : (
              <Button
                priority="primary"
                variant="info"
                data-testid="issue_less_than_15_minutes"
                label={translate('less_than_15_minutes')}
                circle={false}
                infoText={translate('send_a_message_to_traveller')}
                fullWidth
                className="m-b-36"
                onClick={reportDriverDelayed}
              />
            )}
            <Typography variant="body1" className="m-b-36">
              {translate('your_delay_is')}
              {' '}
              <strong>
                {' '}
                {translate('more_than_15_minutes')}
              </strong>
              {translate('please_call_transfer_company')}
            </Typography>
            <Button
              label={translate('call_transfer_company')}
              priority="primary"
              variant="info"
              fullWidth
              icon={<PhoneRingIcon direction="left" width={19} />}
              iconPosition="left"
              onClick={callTransferCompany && callTransferCompany}
              className="m-b-36"
            />
            <Typography variant="body1" className="m-b-36">
              {translate('cannot_reach_transfer_company')}
            </Typography>
            <Button
              label={translate('call_customer_service')}
              priority="secondary"
              variant="info"
              fullWidth
              icon={<PhoneRingIcon direction="left" width={19} />}
              iconPosition="left"
              onClick={callCustomerService && callCustomerService}
            />
          </>
        );
      case 'refuses_to_get_in_car':
        return (
          <Button
            label={translate('call_customer_service')}
            priority="primary"
            variant="info"
            fullWidth
            icon={<PhoneRingIcon direction="left" width={19} />}
            iconPosition="left"
            onClick={callCustomerService && callCustomerService}
          />
        );
      case 'cannot_find_traveller':
        return (
          <>
            <div className="checkbox-container m-b-24">
              <Checkbox
                name="already-called-traveller-checkbox"
                onChange={onCheckboxChange}
                isChecked={isChecked}
                label={translate('called_traveller')}
              />
            </div>
            <Button
              icon={<MinusPersonIcon width={24} />}
              iconPosition="left"
              label={translate('traveller_noShow')}
              variant="info"
              priority="primary"
              fullWidth
              disabled={!isChecked}
              className="m-b-10"
              data-testid="report_no-show"
              onClick={openSubmitProofDrawer && openSubmitProofDrawer}
            />
          </>
        );
      default:
    }
  };
  return (
    <>
      <BottomDrawer
        isOpen={isOpen}
        onClose={onClose}
        goBack={goBack}
        title={issue?.title || ''}
        text={issue?.text}
        className="single-issue-drawer"
      >
        {renderContent()}
      </BottomDrawer>
      <BottomDrawer
        isOpen={reportedDelayDrawer}
        title={translate('delay_less_15_minutes')}
        text={translate('thanks_delay_message')}
        onClose={() => setReportedDelayDrawer(false)}
      >
        <Button
          label={translate('go_back')}
          priority="secondary"
          variant="info"
          icon={<ChevronIcon direction="left" fill="asphalt7" height={14} />}
          iconPosition="left"
          fullWidth
          onClick={() => setReportedDelayDrawer(false)}
          className="m-b-10"
          data-testid="reportedDelay_button"
        />
      </BottomDrawer>
    </>
  );
};

export default SingleIssueDrawer;
