import React, { Dispatch, SetStateAction } from 'react';

export interface ILanguageContext {
  language: string | undefined;
  setLanguage: Dispatch<SetStateAction<string>> | undefined;
}

export const LanguageContext = React.createContext<ILanguageContext>({
  language: undefined,
  setLanguage: undefined,
});
