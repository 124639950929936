import { useMutation } from '@apollo/client';

import { updateVehicleQuery } from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useEditVehicle = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const [editVehicle] = useMutation(updateVehicleQuery, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.updateVehicle);
  };

  return (
    vehicleId: number,
    serieId: number,
    plate: string,
    callback: (data: any) => void,
  ) => offlineProofQuery(() => authenticatedQuery((accessToken) => {
    editVehicle({
      variables: {
        id: vehicleId,
        serieId,
        plate: plate.toUpperCase().replace(/ /g, ''),
        accessToken,
      },
    }).then((res) => {
      handleNewData(res, callback);
    });
  }));
};
