export interface CacheProfile {
  name?: string
  email?: string
  phone?: string
  token?: string
  refreshToken?: string
  accessToken?: string
  id?: number
  driverId?: number
  photo?: string
  lastJourneyView?: string
  lastJourneyId?: number
  created?: string
  videoLang?: string
}

export const CacheProfileEmpty: CacheProfile = {
  name: '',
  email: '',
  phone: '',
  token: '',
  refreshToken: '',
  accessToken: '',
  id: 0,
  driverId: 0,
  photo: '',
  lastJourneyView: '',
  lastJourneyId: 0,
  created: '',
  videoLang: 'en',
};
