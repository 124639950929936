import Typography from '@transferz/fe-component-library/typography';
import React, { FunctionComponent, useEffect } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import StickyBackButton from './StickyBackButton';

interface props {
  name: string;
  handleClick: any;
}

const MeetSign: FunctionComponent<props> = ({ name, handleClick }) => (
  <RemoveScroll>
    <div className="meet-greet-wrapper">
      <div className="button-wrapper">
        <StickyBackButton onClick={handleClick} />
      </div>
      <div className="meetGreetSign">
        <Typography variant="h3" className="m-l-16 m-r-16">
          {name}
        </Typography>
      </div>
    </div>
  </RemoveScroll>
);

export default MeetSign;
