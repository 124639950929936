import Typography from '@transferz/fe-component-library/typography';
import XIcon from '@transferz/fe-component-library/x-icon';
import { useLocalize } from 'localize-react';
import React from 'react';

type Props = {
  file: string;
  deleteFile?: ()=>void;
}

const FilePreview = ({ file = 'test file', deleteFile }: Props) => (
  <div className="w-12 m-b-16 file-preview">
    <Typography variant="body1" color="dark">{file}</Typography>
    <span className="d-flex align-self-center pointer" onClick={deleteFile && deleteFile}><XIcon width={15} color="blue5" /></span>
  </div>
);

export default FilePreview;
