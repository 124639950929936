import { gql } from '@apollo/client/core';

const RetrieveSessionQuery = gql`mutation retrieveSession($token: String!, $refreshToken: String!) {
  retrieveSession(token: $token, refreshToken: $refreshToken) {
    success
    driverId
    userId
    name
    photo
    phone
    email
    token
    refreshToken
    accessToken
    message
    expiresInSeconds
  }
}`;

export default RetrieveSessionQuery;
