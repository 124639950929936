import { gql } from '@apollo/client/core';

const getPhoneByCountryQuery = gql`
    query getPhoneByCountry($countryCode: String!, $contactType: String!, $language: String) {
        getPhoneByCountry(countryCode: $countryCode, contactType: $contactType, language: $language) {
          success
          message
          phone
      }
    }
`;

export default getPhoneByCountryQuery;
