import { useLazyQuery } from '@apollo/client';

import createActionQuery from '../../models/graphql/CreateAction';
import { JourneyActionTypeEnum } from '../../types/actions';
import { Position } from '../../types/journey';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';

export const useCreateAction = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const [createAction, { refetch: createActionRefetch, called }] = useLazyQuery(createActionQuery, {
    fetchPolicy: 'no-cache',
  });

  return (
    journeyId: number,
    type: JourneyActionTypeEnum,
    position: Position,
    callback: (data: any) => void,
  ) => authenticatedQuery((accessToken) => {
    if (called) {
      createActionRefetch({
        position,
        type,
        variables: {
          journeyId,
          type,
          position,
          accessToken,
        },
      }).then(async (res) => {
        if (res?.data?.createAction?.success) {
          console.log(res.data.createAction);
        }

        if (callback) callback(res?.data?.createAction);
      });
    }
    else {
      createAction({
        variables: {
          journeyId,
          type,
          position,
          accessToken,
        },
      }).then(async (res) => {
        if (res?.data?.createAction?.success) {
          console.log(res.data.createAction);
        }

        if (callback) callback(res?.data?.createAction);
      });
    }
  });
};
