import BriefcaseIcon from '@transferz/fe-component-library/briefcase-icon';
import { Button } from '@transferz/fe-component-library/button';
import CircleXIcon from '@transferz/fe-component-library/circle-x-icon';
import ClockIcon from '@transferz/fe-component-library/clock-icon';
import DangerIcon from '@transferz/fe-component-library/danger-icon';
import LocationIcon from '@transferz/fe-component-library/location-icon';
import PhoneRingIcon from '@transferz/fe-component-library/phone-ring-icon';
import PlusPersonIcon from '@transferz/fe-component-library/plus-person-icon';
import SadFaceIcon from '@transferz/fe-component-library/sad-face-icon';
import Typography from '@transferz/fe-component-library/typography';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useLocalize } from 'localize-react';
import React, { FunctionComponent } from 'react';

import { PickupIssuesEnum } from '../../pages/JourneyPage';
import { JourneyAction, JourneyActionTypeEnum } from '../../types/actions';
import { JourneyStatus } from '../../types/journeyStatus';

type PickupIssuesProps = {
  status: JourneyStatus;
  openSingleIssue: (issue: keyof typeof PickupIssuesEnum) => void;
  callCustomerService: () => void;
  openSuccessPage: () => void;
  flags?: any;
  actions: JourneyAction[];
};

const PickupIssues: FunctionComponent<PickupIssuesProps> = ({
  status,
  openSingleIssue,
  callCustomerService,
  openSuccessPage,
  flags,
  actions,
}) => {
  const isDisabled = status === JourneyStatus.PLANNED || status === JourneyStatus.AVAILABLE;
  const { translate } = useLocalize();

  const travelerNoShowSubmited = actions?.find((item: any) => item.type === JourneyActionTypeEnum.TRAVELLER_NO_SHOW);
  const driverLeftSubmitted = actions?.find((item: any) => item.type === JourneyActionTypeEnum.DRIVER_LEFT_PICKUP);
  const additionalNoShowProofSubmitted = actions?.find((item: any) => item.type === JourneyActionTypeEnum.ADDITIONAL_PROOF_TRAVELLER_NO_SHOW);

  const reportTravelerNoShow = () => {
    openSingleIssue('cannot_find_traveller');
  };

  const getDriverLeftLabel = () => {
    if (!driverLeftSubmitted && !travelerNoShowSubmited && !additionalNoShowProofSubmitted) {
      return translate('b_cannotFindTravellerLeaving');
    }

    if (driverLeftSubmitted && !travelerNoShowSubmited) {
      return translate('b_report_traveller_no_show');
    }

    if (travelerNoShowSubmited) {
      return translate('b_add_additional_proof');
    }

    return translate('b_cannotFindTravellerLeaving');
  };

  return (
    <div className="pickup-issues">
      <Typography variant="subtitle1" className="m-b-18 d-flex">
        {translate('capacity_addons_issues')}
      </Typography>
      <Button
        fullWidth
        label={translate('too_many_travellers')}
        icon={<PlusPersonIcon color={isDisabled ? 'asphalt4' : 'red5'} />}
        variant="cancel"
        data-testid="too_many_travellers"
        disabled={isDisabled}
        textColor={isDisabled ? 'asphalt4' : 'red5'}
        className="m-b-16"
        onClick={() => {
          console.log('DEBUG User clicked on too many travellers issue, opening drawer...');
          openSingleIssue('too_many_travellers');
        }}
      />
      <Button
        fullWidth
        label={translate('to_many_suitcases')}
        icon={<BriefcaseIcon color={isDisabled ? 'asphalt4' : 'red5'} />}
        variant="cancel"
        disabled={isDisabled}
        data-testid="to_many_suitcases"
        textColor={isDisabled ? 'asphalt4' : 'red5'}
        className="m-b-16"
        onClick={() => {
          console.log('DEBUG User clicked on too many suitcases, opening drawer...');
          openSingleIssue('too_many_suitcases');
        }}
      />
      <Button
        fullWidth
        label={translate('missing_addons')}
        icon={<CircleXIcon color={isDisabled ? 'asphalt4' : 'red5'} />}
        data-testid="missing_addons"
        variant="cancel"
        disabled={isDisabled}
        textColor={isDisabled ? 'asphalt4' : 'red5'}
        className="m-b-32"
        onClick={() => {
          console.log('DEBUG User clicked on missing addons, opening drawer...');
          openSingleIssue('missing_addons');
        }}
      />
      {flags.driverIssues && (
        <>
          <Typography variant="subtitle1" className="m-b-18 d-flex">
            {translate('driver_issues')}
          </Typography>
          <Button
            fullWidth
            label={translate('im_too_late')}
            icon={<ClockIcon color="red5" direction="right" />}
            variant="cancel"
            textColor="red5"
            className="m-b-16"
            data-testid="im_too_late"
            onClick={() => {
              console.log('DEBUG User clicked on im too late, opening drawer...');
              openSingleIssue('im_too_late');
            }}
          />
          <Button
            fullWidth
            label={translate('blockage_at_pickup_point')}
            icon={<DangerIcon color="red5" />}
            variant="cancel"
            textColor="red5"
            data-testid="blockage_at_pickup_point"
            className="m-b-32"
            onClick={() => {
              console.log('DEBUG User clicked on blockage at pickup point, opening drawer...');
              openSingleIssue('blockage_at_pickup_point');
            }}
          />
        </>
      )}
      <Typography variant="subtitle1" className="m-b-18 d-flex">
        {translate('traveller_issues')}
      </Typography>
      {flags.driverLeft ? (
        <Button
          fullWidth
          label={getDriverLeftLabel()}
          icon={<LocationIcon color={isDisabled ? 'asphalt4' : 'red5'} />}
          data-testid="cannot_find_traveller"
          variant="cancel"
          textColor={isDisabled ? 'asphalt4' : 'red5'}
          disabled={isDisabled}
          className="m-b-16"
          onClick={() => (travelerNoShowSubmited ? openSuccessPage() : reportTravelerNoShow())}
        />
      ) :
        (
          <Button
            fullWidth
            label={translate('cannot_find_traveller')}
            icon={<LocationIcon color={isDisabled ? 'asphalt4' : 'red5'} />}
            variant="cancel"
            textColor={isDisabled ? 'asphalt4' : 'red5'}
            disabled={isDisabled}
            className="m-b-16"
            data-testid="cannot_find_traveller"
            onClick={() => (travelerNoShowSubmited ? openSuccessPage() : openSingleIssue('cannot_find_traveller'))}
          />
        )}
      <Button
        fullWidth
        label={translate('refuses_to_get_in_car')}
        icon={<SadFaceIcon color={isDisabled ? 'asphalt4' : 'red5'} />}
        variant="cancel"
        textColor={isDisabled ? 'asphalt4' : 'red5'}
        className="m-b-32"
        data-testid="refuses_to_get_in_car"
        disabled={isDisabled}
        onClick={() => {
          console.log('DEBUG User clicked on refuses to get in car, opening drawer...');
          openSingleIssue('refuses_to_get_in_car');
        }}
      />
      <Typography variant="subtitle1" className="m-b-18 d-flex">
        {translate('other_issues')}
      </Typography>
      <Button
        label={translate('call_customer_service')}
        priority="primary"
        data-testid="call_customer_service"
        variant="info"
        fullWidth
        icon={<PhoneRingIcon direction="left" height={15} />}
        iconPosition="left"
        onClick={() => {
          console.log('DEBUG User clicked on call customer service, opening drawer...');
          callCustomerService();
        }}
        className="m-b-10"
      />
    </div>
  );
};

export default withLDConsumer()(PickupIssues);
