import { Button } from '@transferz/fe-component-library/button';
import DangerIcon from '@transferz/fe-component-library/danger-icon';
import PhoneRingIcon from '@transferz/fe-component-library/phone-ring-icon';
import SmsIcon from '@transferz/fe-component-library/sms-icon';
import WhatsAppIcon from '@transferz/fe-component-library/whats-app-icon';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent, useRef } from 'react';

interface JourneyInfoBoxButtonsProp {
  setJourneyIssuesDrawer: () => void;
  travellerPhone: string;
  status: string;
  notYetStarted?: boolean;
  availableForStarting?: boolean;
  isCancelled?: boolean;
}

const JourneyInfoBoxButtons: FunctionComponent<JourneyInfoBoxButtonsProp> = ({
  setJourneyIssuesDrawer,
  travellerPhone,
  status,
  notYetStarted,
  availableForStarting,
  isCancelled,
}) => {
  const callLinkRef = useRef<HTMLAnchorElement | null>(null);
  const smsLinkRef = useRef<HTMLAnchorElement | null>(null);
  const whatsAppLinkRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <div className="journey-info-circle-buttons display-flex justify-content-center p-b-20">
      <Button
        label=""
        disabled={notYetStarted || isCancelled}
        onClick={() => {
          console.log('DEBUG User clicked on phone icon, calling phone number...');
          callLinkRef.current?.click();
        }}
        priority="primary"
        variant="info"
        circle
        icon={<PhoneRingIcon width={20} height={20} color="light" />}
      />
      <Button
        label=""
        onClick={() => {
          console.log('DEBUG User clicked on whats app icon, opening app...');
          whatsAppLinkRef?.current?.click();
        }}
        disabled={notYetStarted || isCancelled}
        priority="primary"
        className="margin-left-8"
        variant="confirm"
        circle
        icon={<WhatsAppIcon width={20} height={20} color="light" />}
      />
      {/* WhatsApp */}
      <Button
        label=""
        onClick={() => {
          console.log('DEBUG User clicked on sms icon, opening app...');
          smsLinkRef?.current?.click();
        }}
        disabled={notYetStarted || isCancelled}
        className="margin-left-8 transparent"
        circle
        icon={<SmsIcon width={20} height={20} />}
      />
      <Button
        label=""
        onClick={setJourneyIssuesDrawer!}
        priority="primary"
        className="margin-left-8"
        variant="cancel"
        data-testid="complaint-button"
        circle
        disabled={!availableForStarting || isCancelled}
        icon={<DangerIcon width={20} height={20} color="light" />}
      />
      <a style={{ display: 'none' }} ref={callLinkRef} href={`tel:${travellerPhone}`}>
        .
      </a>
      <a style={{ display: 'none' }} ref={smsLinkRef} href={`sms:${travellerPhone}`}>
        .
      </a>
      <a style={{ display: 'none' }} ref={whatsAppLinkRef} href={`https://wa.me/${travellerPhone?.substring(1)}`}>
        .
      </a>
    </div>
  );
};

export default withLDConsumer()(JourneyInfoBoxButtons);
