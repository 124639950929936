import { useQuery } from '@apollo/client';
import Dropdown from '@transferz/fe-component-library/dropdown';
import Typography from '@transferz/fe-component-library/typography';
import VideoCard from '@transferz/fe-component-library/video-card';
import { useLocalize } from 'localize-react';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { GetCacheProfile } from '../../models/graphql';
import { useSetCacheProfile } from '../../services/apolloCacheHooks';
import InstructionalVideo from '../InstructionalVideo';

export type InstructionalVideoType = {
  title: string;
  videoId: string;
  duration: number;
  cover: string;
}

export type VideoListType = {
  [key: string]: InstructionalVideoType[];
}

const InstructionalVideos = () => {
  const { translate } = useLocalize();
  const setCacheProfile = useSetCacheProfile();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const [videoId, setVideoId] = useState<string>('');

  const languages = [
    { value: 'en', label: `${translate('english')} (EN)`, icon: 'gb' },
    { value: 'it', label: `${translate('italian')} (IT)`, icon: 'it' },
    { value: 'es', label: `${translate('spanish')} (ES)`, icon: 'es' },
    { value: 'pt', label: `${translate('portuguese')} (PT)`, icon: 'pt' },
    { value: 'de', label: `${translate('german')} (DE)`, icon: 'de' },
  ];

  const { control, watch } = useForm({
    defaultValues: { language: cachedProfileData.videoLang || 'en' },
  });

  const videos: VideoListType = {
    en: [
      {
        title: 'How to execute a journey using the transferz Driver app',
        videoId: 'Dn_K9-wqVpo',
        duration: 260,
        cover: 'taxi',
      },
      {
        title: 'How to add driver codes using the transferz Driver app',
        videoId: '6pKXSAv4J8g',
        duration: 83,
        cover: 'phone',
      },
      {
        title: 'How to edit your profile in the transferz Driver app',
        videoId: 'qHRRLscAJHo',
        duration: 106,
        cover: '',
      },
      {
        title: 'How to report pickup issues',
        videoId: 'TOlNTUpvrd8',
        duration: 186,
        cover: 'issues',
      },
    ],
    it: [
      {
        title: "Come eseguire un trasferimento utilizzando l'applicazione di Transferz per conducenti",
        videoId: 'f3CvSpI4-2Y',
        duration: 347,
        cover: 'taxi',
      },
      {
        title: "Come aggiungere i codici per conducenti utilizzando l'applicazione di Transferz per conducenti",
        videoId: 'xpidbqj1O8s',
        duration: 91,
        cover: 'phone',
      },
      {
        title: "Come modificare il tuo profilo nell'applicazione di Transferz per conducenti",
        videoId: 'CoJp_yvIS5g',
        duration: 96,
        cover: '',
      },
      {
        title: 'Come segnalare un problema alla presa in carico del cliente',
        videoId: 'rNqylQkKAdQ',
        duration: 159,
        cover: 'issues',
      },
    ],
    es: [
      {
        title: 'Cómo realizar un viaje usando la aplicación Transferz Driver',
        videoId: 'PF4WyxlZmgM',
        duration: 342,
        cover: 'taxi',
      },
      {
        title: 'Cómo agregar códigos de conductor usando la aplicación Transferz Driver',
        videoId: 'F8fztwfVuRM',
        duration: 84,
        cover: 'phone',
      },
      {
        title: 'Cómo editar tu perfil en la aplicación Transferz Driver',
        videoId: 'BW_ItBlDsyU',
        duration: 98,
        cover: '',
      },
      {
        title: 'Cómo reportar problemas de recogida',
        videoId: 's6b5_6qYEZs',
        duration: 169,
        cover: 'issues',
      },
    ],
    pt: [
      {
        title: "Como executar uma viagem usando o 'Driver App' da Transferz",
        videoId: 'VYUWkuuysyQ',
        duration: 331,
        cover: 'taxi',
      },
      {
        title: "Como adicionar códigos de motorista usando o 'Driver App' da Transferz",
        videoId: 'ls1BCJTURdk',
        duration: 162,
        cover: 'phone',
      },
      {
        title: "Como editar seu perfil no 'Driver App' da Transferz",
        videoId: '4ESlyjtUNts',
        duration: 104,
        cover: '',
      },
      {
        title: 'Como relatar problemas de recolha',
        videoId: '5hWQ66TbIdM',
        duration: 219,
        cover: 'issues',
      },
    ],
    de: [
      {
        title: 'So führen Sie eine Fahrt mit der transferz Driver App durch',
        videoId: 'SxyF8dddM1s',
        duration: 345,
        cover: 'taxi',
      },
      {
        title: 'Hinzufügen von Fahrercodes mit der transferz Driver App',
        videoId: 'RPi4M4zooPc',
        duration: 119,
        cover: 'phone',
      },
      {
        title: 'So bearbeiten Sie Ihr Profil in der transferz Driver App',
        videoId: '-stfkS1WYzg',
        duration: 176,
        cover: '',
      },
      {
        title: 'Wie melde ich Probleme bei der Abholung',
        videoId: 'mG8pganrG-E',
        duration: 222,
        cover: 'issues',
      },
    ],
  };

  const openVideo = (id: string) => {
    setVideoId(id);
  };

  const currentLanguage = watch('language');

  return (
    <div>
      <Typography
        variant="h5"
        color="dark"
        className="m-t-32 m-b-16"
      >
        {translate('instructional_videos')}
      </Typography>
      <Controller
        name="language"
        control={control}
        render={({ field: { value, onChange, onBlur } }) => (
          <Dropdown
            label={translate('language')}
            data={languages}
            value={value}
            onChange={(lang: any) => {
              // @ts-ignore
              setCacheProfile({ videoLang: lang.value }).then(() => {
                console.log(cachedProfileData, lang);
                onChange(lang.value);
              });
            }}
            onBlur={onBlur}
            className="m-b-24"
            noValidation
            isSearchable
          />
        )}
      />
      {videos[currentLanguage].map((video: InstructionalVideoType, idx: number) => (
        <VideoCard
          title={video.title}
          // @ts-ignore
          duration={video.duration}
          onCardClick={() => openVideo(video.videoId)}
          cover={video.cover as any}
          className="m-b-24"
          key={idx}
        />
      ))}
      <InstructionalVideo isOpen={!!videoId} videoId={videoId} onClose={() => setVideoId('')} />
    </div>
  );
};

export default InstructionalVideos;
