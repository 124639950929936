export enum JourneyStatus {
  AVAILABLE = 'AVAILABLE',
  PLANNED = 'PLANNED',
  UNDERWAY_TO_PICKUP = 'UNDERWAY_TO_PICKUP',
  ARRIVED_AT_PICKUP = 'ARRIVED_AT_PICKUP',
  JOURNEY_IN_PROGRESS = 'JOURNEY_IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED_FREE = 'CANCELLED_FREE',
  CANCELLED_WITH_COSTS = 'CANCELLED_WITH_COSTS',
  CANCELLED = 'CANCELLED',
  NO_LONGER_AVAILABLE = 'NO_LONGER_AVAILABLE',
  REASSIGNED_TRANSFER_COMPANY = 'REASSIGNED_TRANSFER_COMPANY',
  REASSIGNED_DRIVER = 'REASSIGNED_DRIVER'
}

export enum JourneyStatusStrings {
  AVAILABLE = 'upcoming',
  PLANNED = 'upcoming',
  UNDERWAY_TO_PICKUP = 'on_the_way_to_pickup',
  ARRIVED_AT_PICKUP = 'arrived_at_pickup',
  JOURNEY_IN_PROGRESS = 'on_the_way_to_drop_off',
  COMPLETED = 'completed',
  CANCELLED_FREE = 'cancelled',
  CANCELLED_WITH_COSTS = 'cancelled',
  CANCELLED = 'n_unavailableCancelled',
  NO_LONGER_AVAILABLE = 'n_unavailableNoLongerAvailable',
  REASSIGNED_TRANSFER_COMPANY = 'n_unavailableOtherTransco',
  REASSIGNED_DRIVER = 'n_unavailableOtherDriver',
}
