import { gql } from '@apollo/client/core';

const loginWithEmailAndPasswordQuery = gql`
    query authEmail($email: String!, $password: String!, $sessionToken: String!) {
        authEmail(email: $email, password: $password, sessionToken: $sessionToken) {
          success
          message
          userId
          driverId
          name
          email
          created
          token
          refreshToken
          accessToken
          updated
          photo
        }
    }
`;

export default loginWithEmailAndPasswordQuery;
