// @ts-ignore
import { isJwtExpired } from 'jwt-check-expiration';

export const validateJwt = (token?: string | undefined | null) => {
  try {
    // @ts-ignore
    return isJwtExpired(token);
  }
  catch (e) {
    console.log('Invalid JWT provided. Error - ', e);

    return true;
  }
};
