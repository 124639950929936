import { gql } from '@apollo/client/core';

const getFlightInfo = gql`
  query getFlightInfo($params: FlightInfoParams!, $accessToken: String!) {
    getFlightInfo(params: $params, accessToken: $accessToken) {
      status
      scheduledTime 
      estimatedTime
      airline
      delay 
      city
    }
  }
`;

export default getFlightInfo;
