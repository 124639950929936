import { useQuery } from '@apollo/client';
import { Button } from '@transferz/fe-component-library/button';
import Loader from '@transferz/fe-component-library/loader';
import Notification from '@transferz/fe-component-library/notification';
import PhoneRingIcon from '@transferz/fe-component-library/phone-ring-icon';
import { PinInput } from '@transferz/fe-component-library/pin-input';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, {
  ReactElement, useEffect, useRef,
  useState,
} from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';

import { GetCacheProfile } from '../models/graphql';
import { useQueryCaptchaToken } from '../queries/captcha/hooks/captcha/useGetCaptchaToken';
import { useSetCacheProfile } from '../services/apolloCacheHooks';
import {
  useAssociateJourney,
  useGetPhoneByCountry,
  useGetPhoneByLocation,
  useLoginPhone,
  useSendOTP,
} from '../services/apolloNetworkHooks';
import { routes } from '../services/constant';
import { storeTokenExpirationTime } from '../services/store';

const LOGIN_ATTEMPTS_INTERVAL = 60;

interface Props {
  onLoginSuccess: (data: any) => void;
}

const PinScreen: (props: Props) => ReactElement = function ({ onLoginSuccess }) {
  const classBase = 'pin-page';
  const [invalidPin, setPinInvalid] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [captchaRequired, setCaptchaRequired] = useState<boolean>(false);
  const [loginAttempted, setLoginAttempted] = useState<boolean>(true);
  const [captchaError, setCaptchaError] = useState<boolean>(false);
  const [rateLimitError, setRateLimitError] = useState<boolean>(false);
  const [timerIsOn, setTimerIsOn] = React.useState<boolean>(false);
  const [seconds, setSeconds] = React.useState<number>(LOGIN_ATTEMPTS_INTERVAL);
  const [captchaKey, setCaptchaKey] = React.useState<string>('');
  const setCacheProfile = useSetCacheProfile();
  const associateJourney = useAssociateJourney();
  const navigate = useNavigate();
  const { translate } = useLocalize();
  const { data: cachedProfileData, loading: loadingProfileData } = useQuery(GetCacheProfile);
  const sendOTP = useSendOTP();
  const loginWithPhone = useLoginPhone();
  const getPhoneByCountry = useGetPhoneByCountry();
  const getPhoneByLocation = useGetPhoneByLocation();

  const [pinCode, setPinCode] = useState('');
  const [notificationError, setNotificationError] = useState<boolean>(false);
  const [lockedAccount, setLockedAccount] = useState<boolean>(false);
  const [customerServicePhone, setCustomerServicePhone] = useState<string>('');
  const callLinkRef = useRef<any>();
  const intervalRef = useRef<any>();
  const getCaptchaToken = useQueryCaptchaToken();

  const handleInput = (pin: string) => {
    setPinCode(pin);
  };

  const countDown = () => {
    setSeconds((sec: number) => sec - 1);
    if (seconds === 0) {
      setTimerIsOn(false);
      setSeconds(LOGIN_ATTEMPTS_INTERVAL);
    }
  };

  const startTimer = (): void => {
    setTimerIsOn(true);
  };

  useEffect(() => {
    // start timer on page load
    startTimer();
  }, []);

  useEffect(() => {
    setPinInvalid(false);
    setLoading(false);

    if (pinCode.length === 6) {
      setLoading(true);
      console.log('DEBUG User entered pin code,Authorizing...');

      loginWithPhone(cachedProfileData.phone, pinCode, (data: any) => {
        if (data?.success) {
          console.log('INFO Login success');
          // set token expiration time
          onLoginSuccess(data.expiresInSeconds);
          storeTokenExpirationTime(data.expiresInSeconds.toString());

          // If driver is newly created redirect him to update his name
          if (!data.name) {
            navigate(`${routes.login.welcome}`);

            setLoading(false);

            return;
          }

          if (cachedProfileData?.lastJourneyView) {
            const code = cachedProfileData?.lastJourneyView;

            associateJourney(
              code,
              () => {
                setCacheProfile({
                  lastJourneyView: '',
                }).then(() => {
                  setTimeout(() => {
                    setLoading(false);

                    navigate(`${routes.journey}/${code}`, { state: { notification: 'addJourneySuccess' } });
                  }, 250);
                });
              },
              data,
              true,
            );
          }
          else {
            setTimeout(() => {
              setLoading(false);

              navigate(routes.journeys);
            }, 250);
          }
        }
        else if (!data.success && data.message === 'FORBIDDEN') {
          setLockedAccount(true);
        }
        else {
          setPinInvalid(true);
          setNotificationError(true);

          setTimeout(() => {
            setNotificationError(false);
          }, 2000);
        }

        setLoading(false);
      });
    }
  }, [pinCode]);

  useEffect(() => {
    if (!loadingProfileData && !cachedProfileData?.phone) {
      navigate(routes.login.phone);
    }
  }, [loadingProfileData]);

  useEffect(() => {
    if (timerIsOn) {
      intervalRef.current = setInterval(() => {
        countDown();
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [timerIsOn, seconds]);

  const getPhone = (position: any) => {
    if (position.coords) {
      getPhoneByLocation(
        'DRIVER',
        String(position.coords.latitude),
        String(position.coords.longitude),
        '',
        (data: any) => {
          if (data?.success) {
            setCustomerServicePhone(data?.phone);
          }
        },
      );
    }
    else {
      getPhoneByCountry('US', 'DRIVER', '', (data: any) => {
        if (data?.success) {
          setCustomerServicePhone(data?.phone);
        }
      });
    }
  };

  useEffect(() => {
    if (lockedAccount) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          // success callback
          getPhone,
          // error callback (position will be undefined)
          getPhone,
        );
      }
    }
  }, [lockedAccount]);

  const handleLoginSuccess = (): void => {
    startTimer();
    setCaptchaRequired(false);
    setLoginAttempted(true);
    setCaptchaError(false);
    setRateLimitError(false);
  };

  const handleCaptchaIsRequiredError = (): void => {
    if (!captchaKey) {
      getCaptchaToken((token: any) => {
        setCaptchaKey(token?.token);
      });
    }
    setCaptchaRequired(true);
    setLoginAttempted(false);
    setCaptchaError(false);
    setRateLimitError(false);
  };

  const handleCaptchaIsIncorrectError = (): void => {
    setCaptchaError(true);
    setRateLimitError(false);
    startTimer();
  };

  const handleRateLimitError = (): void => {
    startTimer();
    setCaptchaError(false);
    setRateLimitError(true);
  };

  const sendAccessCode = (captchaToken?: string) => {
    if (timerIsOn) {
      return;
    }
    setLoginAttempted(true);
    sendOTP({ phoneNumber: cachedProfileData?.phone, captchaToken }, (data: any) => {
      if (data?.success) {
        console.log('INFO Display to the user that SMS is sent again');
        handleLoginSuccess();
      }
      else {
        switch (data.errorCode) {
          case 'CAPTCHA_CHALLENGE_REQUIRED':
            setCaptchaRequired(true);
            handleCaptchaIsRequiredError();
            break;
          case 'CAPTCHA_CHALLENGE_INCORRECT':
            handleCaptchaIsIncorrectError();
            break;
          case 'RATE_LIMIT_EXCEEDED':
            handleRateLimitError();
            break;
          default:
            startTimer();
        }
      }
    });
  };

  const closeNotification = () => {
    setLockedAccount(false);
  };

  if (loading) {
    return <Loader text={translate('please_wait')} fullScreen />;
  }

  return (
    <div className={`${classBase} d-flex flex-column flex-grow-1`} data-testid={classBase}>
      <div className="w-10">
        <Typography variant="h4">{translate('access_code')}</Typography>
      </div>
      <form className="flex-grow-1 d-flex flex-column" data-testid="form">
        <div className={`m-t-48 ${!invalidPin && 'm-b-48'}`}>
          {/* @ts-ignore */}
          <PinInput length={6} handleInput={handleInput} />
        </div>
        {notificationError && (
          <div className="m-b-32">
            <Notification variant="error" message={translate('incorrect_code')} />
          </div>
        )}
        <div className="m-t-48">
          {captchaRequired && captchaKey?.length && (
            <GoogleReCaptchaProvider
              reCaptchaKey={captchaKey}
            >
              <GoogleReCaptcha
                onVerify={(token: string) => {
                  if (!loginAttempted) {
                    sendAccessCode(token);
                  }
                }}
              />
            </GoogleReCaptchaProvider>
          )}
        </div>
        {lockedAccount && (
          <div className="m-b-32">
            <Notification
              variant="error"
              message={translate('account_blocked')}
              additionalText={translate('need_help')}
              closeIcon
              onClose={closeNotification}
              button={(
                <Button
                  label={translate('call_service')}
                  priority="primary"
                  variant="cancel"
                  icon={<PhoneRingIcon fill="light" height={15} />}
                  iconPosition="left"
                  fullWidth
                  onClick={() => {
                    callLinkRef?.current?.click();
                  }}
                />
              )}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <a style={{ display: 'none' }} ref={callLinkRef} href={`tel:${customerServicePhone}`}>
              .
            </a>
          </div>
        )}
        <div className="d-flex">
          <Typography variant="body1">{translate('question_about_receive')}</Typography>
        </div>

        <div className="m-t-20 d-flex flex-column justify-content-flex-end">
          <Button
            label={timerIsOn ? translate('send_code_timer', { seconds }) : translate('send_code')}
            disabled={timerIsOn}
            onClick={sendAccessCode}
          />
        </div>
        {captchaError && (
          <div className="m-v-10">
            <Notification variant="error" message={translate('incorrect_captcha_error')} />
          </div>
        )}
        {rateLimitError && (
          <div className="m-v-10">
            <Notification variant="error" message={translate('rate_limit_error')} />
          </div>
        )}
      </form>
    </div>
  );
};

export default PinScreen;
