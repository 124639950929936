import { useLazyQuery } from '@apollo/client';

import { getPhoneByCountryQuery } from '../../models/graphql';

export const useGetPhoneByCountry = () => {
  const [
    getPhoneByCountry,
    {
      refetch: getPhoneByCountryRefetch,
      called,
    },
  ] = useLazyQuery(
    getPhoneByCountryQuery,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const handleNewData = async (
    res: any,
    callback?: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.getPhoneByCountry);
  };

  return (
    countryCode: string,
    contactType: string,
    language?: string,
    callback?: (data: any) => void,
  ) => {
    if (called) {
      return getPhoneByCountryRefetch({
        countryCode,
        contactType,
        language,
      }).then((res:any) => {
        handleNewData(res, callback);
      });
    }
    return getPhoneByCountry({
      variables: {
        countryCode,
        contactType,
        language,
      },
    }).then((res:any) => {
      handleNewData(res, callback);
    });
  };
};
