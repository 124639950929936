import { ApolloProvider } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ScrollToTop from './components/ScrollToTop';
import { getAllLanguageCodes } from './language/translations';
import WithLanguage from './language/WithLanguage';
import { initializeClient } from './services/apollo';
import { baseURL } from './services/constant';
import { OnlineStatusProvider } from './utils/useOnlineStatus';

(async () => {
  function LaunchDarklyApp() {
    const [element, setElement] = useState(<div />);

    const initializeLD = async () => {
      try {
        const response = await fetch(`${baseURL}/configs`);
        const configs: any = await response.json();

        const LDProvider = await asyncWithLDProvider({
          clientSideID: configs?.launchDarklyId,
          user: {
            key: 'DRIVER_APP_USER',
          },
          options: {
            streaming: false,
          },
        });

        // @ts-ignore
        window.tzGlobalConfigs = configs;

        datadogRum.init({
          applicationId: configs.datadogApplicationId,
          clientToken: configs.datadogClientToken,
          site: 'datadoghq.eu',
          service: 'driver-web',
          allowedTracingUrls: [/https:\/\/(staging\.|test\.|)transferz\.taxi/],
          // excludedActivityUrls: [/^https:\/\/events\.launchdarkly\.com/],
          // allowedTracingUrls: [/^https?:\/\/.*$/],
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackResources: true,
          trackLongTasks: true,
          trackInteractions: true,
          trackFrustrations: true,
          trackUserInteractions: true,
          defaultPrivacyLevel: 'mask-user-input',
          env: configs.env,
        } as any);

        datadogLogs.init({
          clientToken: configs.datadogClientToken,
          site: 'datadoghq.eu',
          forwardErrorsToLogs: true,
          sessionSampleRate: 100,
          service: 'driver-web',
          forwardConsoleLogs: 'all',
          env: configs.env,
        });

        setElement(
          <LDProvider>
            <App />
          </LDProvider>,
        );
      }
      catch (e: any) {
        console.log('Fetching API configs failed. Error - ', e);

        setElement(<App />);
      }
    };

    useEffect(() => {
      initializeLD();
    }, []);

    return element;
  }

  await getAllLanguageCodes();

  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={initializeClient()}>
        <OnlineStatusProvider>
          <BrowserRouter>
            <WithLanguage>
              <ScrollToTop />
              <LaunchDarklyApp />
            </WithLanguage>
          </BrowserRouter>
        </OnlineStatusProvider>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();
