import { useLazyQuery, useQuery } from '@apollo/client';

import {
  GetCacheProfile,
  loginWithEmailAndPasswordQuery,
} from '../../models/graphql';
import { useSetCacheProfile } from '../apolloCacheHooks';

export const useLoginEmail = () => {
  const setCacheProfile = useSetCacheProfile();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const [
    login,
    {
      refetch: loginRefetch,
      called,
    },
  ] = useLazyQuery(
    loginWithEmailAndPasswordQuery,
  );

  const sessionToken = cachedProfileData?.token;

  const handleNewData = async (
    res: any,
    callback: (data: any) => void,
  ) => {
    const driverData = { ...res?.data?.authEmail };

    if (driverData?.success) {
      driverData.id = driverData.userId;

      delete driverData.userId;
      delete driverData.created;
      delete driverData.updated;

      await setCacheProfile(driverData);
    }

    if (callback) callback(driverData);
  };

  return (
    email: string,
    password: string,
    callback: (data: any) => void,
  ) => {
    if (called) {
      loginRefetch({
        email,
        password,
        sessionToken,
      }).then((res) => {
        handleNewData(res, callback);
      });
    }
    else {
      login({
        variables: {
          email,
          password,
          sessionToken,
        },
      }).then((res) => {
        handleNewData(res, callback);
      });
    }
  };
};
