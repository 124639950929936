import { useMutation, useQuery } from '@apollo/client';

import { deleteJourneyQuery, GetCacheProfile } from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useDeleteJourney = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const [deleteJourney] = useMutation(deleteJourneyQuery, {
    fetchPolicy: 'no-cache',
  });
  const { data: cachedProfileData } = useQuery(GetCacheProfile);

  const sessionToken = cachedProfileData?.token;

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.deleteJourney);
  };

  return (
    journeyDriverCode: string,
    callback: (data: any) => void,
  ) => offlineProofQuery(() => authenticatedQuery(() => {
    deleteJourney({
      variables: {
        sessionToken,
        journeyDriverCode,
      },
    }).then((res) => {
      handleNewData(res, callback);
    });
  }));
};
