import { useMutation } from '@apollo/client';

import { updateDriverPositionMutation } from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useUpdateDriverPosition = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const [updateDriverPosition] = useMutation(updateDriverPositionMutation, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res);
  };

  return (
    id: number,
    lat: number,
    lng: number,
    callback: (data: any) => void,
  ) => offlineProofQuery(() => authenticatedQuery((accessToken) => {
    updateDriverPosition({
      variables: {
        id,
        lat,
        lng,
        accessToken,
      },
    }).then((res) => {
      handleNewData(res, callback);
    })
      .catch((e: any) => {
        console.log(e);
      });
  }));
};
