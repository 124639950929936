import { useQuery } from '@apollo/client';
import ListIcon from '@transferz/fe-component-library/list-icon';
import LogoIcon from '@transferz/fe-component-library/logo-icon';
import MenuIcon from '@transferz/fe-component-library/menu-icon';
import PopUp from '@transferz/fe-component-library/pop-up';
import ProfileIcon from '@transferz/fe-component-library/profile-icon';
import QuestionMarkIcon from '@transferz/fe-component-library/question-mark-icon';
import { IData } from '@transferz/fe-component-library/types/src/types/dropdown';
import Typography from '@transferz/fe-component-library/typography';
import XIcon from '@transferz/fe-component-library/x-icon';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useLocalize } from 'localize-react';
import React, {
  FunctionComponent, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { langs } from '../assets/translations/languages';
import { LanguageContext } from '../language/LanguageContext';
import { fetchTranslations, languageCodes } from '../language/translations';
import { CacheProfileEmpty } from '../models/CacheProfile';
import { GetCacheProfile } from '../models/graphql';
import { useSetCacheProfile } from '../services/apolloCacheHooks';
import { routes } from '../services/constant';
import { storeLanguage } from '../services/store';

type HeaderProps = {
  flags?: any;
};

const Header: FunctionComponent<HeaderProps> = ({ flags }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useLocalize();

  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const setCacheProfile = useSetCacheProfile();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const { language, setLanguage } = useContext(LanguageContext);
  const [languages, setLanguages] = useState<IData[]>([]);

  const showMenu =
    location.pathname !== routes.login.phone &&
    location.pathname !== routes.login.email &&
    location.pathname !== routes.login.name &&
    location.pathname !== routes.login.pin &&
    (cachedProfileData.email || cachedProfileData.phone);

  const toggleMenu = () => {
    console.log('DEBUG User clicked menu button');
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    setCacheProfile(CacheProfileEmpty).then(() => {
      navigate('/');
    });
  };

  const profile = cachedProfileData?.photo ? (
    <img className="img-profile" src={cachedProfileData.photo} alt={cachedProfileData.name} />
  ) : (
    <ProfileIcon color="asphalt7" />
  );

  const menuItems = [
    {
      label: translate('your_journeys'),
      icon: <ListIcon color="asphalt7" />,
      onClick: () => {
        console.log('DEBUG User clicked Your Journeys, redirecting to journeys overview page...');
        navigate('/journeys');
      },
    },
    {
      label: translate('profile'),
      icon: profile,
      onClick: () => {
        console.log('DEBUG User clicked Profile, redirecting to profile page...');
        navigate('/profile');
      },
    },
    {
      label: translate('support'),
      icon: <QuestionMarkIcon color="asphalt7" />,
      onClick: () => {
        console.log('DEBUG User clicked Support, redirecting to support page...');
        navigate('/support');
      },
    },
  ];

  useEffect(() => {
    const enabledLangs = Object.keys(flags)
      .filter((key: string) => key.substring(0, 8) === 'language' && !!flags[key])
      .map((filteredKey: string) => filteredKey.substring(8, filteredKey.length).toLowerCase());
    const availableLanguages = enabledLangs.map((lang: string) => {
      const currentLang = langs.find((curr) => curr.code === lang) as any;
      return {
        label: `${currentLang.name} (${lang.toUpperCase()})`,
        value: lang,
        icon: currentLang.flag,
      };
    });
    setLanguages(availableLanguages);
  }, []);
  const sortedLanguages = useMemo(
    () => languages.sort((a: any, b: any) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;
      return 0;
    }),
    [languages],
  );

  return (
    <div className="header d-flex align-items-center justify-content-space-between padding-horizontal-21">
      <div className="d-flex align-items-center" onClick={() => navigate('/journeys')}>
        <LogoIcon className="logo-icon" />
        <Typography variant="h6" color="light" className="margin-left-13">
          {translate('driver_app')}
        </Typography>
      </div>
      <div className="d-flex align-items-center">
        <span onClick={() => navigate(routes.support)}>
          <Typography variant="body1" color="asphalt3" className="m-r-16 pointer">
            {translate('support')}
          </Typography>
        </span>

        {showMenu &&
          (flags.hamburgerMenu ? (
            <div
              className="menu-icon-container d-flex align-items-center justify-content-center"
              onClick={toggleMenu}
              ref={buttonRef}
              data-testid="button_support-menu"
            >
              {isMenuOpen ? <XIcon color="light" width={15} /> : <MenuIcon color="light" className="pointer" />}
            </div>
          ) : (
            <div
              className="profile-icon-container bg-color-asphalt5 d-flex align-items-center justify-content-center"
              onClick={() => navigate('/profile')}
            >
              <ProfileIcon color="light" />
            </div>
          ))}
      </div>

      <PopUp
        isOpen={isMenuOpen}
        togglePopUp={toggleMenu}
        items={menuItems}
        langSelect
        langOptions={sortedLanguages}
        langValue={language}
        onLanguageChange={(lang: IData) => {
          fetchTranslations(lang.value as string).then(() => {
            console.log(`DEBUG User changed language to ${lang.value}`);
            setLanguage!(lang.value as string);
            storeLanguage(lang.value as string);
          });
        }}
        className="hamburger-menu-pop-up"
      />
    </div>
  );
};

export default withLDConsumer()(Header);
