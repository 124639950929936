import { gql } from '@apollo/client/core';

const sendOtpQuery = gql`mutation sendOTP($phone: String!, $captchaToken: String) {
    sendOTP(phone: $phone, captchaToken: $captchaToken) {
      success
      message
      errorCode
      error
  }
}`;

export default sendOtpQuery;
