import { useQuery } from '@apollo/client';
import { Button } from '@transferz/fe-component-library/button';
import CarIcon from '@transferz/fe-component-library/car-icon';
import PlusCircleIcon from '@transferz/fe-component-library/circle-plus-icon';
import Dropdown from '@transferz/fe-component-library/dropdown';
import ImageUpload from '@transferz/fe-component-library/image-upload';
import Input from '@transferz/fe-component-library/input';
import Loader from '@transferz/fe-component-library/loader';
import Notification from '@transferz/fe-component-library/notification';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import { set } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';

import StickyBackButton from '../components/StickyBackButton';
import Vehicle from '../components/Vehicle';
import { CacheDriverVehicle } from '../models/CacheDriverVehicles';
import { CacheVehicleMake } from '../models/CacheVehicleMakes';
import { GetCacheJourneys, GetCacheProfile, GetCacheVehicleMakes } from '../models/graphql';
import {
  useAddVehicle,
  useAssignJourney,
  useAssignVehicle,
  useGetAssociatedJourneys,
  useGetDriverVehicles,
  useGetVehicleMakes,
  useGetVehicleSeries,
  useSetDriverPhoto,
  useSetJourneyStatus,
} from '../services/apolloNetworkHooks';
import { routes } from '../services/constant';
import { useAddAction } from '../services/hooks/useAddAction';
import { useDataLayer } from '../services/hooks/useDataLayer';
import { LocationServices, NotificationType } from '../types/global';

export interface IData {
  value: number;
  label: string;
}

// type FormFields = {
//   license: string;
//   carBrand: number;
//   carType: number;
// };

let latestSelectedVehicleId = 0;

const DriverInformation: FunctionComponent = () => {
  const navigate = useNavigate();
  const { translate } = useLocalize();
  const { trackEvent } = useDataLayer();
  const { addAction } = useAddAction();
  const location: { [key: string]: any } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<number>(0);
  const [vehicles, setVehicles] = useState<CacheDriverVehicle[]>([]);
  const [carMakes, setCarMakes] = useState<IData[]>([]);
  const [carSeriesDropdown, setCarSeriesDropdown] = useState<IData[]>([]);
  const [notification, showNotification] = useState<NotificationType>();
  const [locationServices, setLocationServices] = useState<LocationServices>(LocationServices.UNKNOWN);
  const [showButtonLoading, setShowButtonLoading] = useState<boolean>(false);

  const { data: cachedVehicleMakes } = useQuery(GetCacheVehicleMakes);
  const { data: cachedJourneysData } = useQuery(GetCacheJourneys);
  const { data: cachedProfileData, loading: loadingProfileData } = useQuery(GetCacheProfile);

  const getAssociatedJourneys = useGetAssociatedJourneys();
  const getVehicleMakes = useGetVehicleMakes();
  const getVehicleSeries = useGetVehicleSeries();
  const getDriverVehicles = useGetDriverVehicles();
  const updateDriverPhoto = useSetDriverPhoto();
  const assignJourney = useAssignJourney();
  const assignVehicle = useAssignVehicle();
  const setJourneyStatus = useSetJourneyStatus();
  const addVehicle = useAddVehicle();

  const {
    register, handleSubmit, control, watch,
  } = useForm();

  const licenseValue = watch('license');
  const carBrandValue = watch('carBrand');
  const carTypeValue = watch('carType');

  const selectVehicle = (id: number) => {
    setSelectedVehicleId(id);
  };

  const getJourneys = () => {
    getAssociatedJourneys({}, () => {
      const driverCode = cachedProfileData.lastJourneyView;

      if (driverCode) navigate(`${routes.journey}/${driverCode}`);
    });
  };

  const handleSubmitAndStartJourney = (position: any) => {
    const handleJourneyStatusChange = () => {
      setJourneyStatus(
        cachedProfileData.lastJourneyId,
        'underway',
        position.coords ? String(position.coords.latitude) : '',
        position.coords ? String(position.coords.longitude) : '',
        (data: any) => {
          if (data?.success) {
            console.log(
              `INFO Journey ${cachedProfileData?.lastJourneyView || ''} started, redirecting to journey page...`,
            );
            getJourneys();
            setShowButtonLoading(false);
          }
          else {
            setLoading(false);
          }
        },
      );
    };
    const handleVehicleAssign = () => {
      assignVehicle(cachedProfileData.lastJourneyId, selectedVehicleId || latestSelectedVehicleId, (data: any) => {
        latestSelectedVehicleId = 0;

        if (data?.success) {
          handleJourneyStatusChange();
        }
        else {
          setLoading(false);
        }
      });
    };
    const handleDriverAssign = () => {
      assignJourney(cachedProfileData.lastJourneyId, cachedProfileData.lastJourneyView, (data: any) => {
        if (data?.success) {
          handleVehicleAssign();
        }
        else {
          setLoading(false);
        }
      });
    };

    setLoading(true);
    let cachedJourney;
    if (cachedJourneysData?.items?.length) {
      cachedJourney = cachedJourneysData.items.filter(
        (journey: any) => journey.driverCode === cachedProfileData.lastJourneyView,
      )[0];

      if (cachedJourney?.execution?.assignedDriverId) {
        if (cachedJourney?.execution?.assignedDriverVehicleId) {
          handleJourneyStatusChange();
        }
        else {
          handleVehicleAssign();
        }
      }
      else {
        handleDriverAssign();
      }
    }
    else {
      handleDriverAssign();
    }
    trackEvent({
      event: 'journey-started',
      driverCode: cachedJourney?.driverCode,
      journeyCode: cachedJourney?.journeyCode,
    });
    addAction('journey-started', {
      driverCode: cachedJourney?.driverCode,
      journeyCode: cachedJourney?.journeyCode,
    });
  };

  const handleLocationErrorCases = (error: any) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        showNotification({
          text: translate('location_denied'),
          variant: 'neutral',
        });
        setLocationServices(LocationServices.DISABLED);
        break;
      case error.POSITION_UNAVAILABLE:
        setLocationServices(LocationServices.DISABLED);
        break;
      case error.TIMEOUT:
        setLocationServices(LocationServices.DISABLED);
        break;
      default:
        console.error('Unexpected error code from Geolocation API: ', error.code);
        setLocationServices(LocationServices.DISABLED);
        break;
    }
  };

  const handleGetDriverLocation = () => {
    if (navigator.geolocation) {
      let timeoutId: any = null;

      // Start a timer
      timeoutId = setTimeout(() => {
        setLocationServices(LocationServices.DISABLED);
      }, 2000);
      try {
        navigator.geolocation.getCurrentPosition(
          // success callback
          (position) => {
            clearTimeout(timeoutId);
            setLocationServices(LocationServices.ENABLED);
            handleSubmitAndStartJourney(position);
          },
          // error callback (position will be undefined)
          (error) => {
            clearTimeout(timeoutId);
            handleLocationErrorCases(error);
            setTimeout(() => {
              handleSubmitAndStartJourney('error');
            }, 2000);
          },
        );
      }
      catch (e) {
        alert('Location is not available!');
        handleSubmitAndStartJourney('error');
      }
    }
    else {
      alert('navigator.geolocation is not available!');
      handleSubmitAndStartJourney('error');
    }
  };

  const onSubmit: SubmitHandler<any> = () => {
    console.log('DEBUG User clicked on "Submit and start journey" button...');
    setLocationServices(LocationServices.UNKNOWN);
    setShowButtonLoading(true);
    if (licenseValue && carTypeValue && cachedProfileData?.photo) {
      addVehicle(cachedProfileData?.driverId, carTypeValue, licenseValue, (newVehicle: any) => {
        console.log('INFO New vehicle added successfully');
        if (!selectedVehicleId) {
          latestSelectedVehicleId = newVehicle.id;

          setSelectedVehicleId(latestSelectedVehicleId);
          getDriverVehicles((newVehicles: CacheDriverVehicle[]) => {
            setVehicles(newVehicles);
          });
          handleGetDriverLocation();
        }
      });
    }
    // If driver picked one of his already existing vehicles
    else if (vehicles?.length && selectedVehicleId) {
      handleGetDriverLocation();
    }
  };

  const handleAddNewVehicle = () => {
    console.log('DEBUG User clicked on add new vehicle button, navigating to new vehicle page...');
    navigate(routes.newVehicle, { state: { route: 'information' } });
  };

  const handleUploadPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files![0];

    setLoading(true);

    updateDriverPhoto(file, (data: any) => {
      if (data?.data?.uploadDriverPhoto?.success) {
        console.log(`DEBUG User changed profile photo to: ${file?.name}, file size: ${file?.size} kb`);

        if (!data?.url) {
          showNotification({ text: translate('uploaded_photo'), variant: 'success' });
        }
        else {
          showNotification({ text: translate('changed_photo'), variant: 'success' });
        }
        setLoading(false);
      }
      else {
        showNotification({ text: translate('could_not_save'), variant: 'error' });
        setLoading(false);
      }
    });
  };

  const renderLocationColor = () => {
    if (locationServices === LocationServices.ENABLED) return 'atlantis5';
    if (locationServices === LocationServices.DISABLED) return 'red5';
    return 'asphalt6';
  };

  useEffect(() => {
    if (!carBrandValue) return;
    const value = carBrandValue.value ? carBrandValue.value : carBrandValue;
    getVehicleSeries(value, (data) => {
      setCarSeriesDropdown(data);
    });
  }, [carBrandValue]);

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        showNotification(undefined);
      }, 2000);
    }
  }, [loadingProfileData, notification]);

  useEffect(() => {
    if (!vehicles?.length) {
      setLoading(true);

      getDriverVehicles((newVehicles: CacheDriverVehicle[]) => {
        newVehicles.sort((a, b) => Date.parse(b.updated) - Date.parse(a.updated));
        if (newVehicles[0]) setSelectedVehicleId(newVehicles[0].id);

        setVehicles(newVehicles);
        setLoading(false);
      });
    }
  }, [cachedProfileData?.driverId]);

  useEffect(() => {
    console.log('INFO Driver information page loaded');
    if (navigator.geolocation) {
      let timeoutId: any = null;

      // Start a timer
      timeoutId = setTimeout(() => {
        setLocationServices(LocationServices.DISABLED);
      }, 2000);

      navigator.geolocation.getCurrentPosition(
        // success callback
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {
          clearTimeout(timeoutId);
          setLocationServices(LocationServices.ENABLED);
        },
        // error callback (position will be undefined)
        (error) => {
          clearTimeout(timeoutId);
          handleLocationErrorCases(error);
        },
      );
    }
    else {
      alert('Browser does not support geolocation!');
    }

    // If no cached vehicle makes, fetch them all for a dropdown
    if (!cachedVehicleMakes?.makes?.length) {
      getVehicleMakes((vehicleMakes: CacheVehicleMake[]) => {
        if (vehicleMakes?.length) {
          setCarMakes(vehicleMakes);
        }
        else {
          // @TODO write some kind of an error to the user
        }
      });
    }
    else {
      setCarMakes(cachedVehicleMakes.makes);
    }

    if (location?.state?.notification) {
      window.history.replaceState({}, document.title);

      switch (location?.state?.notification) {
        case 'delete':
          showNotification({ text: translate('deleted_vehicle'), variant: 'success' });
          break;
        case 'update':
          showNotification({ text: translate('changed_details'), variant: 'success' });
          break;
        case 'create':
          showNotification({ text: translate('new_vehicle'), variant: 'success' });
          break;
        default:
      }
    }
  }, []);

  if (loading) {
    return <Loader text={translate('please_wait')} fullScreen />;
  }

  return (
    <div className="driver-information-page m-b-65">
      <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
        <StickyBackButton />
        <Typography variant="h4" data-testid="title_driver-information">
          {translate('submit_information')}
        </Typography>
        <div className="information m-t-30">
          <Typography variant="caption" color="dark" className="m-b-24 d-flex flex-direction-row">
            {translate('location_services')}
            :
            {' '}
            <Typography variant="caption" className="m-l-5 uppercase" color={renderLocationColor()}>
              {translate(locationServices)}
            </Typography>
          </Typography>
          <Input
            hasValue
            type="text"
            nonEditable
            label={translate('your_name_label')}
            className="m-b-24"
            defaultValue={cachedProfileData?.name || 'N/A'}
          />
          <Input
            hasValue
            type="text"
            nonEditable
            label={translate('mobile_number')}
            className="m-b-24"
            defaultValue={cachedProfileData?.phone || translate('N_A')}
          />

          <ImageUpload
            onChange={handleUploadPhoto}
            photo={cachedProfileData.photo}
            title={translate('your_profile_picture')}
            existingPhotoText={translate('change_photo')}
            newPhotoText={translate('new_picture')}
            className="m-b-34"
          />

          {vehicles?.length ? (
            /* Driver has existing vehicles related to him */
            <div className="vehicles-wrapper">
              <Typography variant="caption">{translate('your_vehicle')}</Typography>

              {vehicles.map((vehicle: CacheDriverVehicle) => (
                <div key={vehicle.id} className="m-t-5">
                  <Vehicle vehicle={vehicle} handleClick={selectVehicle} selected={selectedVehicleId} />
                </div>
              ))}

              <div className="d-flex align-items-center justify-content-center m-t-22">
                <div className="d-flex align-items-center pointer" onClick={handleAddNewVehicle}>
                  <PlusCircleIcon color="asphalt7" />
                  <Typography variant="button" color="asphalt6" className="m-l-15 m-t-1">
                    {translate('new_vehicle')}
                  </Typography>
                </div>
              </div>
            </div>
          ) : (
            /* Driver doesn't have existing vehicles related to him */
            <>
              <Input
                {...register('license')}
                hasValue={!!licenseValue}
                type="text"
                upperCase
                label={translate('your_plate_number')}
                placeholder={translate('plate_number')}
                className="m-b-24 repetitive"
                defaultValue={licenseValue}
                iconLeft={<CarIcon />}
                data-testid="input_license-text"
                confirmed={licenseValue?.length >= 3}
              />

              <Controller
                name="carBrand"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Dropdown
                    placeholder={translate('choose_car_brand')}
                    data={carMakes}
                    label={translate('car_brand')}
                    className="m-b-24"
                    value={value}
                    onChange={(brand: IData) => {
                      onChange(brand.value);
                    }}
                    onBlur={onBlur}
                    confirmed={!!carBrandValue}
                    isSearchable
                  />
                )}
              />
              <Controller
                name="carType"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Dropdown
                    placeholder={translate('choose_car_type')}
                    data={carSeriesDropdown}
                    label={translate('car_type')}
                    className="m-t-24"
                    disabled={carBrandValue === 0}
                    value={value}
                    onChange={(type: IData) => {
                      onChange(type.value);
                    }}
                    onBlur={onBlur}
                    confirmed={!!carTypeValue}
                    isSearchable
                  />
                )}
              />
            </>
          )}
        </div>
        {notification && (
          <div className="notification-wrapper w-12 position-fixed bottom-80-px p-h-16 left-0 z-3">
            <Notification
              message={notification.text}
              variant={notification.variant}
              className="update-vehicle-notification"
              isSnackBar
            />
          </div>
        )}

        <div className="button-wrapper-bottom w-12 position-fixed bottom-0 left-0 p-16 z-3">
          <Button
            label={translate('submit_save')}
            priority="primary"
            variant="confirm"
            fullWidth
            iconPosition="right"
            icon={showButtonLoading ? <Loader spinner /> : <div />}
            data-testid="submit_driver-information"
            type="submit"
            // Disabled if driver didn't pick existing vehicle or inserted mandatory values for new one
            // Disabled if driver didn't upload a photo or doesn't have one already uploaded
            disabled={
              !cachedProfileData?.photo ||
              (!selectedVehicleId && !(!!licenseValue && !!carTypeValue)) ||
              locationServices === LocationServices.UNKNOWN
            }
          />
        </div>
      </form>
    </div>
  );
};

export default DriverInformation;
