import CircleIcon from '@transferz/fe-component-library/circle-icon';
import DangerIcon from '@transferz/fe-component-library/danger-icon';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React from 'react';

import { JourneyStatus } from '../../types/journeyStatus';
import StatusIndicator from '../StatusIndicator';

interface JourneyOverviewBoxProps {
    status?: JourneyStatus | undefined;
    dangerIcon?: boolean
  }

const JourneyHeaderBox = ({
  status,
  dangerIcon = false,
}: JourneyOverviewBoxProps) => (
  <div data-testid="journey-status_title" className="top-wrapper d-flex justify-center align-center">
    {status && (
    <StatusIndicator withoutLabel status={status} />
    )}
    {dangerIcon && <DangerIcon color="red5" height={18} className="m-l-10" />}
  </div>
);

export default JourneyHeaderBox;
