import { gql } from '@apollo/client/core';

const getVehicleMakes = gql`query getVehicleMakes($accessToken: String!) {
  getVehicleMakes(page: 0, size: 9999, accessToken: $accessToken) {
      success
      message
      pagination {
          currentPage
          pageSize
          totalPages
          totalResults
      } 
      results {
          name
          id
      }
  }
}`;

export default getVehicleMakes;
