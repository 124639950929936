import React from 'react';

import { JourneyStatus } from '../types/journeyStatus';

interface ProgressBarStatusProps {
    journeyStatus?: JourneyStatus,
    assignedToAnotherDriver?: boolean,
    setShowProgresBarStatus: () => void
}

const ProgressBarStatusButton = ({
  journeyStatus = JourneyStatus.AVAILABLE,
  assignedToAnotherDriver = true,
  setShowProgresBarStatus,
}: ProgressBarStatusProps) => {
  const currentStatus = () => {
    if (!assignedToAnotherDriver) return '';
    const status = journeyStatus?.toString().toLocaleLowerCase();
    return status;
  };

  return (
    <div className={`d-flex p-v-12 circles-button ${currentStatus()}`} onClick={setShowProgresBarStatus}>
      <div className="circle carOne" />
      <div className="circle location" />
      <div className="circle carTwo" />
      <div className="circle check" />
    </div>
  );
};

export default ProgressBarStatusButton;
