import { useQuery } from '@apollo/client';
import BottomDrawer from '@transferz/fe-component-library/bottom-drawer';
import { Button } from '@transferz/fe-component-library/button';
import CameraIcon from '@transferz/fe-component-library/camera-icon';
import CarRightIcon from '@transferz/fe-component-library/car-right-icon';
import ChevronIcon from '@transferz/fe-component-library/chevron-icon';
import CircleCheckIcon from '@transferz/fe-component-library/circle-check-icon';
import CirclePlusIcon from '@transferz/fe-component-library/circle-plus-icon';
import CircleXIcon from '@transferz/fe-component-library/circle-x-icon';
import { FlightStatusEnum, FlightTracker } from '@transferz/fe-component-library/flight-tracker';
import GeoDirection from '@transferz/fe-component-library/geo-direction';
import ImageUpload from '@transferz/fe-component-library/image-upload';
import Loader from '@transferz/fe-component-library/loader';
import LocationIcon from '@transferz/fe-component-library/location-icon';
import Notification from '@transferz/fe-component-library/notification';
import ShipIcon from '@transferz/fe-component-library/ship-icon';
import TimerIcon from '@transferz/fe-component-library/timer-icon';
import Typography from '@transferz/fe-component-library/typography';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useLocalize } from 'localize-react';
import React, {
  FunctionComponent, useEffect, useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import FilePreview from '../components/FilePreview';
import FileUpload from '../components/fileUpload/fileUpload';
import FileUploadSecond from '../components/fileUpload/fileUploadSecond';
import JourneyInfo from '../components/Journey/JourneyInfo';
import JourneyInfoBox from '../components/Journey/JourneyInfoBox';
import PickupIssues from '../components/PickupIssues/PickupIssues';
import SingleIssueDrawer from '../components/PickupIssues/SingleIssueDrawer';
import ProgressBarStatus from '../components/ProgressBarStatus';
import ProgressBarStatusButton from '../components/ProgressBarStatusButton';
import { GetCacheJourneys, GetCacheProfile } from '../models/graphql';
import { useSetApiError, useSetCacheProfile } from '../services/apolloCacheHooks';
import {
  useAssociateJourney,
  useCreateAction,
  useGetAssociatedJourneys,
  useGetJourneyByCode,
  useGetPhoneByCountry,
  useGetPhoneByLocation,
  useSearchActions,
  useSetJourneyStatus,
  useUploadAttachment,
} from '../services/apolloNetworkHooks';
import { useGetFlightInfo } from '../services/apolloNetworkHooks/useGetFlightInfo';
import { useUpdateDriverPosition } from '../services/apolloNetworkHooks/useUpdateDriverPosition';
import { baseURL, filterStatuses, routes } from '../services/constant';
import { useAddAction } from '../services/hooks/useAddAction';
import { useDataLayer } from '../services/hooks/useDataLayer';
import { JourneyAction, JourneyActionTypeEnum } from '../types/actions';
import { FlightInfo, Journey } from '../types/journey';
import { JourneyStatus, JourneyStatusStrings } from '../types/journeyStatus';
import { before24Hours } from '../utils/parsingFunctions';
import { JourneyFiltersDefault } from './JourneyOverview';

let buttonTimeout: ReturnType<typeof setTimeout> | null = null;
let completedMessageTimeout: ReturnType<typeof setTimeout> | null = null;

export enum PickupIssuesEnum {
  too_many_travellers = 'too_many_travellers',
  too_many_suitcases = 'too_many_suitcases',
  missing_addons = 'missing_addons',
  im_too_late = 'im_too_late',
  blockage_at_pickup_point = 'blockage_at_pickup_point',
  cannot_find_traveller = 'cannot_find_traveller',
  refuses_to_get_in_car = 'refuses_to_get_in_car',
}

export type SingleIssue = {
  type: keyof typeof PickupIssuesEnum;
  title: string;
  text?: string;
};

type FileType = {
  file: File | null;
  url: string;
  name: string;
  size: number;
  date: Date | number;
};

const JourneyPage: FunctionComponent<any> = ({ flags }) => {
  const classBase = 'journey-info-page';
  const navigate = useNavigate();
  const { translate } = useLocalize();
  const { trackEvent } = useDataLayer();
  const { addAction } = useAddAction();
  const [onMyWayButtonProgress, setOnMyWayButtonProgress] = useState(false);
  const [showProgresBarStatus, setShowProgresBarStatus] = useState(false);
  const [journey, setJourney] = useState<Journey | null>(null);
  const [journeyStatus, setJourneyStatus] = useState<JourneyStatus>();
  const [alreadyAssociated, setAlreadyAssociated] = useState<boolean>(false);
  const [availableForStarting, setAvailableForStarting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [completedMessage, setCompletedMessage] = useState<boolean>(false);
  const [completedError, setCompletedError] = useState<boolean>(false);
  const [assignedToAnotherDriver, setAssignedToAnotherDriver] = useState<boolean>(false);
  const [journeyAdded, setJourneyAdded] = useState<boolean>(false);
  const [completeJourneyDrawer, setCompleteJourneyDrawer] = useState(false);
  const [journeyIssuesDrawer, setJourneyIssuesDrawer] = useState(false);
  const [singleIssueDrawer, setSingleIssueDrawer] = useState<SingleIssue | null>(null);
  const [customerServicePhone, setCustomerServicePhone] = useState<string>('');
  const [submitProofDrawer, setSubmitProofDrawer] = useState<boolean>(false);
  const [locationPicture, setLocationPicture] = useState<FileType>();
  const [otherProof, setOtherProof] = useState<FileType[]>([]);
  const [submitProofSuccess, setSubmitProofSuccess] = useState<boolean>(false);
  const [googleApiKey, setGoogleApiKey] = useState<string>('');
  const [actions, setActions] = useState<JourneyAction[]>([]);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [disabledAtPickup, setDisabledAtPickup] = useState<boolean>(true);
  const [canNotStartJourneyDrawer, setCanNotStartJourneyDrawer] = useState<boolean>(false);
  const [flightInfo, setFlightInfo] = useState<FlightInfo & { updatedOn: string }>();
  const [flightInfoLoading, setFlightInfoLoading] = useState(false);
  const [driverPosition, setDriverPosition] = useState({} as any);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const intervalRef = useRef<any>();

  const getAssociatedJourneys = useGetAssociatedJourneys();
  const getJourneyByCode = useGetJourneyByCode();
  const associateJourney = useAssociateJourney();
  const setCacheProfile = useSetCacheProfile();
  const changeJourneyStatus = useSetJourneyStatus();
  const setApiError = useSetApiError();
  const getPhoneByCountry = useGetPhoneByCountry();
  const getPhoneByLocation = useGetPhoneByLocation();
  const createAction = useCreateAction();
  const uploadAttachment = useUploadAttachment();
  const searchActions = useSearchActions();
  const getFlightInfo = useGetFlightInfo();
  const updateDriverPosition = useUpdateDriverPosition();

  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const { data: cachedJourneysData } = useQuery(GetCacheJourneys);

  const { code } = useParams();
  const callLinkRef = useRef<HTMLAnchorElement | null>(null);
  const smsLinkRef = useRef<HTMLAnchorElement | null>(null);
  const customerServiceLinkRef = useRef<HTMLAnchorElement | null>(null);
  const transferCompanyLinkRef = useRef<HTMLAnchorElement | null>(null);

  const driverLoggedIn = cachedProfileData?.phone || cachedProfileData?.email;
  const driverCode = journey?.execution?.executionCode;
  const journeyCode = journey?.journeyCode;
  const parts: any = driverCode?.match(/.{1,3}/g);
  const splittedDriverCode = parts && parts.join(' ');
  const location: { [key: string]: any } = useLocation();
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);
  const [trackDriverPosition, setTrackDriverPosition] = useState<boolean>(false);

  const handleUpdateDriverPosition = () => {
    if (journey && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          updateDriverPosition(journey?.id, pos?.coords?.latitude, pos?.coords?.longitude, () => {
            console.log('INFO driver position updated');
          });
        },
        (error) => {
          console.log('INFO cant fetch position');
          console.log('INFO error code', error?.code);
          console.log('INFO error message', error?.message);
        },
      );
    }
  };

  useEffect(() => {
    if (flags.trackDriverPosition && trackDriverPosition) {
      console.log('start');
      intervalRef.current = setInterval(() => {
        handleUpdateDriverPosition();
      }, 15000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [trackDriverPosition, journey]);

  const getJourneys = () => {
    getAssociatedJourneys({}, () => {
      setLoadingStatus(false);
    });
  };

  const handleAddToMyJourney = () => {
    console.log(`DEBUG User clicked on "Add to my journeys" button, associating journey ${code}`);
    // For authorized driver immediately associate journey
    if (driverLoggedIn && code) {
      associateJourney(code, (associateJourneyData: any) => {
        if (associateJourneyData.success) {
          console.log(`INFO Journey ${code} associated successfully`);
          setAlreadyAssociated(true);
          setAvailableForStarting(true);
          setJourneyAdded(true);
          setJourneyStatus(JourneyStatus.PLANNED);
          setTimeout(() => {
            setJourneyAdded(false);
          }, 2000);
        }
        else {
          setApiError(true);
        }
      });
    }
    // Un-authorized driver needs to register before associating a journey
    else {
      setCacheProfile({
        lastJourneyView: code,
      }).then(() => {
        navigate(routes.login.phone);
      });
    }
  };

  const handleStartJourney = () => {
    setCacheProfile({
      lastJourneyView: code,
      lastJourneyId: journey?.id,
    }).then(() => {
      navigate(routes.information, {
        state: {
          journeyId: journey?.id,
          driverCode: journey?.driverCode,
        },
      });
    });
  };

  const handleGetDriverLocation = (callback: (position?: GeolocationPosition) => void) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        callback,
        (e: any) => {
          // @TODO here we should display an error about disabled location permissions
          // For now we just continue with the API call
          if (callback) callback();
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      );
    }
    else {
      alert(translate('geolocation_not_supported'));
    }
  };

  const handleArrivedAtPickup = (position?: GeolocationPosition) => {
    setLoadingStatus(true);
    setDriverPosition({
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
    });

    if (journey?.id) {
      changeJourneyStatus(
        journey.id,
        'driver-arrived',
        position ? String(position.coords.latitude) : '',
        position ? String(position.coords.longitude) : '',
        () => {
          console.log(`INFO Journey ${code} status changed to "driver-arrived"`);
          setJourneyStatus(JourneyStatus.ARRIVED_AT_PICKUP);
          getJourneys();
        },
      );

      trackEvent({
        event: 'journey-arrived',
        driverCode,
        journeyCode,
      });
      addAction('journey-arrived', {
        driverCode,
        journeyCode,
      });
    }
  };

  const handleJourneyInProgress = (position?: GeolocationPosition) => {
    setLoadingStatus(true);
    setDriverPosition({
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
    });
    if (journey?.id) {
      changeJourneyStatus(
        journey.id,
        'progress',
        position ? String(position.coords.latitude) : '',
        position ? String(position.coords.longitude) : '',
        () => {
          console.log(`INFO Journey ${code} status changed to "progress"`);
          setJourneyStatus(JourneyStatus.JOURNEY_IN_PROGRESS);
          getJourneys();
          console.log('INFO Get jouneys');
        },
      );

      trackEvent({
        event: 'journey-in-progress',
        driverCode,
        journeyCode,
      });
      addAction('journey-in-progress', {
        driverCode,
        journeyCode,
      });
    }
  };

  const handleArrivedAtDropOff = (position?: GeolocationPosition) => {
    setDriverPosition({
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
    });
    if (
      journey?.execution?.pickupDate &&
      new Date(journey?.execution?.pickupDate).getTime() - new Date().getTime() > 3600 * 1000 * 24
    ) {
      setCompletedError(true);

      if (completedMessageTimeout) {
        clearTimeout(completedMessageTimeout);
      }

      completedMessageTimeout = setTimeout(() => {
        setCompletedError(false);
      }, 2000);
    }
    else {
      setLoadingStatus(true);

      if (journey?.id) {
        setTrackDriverPosition(false);
        changeJourneyStatus(
          journey.id,
          'complete',
          position ? String(position.coords.latitude) : '',
          position ? String(position.coords.longitude) : '',
          (data) => {
            if (data.success) {
              console.log(`INFO Journey ${code} status changed to "complete"`);
              setCompletedMessage(true);
              setJourneyStatus(JourneyStatus.COMPLETED);
              getJourneys();

              if (completedMessageTimeout) {
                clearTimeout(completedMessageTimeout);
              }

              completedMessageTimeout = setTimeout(() => {
                setCompletedMessage(false);
              }, 2000);
            }
            else {
              setCompleteJourneyDrawer(true);
              setLoadingStatus(false);
            }
          },
        );

        trackEvent({
          event: 'journey-completed',
          driverCode,
          journeyCode,
        });
        addAction('journey-completed', {
          driverCode,
          journeyCode,
        });
      }
    }
  };

  const confirmArrivedAtDropOff = (position?: GeolocationPosition) => {
    console.log("DEBUG User clicked on 'Arrived at drop-off' button");
    setTrackDriverPosition(false);
    if (journey?.id) {
      changeJourneyStatus(
        journey.id,
        'complete',
        position ? String(position.coords.latitude) : '',
        position ? String(position.coords.longitude) : '',
        (data) => {
          if (data.success) {
            console.log(`INFO Journey ${code || ''} completed successfully`);
            setCompletedMessage(true);
            setJourneyStatus(JourneyStatus.COMPLETED);
            getJourneys();

            if (completedMessageTimeout) {
              clearTimeout(completedMessageTimeout);
            }

            completedMessageTimeout = setTimeout(() => {
              setCompletedMessage(false);
            }, 2000);
          }
          setCompleteJourneyDrawer(false);
        },
        true,
      );
    }
  };

  const getPhone = (position: any) => {
    if (position.coords) {
      setDriverPosition({
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      });
      getPhoneByLocation(
        'DRIVER',
        String(position.coords.latitude),
        String(position.coords.longitude),
        '',
        (data: any) => {
          if (data?.success) {
            setCustomerServicePhone(data?.phone);
          }
        },
      );
    }
    else {
      getPhoneByCountry('US', 'DRIVER', '', (data: any) => {
        if (data?.success) {
          setCustomerServicePhone(data?.phone);
        }
      });
    }
  };

  const getJourneyActions = () => {
    searchActions(journey!.id, 0, 100, (data) => {
      setActions(data);
    });
  };

  const callCustomerService = () => {
    console.log("DEBUG User clicked on 'Call customer service' button, calling customer service");
    customerServiceLinkRef?.current?.click();
  };

  const callTransferCompany = () => {
    console.log("DEBUG User clicked on 'Call transfer company' button, calling transfer company");
    transferCompanyLinkRef?.current?.click();
  };

  const openSubmitProofDrawer = () => {
    console.log("DEBUG User clicked on 'Submit proof' button, opened submit proof drawer");
    setSubmitProofDrawer(true);
    setSingleIssueDrawer(null);
  };

  const issues: { [key in PickupIssuesEnum]: SingleIssue } = {
    too_many_travellers: {
      type: 'too_many_travellers',
      title: translate('too_many_travellers_title'),
      text: translate('too_many_travellers_text'),
    },
    too_many_suitcases: {
      type: 'too_many_suitcases',
      title: translate('too_many_suitcases_title'),
      text: translate('too_many_suitcases_text'),
    },
    missing_addons: {
      type: 'missing_addons',
      title: translate('missing_addons_title'),
      text: translate('missing_addons_text'),
    },
    im_too_late: {
      type: 'im_too_late',
      title: translate('im_too_late_title'),
    },
    blockage_at_pickup_point: {
      type: 'blockage_at_pickup_point',
      title: translate('blockage_at_pickup_point_title'),
    },
    cannot_find_traveller: {
      type: 'cannot_find_traveller',
      title: translate('cannot_find_traveller_title'),
      text: translate('cannot_find_traveller_text'),
    },
    refuses_to_get_in_car: {
      type: 'refuses_to_get_in_car',
      title: translate('refuses_to_get_in_car_title'),
      text: translate('refuses_to_get_in_car_text'),
    },
  };

  const openSingleIssueDrawer = (issue: keyof typeof PickupIssuesEnum) => {
    setJourneyIssuesDrawer(false);
    setSingleIssueDrawer(issues[issue]);
  };

  const uploadLocationPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = (e.target as HTMLInputElement).files![0];
    const filesize = file ? file.size / 1024 / 1024 : 0;
    const url = URL.createObjectURL(file);
    if (filesize >= 25) {
      setFileSizeError(true);
      console.log(`DEBUG User tried to upload a file that is too big: ${file.name} with size ${file.size}`);
    }
    else {
      setLocationPicture({
        file,
        url,
        name: file.name,
        size: file.size,
        date: file.lastModifiedDate,
      });
      console.log(
        `DEBUG User ${locationPicture ? 'uploaded' : 'changed'} location photo: ${file.name} with size ${file.size}`,
      );
    }
  };

  const uploadMoreProof = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = (e.target as HTMLInputElement).files![0];

    const filesize = file ? file.size / 1024 / 1024 : 0;

    const url = URL.createObjectURL(file);

    console.log(`DEBUG User uploaded more proof: ${file.name} with size ${file.size}`);

    if (
      otherProof?.length &&
      otherProof.findIndex((item) => item.size === file.size && item.date === file.lastModified) > -1
    ) {
      return;
    }

    if (filesize >= 25) {
      setFileSizeError(true);
    }
    else {
      setOtherProof([
        ...otherProof,
        {
          file,
          url,
          name: file.name,
          size: file.size,
          date: file.lastModified,
        },
      ]);
    }
    e.target.value = '';
  };

  const submitProof = () => {
    setLoadingButton(true);
    console.log("DEBUG User clicked on 'Submit proof' button, submitting proof...");
    const files: any = [];
    if (locationPicture?.file) {
      files.push(locationPicture?.file);
    }
    if (otherProof.length !== 0) {
      otherProof.forEach((item: FileType) => files.push(item.file));
    }
    const travelerNoShowSubmited = actions?.find((item: any) => item.type === JourneyActionTypeEnum.TRAVELLER_NO_SHOW);
    const issueType = travelerNoShowSubmited
      ? JourneyActionTypeEnum.ADDITIONAL_PROOF_TRAVELLER_NO_SHOW
      : JourneyActionTypeEnum.TRAVELLER_NO_SHOW;
    createAction(journey!.id, issueType, driverPosition, (actionData: any) => {
      if (actionData?.success) {
        let uploadedCount = 0;

        files.forEach((file: any) => {
          uploadAttachment(actionData?.id, file, (res: any) => {
            if (!res?.data?.uploadAttachment?.success) {
              console.log(`INFO Uploading file ${file?.name} with size ${file?.size} failed`);
              setUploadErrorMessage(true);
              setLoadingButton(false);
            }
            else {
              console.log(`INFO Uploading file ${file?.name} with size ${file?.size} success`);
              uploadedCount += 1;
              if (uploadedCount === files.length) {
                getJourneyActions();
                setSubmitProofSuccess((prevState) => !prevState);
                setSubmitProofDrawer(false);
                setLocationPicture({
                  file: null,
                  url: '',
                  name: '',
                  size: 0,
                  date: 0,
                });
                setOtherProof([]);
                setLoadingButton(false);
              }
            }
          });
        });
      }
      else {
        setApiError(true);
      }
    });
    trackEvent({
      event: 'submitted-traveller-no-show',
      journeyCode,
      driverCode,
    });
    addAction('submitted-traveller-no-show', {
      driverCode,
      journeyCode,
    });
  };

  const deleteFile = (file: FileType) => {
    setOtherProof((prevState: any) => prevState.filter((item: FileType) => item !== file));
  };

  const openMap = () => {
    console.log('DEBUG User clicked on map');
    if (journey) {
      const originAddress = encodeURIComponent(journey.originLocation.address.originalAddress);
      const destinationAddress = encodeURIComponent(journey.destinationLocation.address.originalAddress);
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${originAddress}&destination=${destinationAddress}&travelmode=driving&dir_action=navigate`,
      );
    }
  };

  const onFlightInfoRefresh = () => {
    if (journey?.traveller.flightNumber && journey?.execution.pickupDate) {
      const hubIataCode = journey?.originLocation?.hubInfo?.details?.filter((detail) => detail.code === 'iata')[0]
        ?.value;
      setFlightInfoLoading(true);
      getFlightInfo(
        {
          flightNumber: journey?.traveller.flightNumber,
          flightDate: decodeURIComponent(journey?.execution.pickupDate),
          airportIataCode: hubIataCode,
          directionality: 'ARRIVAL',
        },
        (data) => {
          setFlightInfo({
            ...data,
            updatedOn: new Date().toISOString(),
          });
          setFlightInfoLoading(false);
        },
      );
    }
  };

  const checkJourneyStatus = (newJourney: Journey | null, allJourneys: Journey[]) => {
    const associatedJourney = allJourneys.filter(
      (cachedJourney: any) => cachedJourney.driverCode === newJourney?.driverCode,
    )[0];

    // If journey was already associated don't show add button
    if (associatedJourney) {
      setJourneyStatus(associatedJourney.status);
      console.log(`INFO Journey Status: ${associatedJourney.status}`);

      setAlreadyAssociated(true);
      setAvailableForStarting(
        associatedJourney.status === JourneyStatus.AVAILABLE || associatedJourney.status === JourneyStatus.PLANNED,
      );
    }
    // If journey wasn't started by another driver
    else if (!newJourney?.execution?.assignedDriverId) {
      setJourneyStatus(JourneyStatus.AVAILABLE);
      console.log(`INFO Journey Status: ${JourneyStatus.AVAILABLE}`);
    }
    else {
      setJourneyStatus(JourneyStatus.NO_LONGER_AVAILABLE);
      console.log(`INFO Journey Status: ${JourneyStatus.NO_LONGER_AVAILABLE}`);
    }
  };

  const getJourneyByDriverCode = (journeyDriverCode: string) => {
    getJourneyByCode(journeyDriverCode, (getJourneyData: any) => {
      if (getJourneyData?.success) {
        console.log(`INFO Journey details page for ${code} loaded`);
        if (getJourneyData?.execution?.assignedDriverId) {
          setAssignedToAnotherDriver(true);
        }

        if (getJourneyData?.driverCode) {
          // We need to check if this journey is already added to this driver
          if (cachedJourneysData?.items?.length) {
            checkJourneyStatus(getJourneyData, cachedJourneysData?.items);
            setJourney(getJourneyData);
            setFlightInfo({
              ...getJourneyData.flightInfo,
              updatedOn: new Date().toISOString(),
            });
            setLoading(false);
          }
          // If no cached journeys but access token exists, fetch associated journeys
          else if (cachedProfileData?.accessToken) {
            getAssociatedJourneys({}, (data: any) => {
              const journeysData = data?.items;

              if (journeysData?.length) {
                checkJourneyStatus(getJourneyData, journeysData);
              }

              setJourney(getJourneyData);
              setFlightInfo({
                ...getJourneyData.flightInfo,
                updatedOn: new Date().toISOString(),
              });

              setLoading(false);
            });
          }
          else {
            setJourney(getJourneyData);
            setFlightInfo({
              ...getJourneyData.flightInfo,
              updatedOn: new Date(),
            });
            // setJourneyStatus(getJourneyData.status);
            setLoading(false);
          }
        }
        trackEvent({
          event: 'journey-opened',
          driverCode: getJourneyData?.driverCode,
          journeyCode: getJourneyData?.journeyCode,
        });
        addAction('journey-opened', {
          driverCode,
          journeyCode,
        });
      }
      else {
        setApiError(true);
      }
    });
  };

  const checkStatusChange = () => {
    getAssociatedJourneys(
      {
        ...JourneyFiltersDefault,
        includedStatuses: Object.values(filterStatuses).flatMap((statusArray) => statusArray),
        textSearch: code,
      },
      (data: any) => {
        if (data?.success) {
          const allJourneys = data?.items;
          const currentJourney = allJourneys[0];
          checkJourneyStatus(currentJourney, allJourneys);
        }
      },
    );
  };

  useEffect(() => {
    if (onMyWayButtonProgress) {
      buttonTimeout = setTimeout(() => {
        if (journeyStatus === JourneyStatus.UNDERWAY_TO_PICKUP) {
          handleGetDriverLocation(handleArrivedAtPickup);
        }
        else if (journeyStatus === JourneyStatus.ARRIVED_AT_PICKUP) {
          handleGetDriverLocation(handleJourneyInProgress);
        }
        else if (journeyStatus === JourneyStatus.JOURNEY_IN_PROGRESS) {
          handleGetDriverLocation(handleArrivedAtDropOff);
        }
        else {
          handleStartJourney();
        }

        if (buttonTimeout) {
          clearTimeout(buttonTimeout);
        }
        setOnMyWayButtonProgress(false);
      }, 4200);
    }
    else if (buttonTimeout) {
      clearTimeout(buttonTimeout);
    }
  }, [onMyWayButtonProgress]);

  useEffect(() => {
    const journeyStarted =
      journeyStatus === JourneyStatus.JOURNEY_IN_PROGRESS ||
      journeyStatus === JourneyStatus.UNDERWAY_TO_PICKUP ||
      journeyStatus === JourneyStatus.ARRIVED_AT_PICKUP;

    const refDate = Date.now() - 1000 * 60 * 60 * 3;
    const date = journey?.execution?.pickupDate;

    const is24Hours = before24Hours(new Date(date!));
    if (is24Hours) setDisabledAtPickup(false);
    if (date && journeyStarted && new Date(date) < new Date(refDate)) {
      setShowIcon(true);
    }
    if (journeyStarted) {
      setTrackDriverPosition(true);
    }
  }, [journey]);

  useEffect(() => {
    if (uploadErrorMessage) {
      setTimeout(() => {
        setUploadErrorMessage(false);
        setFileSizeError(false);
        getJourneyActions();
      }, 2000);
    }
  }, [uploadErrorMessage]);

  useEffect(() => {
    const loadActions =
      journeyStatus === JourneyStatus.PLANNED ||
      journeyStatus === JourneyStatus.UNDERWAY_TO_PICKUP ||
      journeyStatus === JourneyStatus.ARRIVED_AT_PICKUP ||
      journeyStatus === JourneyStatus.JOURNEY_IN_PROGRESS ||
      journeyStatus === JourneyStatus.COMPLETED;

    if (journey?.id && loadActions) {
      getJourneyActions();
    }
  }, [journey, journeyStatus]);

  useEffect(() => {
    console.log(`INFO Loading journey details page for journey ${code}...`);

    const getConfig = async () => {
      const response = await fetch(`${baseURL}/configs`);
      const configs: any = await response.json();
      setGoogleApiKey(configs.googleApiKey);
    };

    // When adding a journey from overview page show success notification
    if (location?.state?.notification) {
      window.history.replaceState({}, document.title);

      if (location.state.notification === 'addJourneySuccess') {
        setJourneyAdded(true);
        setAlreadyAssociated(true);
        setAvailableForStarting(true);

        setTimeout(() => {
          setJourneyAdded(false);
        }, 2000);
      }
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // success callback
        getPhone,
        // error callback (position will be undefined)
        getPhone,
      );
    }

    getConfig();
  }, []);

  useEffect(() => {
    if (code) {
      // When URL query param is driver code
      if (code.length >= 6) {
        // Check if driver code is valid
        getJourneyByDriverCode(code);

        const intervalFunction = () => {
          console.log('DEBUG Checking for journey status change...');
          checkStatusChange();
        };
        const intervalID = setInterval(intervalFunction, 60000);
        return () => {
          clearInterval(intervalID);
        };
      }
      // When other routes mistakenly get appended as a driver code redirect to them

      console.log('INFO JourneyPage page > route got appended as a query param by mistake - ', code);
      navigate(`/${code}`);
    }
  }, [code]);

  if (loading) {
    return <Loader text={translate('please_wait')} fullScreen />;
  }

  return (
    <div className={`${classBase} m-b-60`} data-testid={classBase}>
      {loadingStatus && <Loader text={translate('please_wait')} fullScreen />}
      <div className="d-flex justify-content-space-between align-items-center sticky-bar">
        <Button
          label={translate('overview')}
          priority="tertiary"
          icon={<ChevronIcon direction="left" height={15} />}
          iconPosition="left"
          textColor="asphalt6"
          onClick={() => {
            console.log('DEBUG User clicked on "Overview" button, navigating to journeys overview page...');
            navigate('/journeys');
          }}
          className="back-button"
        />

        <ProgressBarStatusButton
          journeyStatus={journeyStatus}
          assignedToAnotherDriver={assignedToAnotherDriver}
          setShowProgresBarStatus={() => setShowProgresBarStatus(!showProgresBarStatus)}
        />
        {showProgresBarStatus && (
          <ProgressBarStatus journeyStatus={journeyStatus} assignedToAnotherDriver={assignedToAnotherDriver} />
        )}
      </div>

      <div onClick={openMap} className="map-wrapper">
        <GeoDirection
          apiKey={googleApiKey}
          width="auto"
          height="175px"
          origin={{
            lat: Number(journey?.originLocation.position.lat) || 0,
            lng: Number(journey?.originLocation.position.lng) || 0,
          }}
          destination={{
            lat: Number(journey?.destinationLocation.position.lat) || 0,
            lng: Number(journey?.destinationLocation.position.lng) || 0,
          }}
          options={{
            disableDefaultUI: true,
            draggable: false,
            keyboardShortcuts: false,
          }}
          className="journey-info-map pointer"
          // timeStamp={123}
        />
        {journey?.execution?.durationInSeconds !== 0 && (
          <div className="time-box bg-color-blue5 light d-flex justify-content-center align-items-center">
            <TimerIcon width={18} color="light" direction="right" />
            <span className="m-l-11">
              {`${Number(Number(journey?.execution?.durationInSeconds) / 60).toPrecision(2)} min`}
            </span>
          </div>
        )}
      </div>

      {journey && (
        <JourneyInfoBox
          driverCode={splittedDriverCode}
          status={journeyStatus}
          setJourneyIssuesDrawer={() => {
            console.log('DEBUG User clicked on "Journey Issues" button, opening journey issues drawer...');
            setJourneyIssuesDrawer(true);
          }}
          traveller={journey.traveller}
          addons={journey.addons || []}
          actions={actions}
          dangerIcon={showIcon}
          availableForStarting={alreadyAssociated}
        />
      )}

      {journey ? (
        <div>
          <Typography variant="h4" className="m-b-16 m-t-24">
            {translate('journey_info')}
          </Typography>

          <a
            style={{ display: 'none' }}
            ref={callLinkRef}
            href={`tel:${journey.traveller?.phone ?? journey.traveller?.phone}`}
          >
            .
          </a>

          <a
            style={{ display: 'none' }}
            ref={smsLinkRef}
            href={`sms:${journey.traveller?.phone ?? journey.traveller?.phone}`}
          >
            .
          </a>

          <a style={{ display: 'none' }} ref={customerServiceLinkRef} href={`tel:${customerServicePhone}`}>
            .
          </a>

          <a
            style={{ display: 'none' }}
            ref={transferCompanyLinkRef}
            href={`tel:${journey?.transferCompany?.supportPhone}`}
          >
            .
          </a>

          {flags?.showFlightTracker && journey.traveller.flightNumber && (
            <FlightTracker
              status={flightInfo?.status || FlightStatusEnum.UNKNOWN}
              delay={flightInfo?.delay}
              estimatedTime={flightInfo?.estimatedTime as string}
              scheduledTime={flightInfo?.scheduledTime as string}
              flightNumber={journey.traveller?.flightNumber}
              updatedOn={flightInfo?.updatedOn as string}
              airline={flightInfo?.airline}
              city={flightInfo?.city}
              onRefresh={onFlightInfoRefresh}
              className="m-b-16"
              isRefreshLoading={flightInfoLoading}
              card
            />
          )}

          {journey?.traveller?.shipName && (
            <Notification
              variant="neutral"
              message={journey?.traveller?.shipName}
              closeIcon={false}
              className="custom-notification-white"
              icon={<ShipIcon width={18} />}
            />
          )}

          <JourneyInfo journey={journey} status={journeyStatus || JourneyStatus.PLANNED} />

          {!alreadyAssociated && !assignedToAnotherDriver && (
            <div className="button-wrapper-bottom position-fixed p-16">
              <Button
                label={translate('add_to_journeys')}
                priority="primary"
                variant="confirm"
                fullWidth
                icon={<CircleXIcon className="rotate-45" />}
                iconPosition="left"
                onClick={handleAddToMyJourney}
              />
            </div>
          )}
          {/*
           * Driver added a journey, no other driver started it yet
           * So this driver can start a journey
           */}
          {alreadyAssociated && availableForStarting && journeyStatus !== 'NO_LONGER_AVAILABLE' && (
            <div className="button-wrapper-bottom position-fixed p-16">
              {journeyAdded && (
                <div>
                  <Notification
                    variant="success"
                    message={translate('added_journeys')}
                    className="d-flex justify-content-center m-b-16"
                  />
                </div>
              )}
              <Button
                label={translate('on_my_way_to_pickup')}
                priority="primary"
                variant="confirm"
                fullWidth
                icon={<CarRightIcon fill="light" />}
                iconPosition="left"
                onClick={() => {
                  console.log('DEBUG User clicked "On my way to pickup" button');
                  if (disabledAtPickup) {
                    console.log('INFO User can not start journey');
                    setCanNotStartJourneyDrawer(true);
                    return;
                  }
                  setTrackDriverPosition(true);
                  console.log('INFO Starting journey, loading driver information page...');
                  setOnMyWayButtonProgress(!onMyWayButtonProgress);
                }}
                progress={onMyWayButtonProgress}
              />
            </div>
          )}
          {journeyStatus === JourneyStatus.UNDERWAY_TO_PICKUP && (
            <div className="button-wrapper-bottom position-fixed p-16">
              <Button
                label={translate('arrived_at_pickup')}
                priority="primary"
                variant="confirm"
                fullWidth
                icon={<LocationIcon fill="light" />}
                iconPosition="left"
                onClick={() => {
                  console.log('DEBUG User clicked "Arrived at pickup" button');
                  setOnMyWayButtonProgress(!onMyWayButtonProgress);
                }}
                progress={onMyWayButtonProgress}
                // Check if we can use the same button
              />
            </div>
          )}
          {journeyStatus === JourneyStatus.ARRIVED_AT_PICKUP && (
            <div className="button-wrapper-bottom position-fixed p-16">
              <Button
                label={translate('on_my_way_to_drop_off')}
                priority="primary"
                variant="confirm"
                fullWidth
                icon={<CarRightIcon fill="light" />}
                iconPosition="left"
                onClick={() => {
                  console.log('DEBUG User clicked "On my way to drop off" button');
                  setOnMyWayButtonProgress(!onMyWayButtonProgress);
                }}
                progress={onMyWayButtonProgress}
                // Check if we can use the same button
              />
            </div>
          )}
          {journeyStatus === JourneyStatus.JOURNEY_IN_PROGRESS && (
            <div className="button-wrapper-bottom position-fixed p-16">
              <Button
                label={translate('arrived_at_drop_off')}
                priority="primary"
                variant="confirm"
                fullWidth
                icon={<CircleCheckIcon fill="light" />}
                iconPosition="left"
                onClick={() => {
                  console.log('DEBUG User clicked "Arrived at drop off" button');
                  setOnMyWayButtonProgress(!onMyWayButtonProgress);
                }}
                progress={onMyWayButtonProgress}
                // Check if we can use the same button
              />
            </div>
          )}
          {completedMessage && (
            <div className="journey-completed-notification position-fixed p-16">
              <Notification
                variant="success"
                message={translate('journey_completed')}
                className="d-flex justify-content-center"
              />
            </div>
          )}
          {completedError && (
            <div className="journey-completed-notification error position-fixed p-16">
              <Notification variant="error" message={translate('error')} />
            </div>
          )}
          {completedMessage && (
            <div className="journey-completed-notification position-fixed p-16">
              <Notification
                variant="success"
                message={translate('success')}
                className="d-flex justify-content-center"
              />
            </div>
          )}
          {uploadErrorMessage ||
            (fileSizeError && (
              <div className="journey-completed-notification upload-error position-fixed p-16">
                <Notification
                  variant="error"
                  message={translate('n_errorProofNoShowImage')}
                  className="d-flex justify-content-center"
                  onClose={() => {
                    setUploadErrorMessage(false);
                    setFileSizeError(false);
                  }}
                />
              </div>
            ))}

          <BottomDrawer
            isOpen={completeJourneyDrawer}
            title={translate('complite_journey')}
            text={translate('bottomDrawer_text')}
            onClose={() => setCompleteJourneyDrawer(false)}
          >
            <>
              <Button
                label={translate('arrived_at_drop_off')}
                priority="primary"
                variant="info"
                icon={<CircleCheckIcon fill="light" height={22} />}
                iconPosition="left"
                fullWidth
                className="m-b-32"
                onClick={confirmArrivedAtDropOff}
              />
              <Button
                label={translate('go_back')}
                priority="secondary"
                variant="info"
                icon={<ChevronIcon direction="left" fill="asphalt7" height={14} />}
                iconPosition="left"
                fullWidth
                onClick={() => setCompleteJourneyDrawer(false)}
                className="m-b-10"
              />
            </>
          </BottomDrawer>
          <BottomDrawer
            isOpen={journeyIssuesDrawer}
            title={translate('what_is_wrong')}
            onClose={() => setJourneyIssuesDrawer(false)}
          >
            <PickupIssues
              status={journeyStatus!}
              openSingleIssue={openSingleIssueDrawer}
              callCustomerService={callCustomerService}
              openSuccessPage={() => {
                getJourneyActions();
                setJourneyIssuesDrawer(false);
                setSubmitProofSuccess(true);
              }}
              actions={actions}
            />
          </BottomDrawer>

          <SingleIssueDrawer
            issue={singleIssueDrawer}
            isOpen={!!singleIssueDrawer}
            onClose={() => {
              setSingleIssueDrawer(null);
            }}
            goBack={() => {
              setSingleIssueDrawer(null);
              setJourneyIssuesDrawer(true);
            }}
            callTransferCompany={callTransferCompany}
            callCustomerService={callCustomerService}
            openSubmitProofDrawer={openSubmitProofDrawer}
            journeyId={journey.id}
            getActions={getJourneyActions}
            actions={actions}
            journeyCode={journeyCode}
            driverCode={driverCode}
          />
          <BottomDrawer
            isOpen={submitProofDrawer}
            title={translate('submit_proof')}
            text={translate('gps_note')}
            onClose={() => setSubmitProofDrawer(false)}
            goBack={() => {
              setSubmitProofDrawer(false);
              openSingleIssueDrawer('cannot_find_traveller');
            }}
          >
            <>
              {!locationPicture ? (
                <FileUpload
                  text={translate('take_location_picture')}
                  onChange={uploadLocationPhoto}
                  icon={<CameraIcon color="blue5" height={17} width={20} />}
                />
              ) : (
                <ImageUpload
                  onChange={uploadLocationPhoto}
                  title={translate('location_picture')}
                  newPhotoText={translate('retake_picture')}
                  className="retake-picture"
                  photo={locationPicture.url}
                />
              )}
              {/* <JourneyLoacationPicture locationPicture={locationPicture} handleUploadFile={() => handleUploadFileMine} /> */}
              <div className="m-v-24">
                <Typography
                  variant={otherProof?.length ? 'caption' : 'body1'}
                  color={otherProof?.length ? 'asphalt6' : 'dark'}
                >
                  {otherProof?.length ? translate('additional_proof') : translate('other_proof_text')}
                </Typography>
                <div className="m-t-24">
                  <FileUploadSecond
                    text={otherProof?.length ? translate('add_more_files') : translate('upload_other_proof')}
                    onChange={uploadMoreProof}
                    icon={<CirclePlusIcon color="blue5" height={17} width={20} />}
                  />
                </div>
                {!!otherProof.length && (
                  <div className="m-t-24">
                    {otherProof.map((item: FileType, index: number) => (
                      <div key={index}>
                        <FilePreview file={`${item.name}`} deleteFile={() => deleteFile(item)} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <Button
                label={translate('submit_no_show_proof')}
                priority="primary"
                variant="info"
                icon={loadingButton ? <Loader spinner height="22" /> : <CircleCheckIcon fill="light" height={22} />}
                iconPosition="left"
                fullWidth
                data-testid="submit_no-show-proof"
                disabled={(!locationPicture && !otherProof.length) || fileSizeError}
                onClick={submitProof}
              />
            </>
          </BottomDrawer>
          <BottomDrawer
            isOpen={submitProofSuccess}
            title={translate('submit_proof_success')}
            text={translate('submit_proof_success_message')}
            onClose={() => setSubmitProofSuccess(false)}
          >
            {otherProof.length === 0 && (
              <div>
                <Button
                  label={translate('go_back')}
                  priority="primary"
                  variant="info"
                  icon={<ChevronIcon direction="left" fill="light" height={22} />}
                  iconPosition="left"
                  fullWidth
                  onClick={() => setSubmitProofSuccess(false)}
                />
              </div>
            )}
            <div className="m-t-24">
              <Typography variant="subtitle1">{translate('submit_more_proof_question')}</Typography>
            </div>
            <div className="m-t-24">
              <FileUploadSecond
                text={translate('add_additional_proof_button')}
                onChange={uploadMoreProof}
                icon={<CirclePlusIcon color="blue5" height={17} width={20} />}
              />
            </div>
            {!!otherProof.length &&
              ((
                <div className="m-t-24">
                  {otherProof.map((item: FileType, index: number) => (
                    <div key={index}>
                      <FilePreview file={`${item.name}`} deleteFile={() => deleteFile(item)} />
                    </div>
                  ))}
                </div>
              ) as any)}
            {!!otherProof.length && (
              <div className="m-t-24">
                <Button
                  label={translate('submit_no_show_proof')}
                  priority="primary"
                  variant="info"
                  icon={<CircleCheckIcon fill="light" height={22} />}
                  iconPosition="left"
                  fullWidth
                  disabled={!locationPicture && !otherProof.length}
                  onClick={submitProof}
                />
              </div>
            )}
          </BottomDrawer>
          <BottomDrawer
            isOpen={canNotStartJourneyDrawer}
            title={translate('journey_in_future_title')}
            text={translate('journey_in_future_text')}
            onClose={() => setCanNotStartJourneyDrawer(false)}
          >
            <Button
              label={translate('journey_in_future_button')}
              priority="primary"
              variant="info"
              icon={<CircleCheckIcon fill="light" height={22} />}
              iconPosition="left"
              fullWidth
              onClick={() => setCanNotStartJourneyDrawer(false)}
            />
          </BottomDrawer>
        </div>
      ) : (
        <div>
          <Typography variant="h4">{translate('Oops')}</Typography>
          <Typography variant="body1">{translate('info_not_available')}</Typography>
        </div>
      )}
    </div>
  );
};

export default withLDConsumer()(JourneyPage);
