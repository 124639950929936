import '@transferz/fe-component-library/style';

import CalendarNewIcon from '@transferz/fe-component-library/calendar-new-icon';
import ChevronIcon from '@transferz/fe-component-library/chevron-icon';
import ClockIcon from '@transferz/fe-component-library/clock-icon';
import Divider from '@transferz/fe-component-library/divider';
import { FlightStatusEnum, FlightTracker } from '@transferz/fe-component-library/flight-tracker';
import LocationIcon from '@transferz/fe-component-library/location-icon';
import Notification from '@transferz/fe-component-library/notification';
import PlaneIcon from '@transferz/fe-component-library/plane-icon';
import PointOfInterestIcon from '@transferz/fe-component-library/point-of-interest-icon';
import SeaportIcon from '@transferz/fe-component-library/seaport-icon';
import ShipIcon from '@transferz/fe-component-library/ship-icon';
import TrainIcon from '@transferz/fe-component-library/train-icon';
import TrashIcon from '@transferz/fe-component-library/trash-icon';
import Typography from '@transferz/fe-component-library/typography';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useLocalize } from 'localize-react';
import React, { useEffect, useState } from 'react';

import { JourneyStatus } from '../types/journeyStatus';
import { parseDate, tomorrowOrToday } from '../utils/parsingFunctions';
import JourneyHeaderBox from './Journey/JourneyHeaderBox';
import JourneyInfoAddons from './Journey/JourneyInfoAddons';
import JourneyInfoDetail from './Journey/JourneyInfoDetail';

interface JourneyOverviewBoxProps {
  onClick?: () => void;
  handleDelete: () => any;
  driverCode: string;
  pickupDate?: string;
  startLocation?: string;
  endLocation?: string;
  status: JourneyStatus;
  startLocationHub: string;
  endLocationHub: string;
  addons?: any[];
  flightNumber: string;
  estimatedTime: string;
  scheduledTime: string;
  delay?: number;
  flightStatus: FlightStatusEnum;
  flags?: any;
  shipName?: string;
}

const JourneyOverviewBox = ({
  onClick,
  handleDelete,
  driverCode,
  pickupDate,
  startLocation,
  endLocation,
  status,
  startLocationHub,
  endLocationHub,
  addons = [],
  flightNumber,
  estimatedTime,
  scheduledTime,
  delay,
  flightStatus,
  flags,
  shipName,
}: JourneyOverviewBoxProps) => {
  const parts: RegExpMatchArray | null = driverCode?.match(/.{1,3}/g);
  const splittedDriverCode = parts && parts.join(' ');
  const { translate } = useLocalize();
  const [showIcon, setShowIcon] = useState<boolean>(false);

  const journeyDate = new Date(pickupDate!);
  const todayOrTomorrow = tomorrowOrToday(journeyDate);

  const date = todayOrTomorrow ? translate(todayOrTomorrow) : pickupDate && parseDate(pickupDate);
  const time = pickupDate && pickupDate.split('T')[1].slice(0, -3);

  const isCancelled =
    status === JourneyStatus.CANCELLED ||
    status === JourneyStatus.NO_LONGER_AVAILABLE ||
    status === JourneyStatus.REASSIGNED_DRIVER ||
    status === JourneyStatus.REASSIGNED_TRANSFER_COMPANY;
  const isCompleted = status === JourneyStatus.COMPLETED;

  const canDelete =
    isCancelled || isCompleted || status === JourneyStatus.AVAILABLE || status === JourneyStatus.PLANNED;

  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'AIRPORT':
        return <PlaneIcon width={24} direction="right" color="asphalt7" />;
      case 'SEAPORT':
        return <SeaportIcon width={21} />;
      case 'TRAIN_STATION':
        return <TrainIcon width={25} />;
      case 'POINT_OF_INTEREST':
        return <PointOfInterestIcon width={25} />;
      default:
        return <LocationIcon width={21} />;
    }
  };

  useEffect(() => {
    const journeyStarted =
      status === JourneyStatus.JOURNEY_IN_PROGRESS ||
      status === JourneyStatus.UNDERWAY_TO_PICKUP ||
      status === JourneyStatus.ARRIVED_AT_PICKUP;

    const refDate = Date.now() - 1000 * 60 * 60 * 3;

    if (journeyStarted && new Date(pickupDate!) < new Date(refDate)) {
      setShowIcon(true);
    }
  }, [status]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      className={`journey-overview-box journey-info-box ${isCancelled && 'disabled'}`}
      onClick={onClick}
      role="listitem"
    >
      <div className="top-wrapper">
        <JourneyHeaderBox status={status} dangerIcon={showIcon} />
      </div>
      <div className="main-wrapper">
        <div className="name-icon">
          <Typography
            variant="h5"
            color={isCancelled ? 'asphalt7' : 'blue5'}
            className="uppercase d-flex align-items-center"
            data-testid="journey_driver-code"
          >
            {splittedDriverCode}
            {isCancelled && <ChevronIcon width={14} className="m-l-8" color="blue5" />}
          </Typography>
          {canDelete && (
            <div
              className="trash-button d-flex align-center justify-center pointer"
              data-testid="journey_delete-button"
              onClick={(ev: any) => {
                ev.stopPropagation();
                handleDelete();
              }}
            >
              <TrashIcon color="asphalt7" width={18} />
            </div>
          )}
        </div>

        {flags?.showFlightTracker && flightNumber && (
          <FlightTracker
            // @ts-ignore
            status={flightStatus || FlightStatusEnum.UNKNOWN}
            delay={delay}
            estimatedTime={estimatedTime}
            scheduledTime={scheduledTime}
            flightNumber={flightNumber}
            updatedOn=""
            card={false}
          />
        )}

        {shipName && (
          <Notification
            variant="neutral"
            message={shipName}
            closeIcon={false}
            icon={<ShipIcon width={18} color="blue5" />}
          />
        )}

        <div data-testid="journey_from-location" className="from-location">
          <span className="d-flex align-center margin-right-8">{renderIcon(startLocationHub)}</span>
          <Typography className="elipsis f-w-400" variant="subtitle1">
            {startLocation}
          </Typography>
        </div>
        <div data-testid="journey_to-location" className="to-location">
          <span className="margin-right-8 d-flex align-center">{renderIcon(endLocationHub)}</span>
          <Typography className="elipsis f-w-400" variant="subtitle1">
            {endLocation}
          </Typography>
        </div>
        <div className="d-flex flex-direction-row justify-content-space-between m-l-4 m-t-16 m-b-16">
          <JourneyInfoDetail
            title={date!}
            icon={<CalendarNewIcon width={24} />}
            className="w-6"
            dataTestId="journey_date"
          />

          <JourneyInfoDetail
            title={time!}
            icon={<ClockIcon width={24} className="top" />}
            className="w-6"
            dataTestId="journey_time"
          />
        </div>
        {addons.length > 0 && <Divider />}
        {addons.length > 0 && <JourneyInfoAddons className="m-t-16 m-l-4 none-plus m-b-0" addons={addons} />}
      </div>
    </div>
  );
};

export default withLDConsumer()(JourneyOverviewBox);
