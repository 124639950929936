import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@transferz/fe-component-library/button';
import Input from '@transferz/fe-component-library/input';
import Loader from '@transferz/fe-component-library/loader';
import Notification from '@transferz/fe-component-library/notification';
import PhoneIcon from '@transferz/fe-component-library/phone-icon';
import DialPhoneDropdown from '@transferz/fe-component-library/phone-input';
import Typography from '@transferz/fe-component-library/typography';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useLocalize } from 'localize-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { GetCacheProfile } from '../models/graphql';
import { useQueryCaptchaToken } from '../queries/captcha/hooks/captcha/useGetCaptchaToken';
import { useQueryIPAddressInfo } from '../queries/geo/hooks/ip/useQueryIPAddressInfo';
import { useSetCacheProfile } from '../services/apolloCacheHooks';
import { useAssociateJourney, useLoginEmail, useSendOTP } from '../services/apolloNetworkHooks';
import { routes } from '../services/constant';

interface Props {
  loginType: string;
  flags?: any;
}

type FormFields = {
  email: string;
  password: string;
  phone: string;
};

const Login: (props: Props) => ReactElement = function ({ loginType, flags }) {
  const classBase = 'login-page';
  const [error, setError] = useState<string>('');
  const [captchaRequired, setCaptchaRequired] = useState<boolean>(false);
  const [loginAttempted, setLoginAttempted] = useState<boolean>(true);
  const [captchaError, setCaptchaError] = useState<boolean>(false);
  const [rateLimitError, setRateLimitError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [captchaKey, setCaptchaKey] = React.useState<string>('');
  const setCacheProfile = useSetCacheProfile();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const associateJourney = useAssociateJourney();
  const loginWithEmail = useLoginEmail();
  const sendOTP = useSendOTP();
  const getCaptchaToken = useQueryCaptchaToken();
  const { translate } = useLocalize();
  const { data: ipAddressData, loading: IPInfoLoading } = useQueryIPAddressInfo();

  const validations = yup.object().shape(
    loginType === 'email'
      ? {
        email: yup.string().email(translate('must_be_valide')).required(translate('email_is_required')),
        password: yup.string().required(translate('password_is_required')),
      }
      : {
        phone: yup.string().optional(),
      },
  );
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({ resolver: yupResolver(validations) });

  const passwordValue = watch('password');
  const emailValue = watch('email');

  const [phoneNumber, setPhoneNumber] = React.useState('');
  const navigate = useNavigate();

  const handleLoginSuccess = (): void => {
    setCaptchaRequired(false);
    setLoginAttempted(true);
    setCaptchaError(false);
    setRateLimitError(false);
    navigate(routes.login.pin);
  };

  const handleCaptchaIsRequiredError = (): void => {
    setCaptchaRequired(true);
    if (!captchaKey) {
      getCaptchaToken((token: any) => {
        setCaptchaKey(token?.token);
      });
    }
    setLoginAttempted(false);
    setCaptchaError(false);
    setRateLimitError(false);
  };

  const handleCaptchaIsIncorrectError = (): void => {
    setCaptchaError(true);
    setRateLimitError(false);
  };

  const handleRateLimitError = (): void => {
    setCaptchaError(false);
    setRateLimitError(true);
  };

  const requestOTP = (captchaToken?: string) => {
    setLoading(true);
    setLoginAttempted(true);
    sendOTP({ phoneNumber, captchaToken }, (data) => {
      if (data?.success) {
        handleLoginSuccess();
      }
      else {
        switch (data.errorCode) {
          case 'CAPTCHA_CHALLENGE_REQUIRED':
            handleCaptchaIsRequiredError();
            break;
          case 'CAPTCHA_CHALLENGE_INCORRECT':
            handleCaptchaIsIncorrectError();
            break;
          case 'RATE_LIMIT_EXCEEDED':
            handleRateLimitError();
            break;
          default:
            // do nothing
        }
      }

      setLoading(false);
    });
  };

  const onSubmit: SubmitHandler<FormFields> = ({ email, password }) => {
    console.log(`DEBUG User clicked on ${loginType === 'email' ? translate('log_in') : translate('send_sms')} button`);
    if (loginType === 'email') {
      setLoading(true);

      loginWithEmail(email, password, (driverData: any) => {
        if (driverData?.success) {
          const code = cachedProfileData?.lastJourneyView;

          // When logging in check if user clicked on add to my journeys
          // and upon logging associate that journey
          if (code) {
            associateJourney(
              code,
              () => {
                setCacheProfile({
                  lastJourneyView: '',
                }).then(() => {
                  navigate(`${routes.journey}/${code}`);
                });
              },
              driverData,
              true,
            );
          }
          else {
            navigate(routes.journeys);
          }
        }
        else {
          setError(translate('wrong_email_password'));
        }

        setLoading(false);
      });
    }
    else if (phoneNumber) {
      // Enter phone and redirect to driver name page
      setCacheProfile({
        driverId: 0,
        phone: phoneNumber,
      }).then(() => {
        console.log('INFO Sending OTP password, Loading Pin Screen...');
        requestOTP();
      });
    }
  };

  const handleInputFocus = () => {
    setError('');
  };

  useEffect(() => {
    console.log('INFO Login page loaded');
  }, []);

  if (loading || IPInfoLoading) {
    return <Loader text={translate('please_wait')} fullScreen />;
  }

  return (
    <div className={`${classBase} d-flex flex-column m-b-80`} data-testid={classBase}>
      <div className="w-9">
        <Typography variant="h4">{translate('login_title')}</Typography>
      </div>
      <div className="w-10 m-t-16">
        <Typography variant="body1">{translate('login_subtitle')}</Typography>
      </div>
      <div className="w-10 m-t-32">
        <Typography variant="h5">
          {loginType === 'email' ? translate('email_login') : translate('phone_login')}
        </Typography>
      </div>
      <form className="flex-grow-1 d-flex flex-column" onSubmit={handleSubmit(onSubmit)} data-testid="form">
        {loginType === 'email' && flags.emailLogin && (
          <div className="w-12 m-t-32">
            <div>
              <Input
                {...register('email')}
                hasValue={Boolean(watch('email'))}
                type="text"
                fullWidth
                label={translate('email')}
                placeholder={translate('email')}
                defaultValue={emailValue}
                error={errors?.email?.message}
                onFocus={handleInputFocus}
              />
            </div>

            <div className="m-t-30">
              <Input
                {...register('password')}
                hasValue={Boolean(watch('password'))}
                type="password"
                fullWidth
                label={translate('password')}
                placeholder={translate('password')}
                defaultValue={passwordValue}
                error={errors?.password?.message}
                onFocus={handleInputFocus}
              />
            </div>
          </div>
        )}

        <div className="w-12 m-t-32">
          {captchaRequired && captchaKey?.length && (
            <GoogleReCaptchaProvider
              reCaptchaKey={captchaKey}
            >
              <GoogleReCaptcha
                onVerify={(token: string) => {
                  if (!loginAttempted && phoneNumber) {
                    requestOTP(token);
                  }
                }}
              />
            </GoogleReCaptchaProvider>
          )}
        </div>

        {loginType === 'phone' && (
          <div className="w-12 m-t-32">
            <DialPhoneDropdown
              onChange={(phone: string) => setPhoneNumber(phone)}
              label={translate('mobile_number')}
              initialValue={ipAddressData?.ipAddressInfo?.countryPhonePrefix || ''}
              className="custom-phone-input"
              path={`${window.location.origin}/flags/4x3`}
            />
          </div>
        )}
        {flags.emailLogin && (
          <div className="d-flex link m-t-32 align-items-center">
            <Typography variant="body1">
              {loginType === 'email' ? translate('login_phone') : translate('login_email')}
              <a
                className="m-l-5"
                onClick={() => {
                  navigate(loginType === 'email' ? routes.login.phone : routes.login.email);
                }}
              >
                {translate('click')}
              </a>
            </Typography>
          </div>
        )}

        {!!error && (
          <div className="m-v-10">
            <Notification variant="error" message={error} />
          </div>
        )}
        {captchaError && (
          <div className="m-v-10">
            <Notification variant="error" message={translate('incorrect_captcha_error')} />
          </div>
        )}
        {rateLimitError && (
          <div className="m-v-10">
            <Notification variant="error" message={translate('rate_limit_error')} />
          </div>
        )}
        <div className="button-wrapper-bottom p-fixed bottom-0 left-0 p-16 w-12 b-r-20">
          <Button
            label={loginType === 'email' ? translate('log_in') : translate('send_sms')}
            priority="primary"
            fullWidth
            variant="confirm"
            disabled={loginType === translate('phone') && !phoneNumber}
            icon={loginType === translate('phone') ? <PhoneIcon height={20} /> : undefined}
            iconPosition="left"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default withLDConsumer()(Login);
