import { useLazyQuery, useQuery } from '@apollo/client';

import {
  GetCacheProfile,
  loginWithPhoneQuery,
} from '../../models/graphql';
import { standardizePhoneNumber } from '../../utils/parsingFunctions';
import { useSetCacheProfile } from '../apolloCacheHooks';

export const useLoginPhone = () => {
  const setCacheProfile = useSetCacheProfile();
  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const [
    login,
    {
      refetch: loginRefetch,
      called,
    },
  ] = useLazyQuery(
    loginWithPhoneQuery,
  );

  const sessionToken = cachedProfileData?.token;

  const handleNewData = async (
    res: any,
    callback: (data: any) => void,
  ) => {
    const driverData = { ...res?.data?.authPhone };

    driverData.id = driverData.userId;

    delete driverData.userId;
    delete driverData.updated;

    if (driverData.success) {
      await setCacheProfile(driverData);
    }

    if (callback) callback(driverData);
  };

  return (
    phone: string,
    otp: string,
    callback: (data: any) => void,
  ) => {
    if (called) {
      loginRefetch({
        otp,
        phone: standardizePhoneNumber(phone),
        sessionToken,
      }).then((res) => {
        handleNewData(res, callback);
      });
    }
    else {
      login({
        variables: {
          otp,
          phone: standardizePhoneNumber(phone),
          sessionToken,
        },
      }).then((res) => {
        handleNewData(res, callback);
      });
    }
  };
};
