import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import BottomDrawer from '@transferz/fe-component-library/bottom-drawer';
import { Button } from '@transferz/fe-component-library/button';
import CarIcon from '@transferz/fe-component-library/car-icon';
import Dropdown from '@transferz/fe-component-library/dropdown';
import Input from '@transferz/fe-component-library/input';
import Loader from '@transferz/fe-component-library/loader';
import TrashIcon from '@transferz/fe-component-library/trash-icon';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import * as yup from 'yup';

import StickyBackButton from '../components/StickyBackButton';
import { CacheVehicleMake } from '../models/CacheVehicleMakes';
import { GetCacheProfile, GetCacheVehicleMakes } from '../models/graphql';
import {
  useAddVehicle,
  useDeleteVehicle,
  useEditVehicle,
  useGetVehicleMakes,
  useGetVehicleSeries,
} from '../services/apolloNetworkHooks';
import { IData } from './DriverInformation';

interface VehicleProps {
  vehicleType: string;
}

const VehiclePage = ({ vehicleType }: VehicleProps) => {
  const navigate = useNavigate();
  const { translate } = useLocalize();
  const location: any = useLocation();
  const prevRoute = location?.state?.route;
  const vehicleParamMakeName = location?.state?.carBrand;
  const vehicleParamSerieName = location?.state?.carType;

  const [deleteBottomDrawer, setDeleteBottomDrawer] = useState(false);
  const [carMakes, setCarMakes] = useState<IData[]>([]);
  const [carSeriesDropdown, setCarSeriesDropdown] = useState<IData[]>([]);

  const { data: cachedVehicleMakes } = useQuery(GetCacheVehicleMakes);
  const { data: cachedProfileData } = useQuery(GetCacheProfile);

  const getVehicleMakes = useGetVehicleMakes();
  const getVehicleSeries = useGetVehicleSeries();
  const deleteVehicle = useDeleteVehicle();
  const addVehicle = useAddVehicle();
  const editVehicle = useEditVehicle();

  const validations = yup.object().shape({
    license: yup.string().required(translate('field_required')),
    carBrand: yup.number().required(translate('field_required')),
    carType: yup.number().required(translate('field_required')),
  });

  const {
    register, handleSubmit, setValue, control, watch, reset,
  } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      license: location?.state?.license,
      carBrand: 0,
      carType: 0,
    },
  });

  const licenseValue = watch('license');
  const carBrandValue = watch('carBrand');
  const carTypeValue = watch('carType');

  const handleDeleteVehicleById = () => {
    console.log('DEBUG User clicked on delete vehicle button');
    deleteVehicle(location?.state?.vehicleID, () => {
      console.log('INFO Vehicle deleted');
      navigate(`/${prevRoute}`, { state: { notification: 'delete' } });
    });
  };

  const handleAddOrUpdateVehicle = () => {
    console.log('DEBUG User clicked on add or update vehicle button');
    if (vehicleType === 'update-car') {
      editVehicle(location?.state?.vehicleID, carTypeValue, licenseValue, () => {
        console.log('INFO Vehicle updated');
        navigate(`/${prevRoute}`, { state: { notification: 'update' } });
      });
    }
    else if (vehicleType === 'new-car') {
      addVehicle(cachedProfileData?.driverId, carTypeValue, licenseValue, () => {
        console.log('INFO Vehicle added');
        navigate(`/${prevRoute}`, { state: { notification: 'create' } });
      });
    }
  };

  useEffect(() => {
    if (carBrandValue === 0) return;

    if (!carTypeValue && vehicleParamSerieName && carSeriesDropdown?.length && vehicleType === 'update-car') {
      const vehicleSerieId = carSeriesDropdown.filter((serie: any) => serie.label === vehicleParamSerieName)[0];

      if (vehicleSerieId?.value) setValue('carType', vehicleSerieId.value);
    }
    else {
      getVehicleSeries(carBrandValue, (series: any) => {
        if (series) {
          setCarSeriesDropdown(series);

          setValue('carType', 0);
        }
        else {
          console.log('Something went wrong');
        }
      });
    }
  }, [carBrandValue]);

  useEffect(() => {
    if (!carBrandValue && vehicleParamMakeName && carMakes?.length && vehicleType === 'update-car') {
      const vehicleMakeId = carMakes.filter((make: any) => make.label === vehicleParamMakeName)[0];

      if (vehicleMakeId?.value) {
        getVehicleSeries(vehicleMakeId.value, (series: any) => {
          if (series) {
            setCarSeriesDropdown(series);

            setValue('carBrand', vehicleMakeId.value);
          }
          else {
            console.log('Something went wrong');
          }
        });
      }
    }
  }, [carMakes]);

  useEffect(() => {
    console.log('INFO Vehicle page loaded');
    // If no cached vehicle makes, fetch them all for a dropdown
    if (!cachedVehicleMakes?.makes?.length) {
      getVehicleMakes((vehicleMakes: CacheVehicleMake[]) => {
        if (vehicleMakes?.length) {
          setCarMakes(vehicleMakes);
        }
        else {
          // @TODO write some kind of an error to the user
        }
      });
    }
    else {
      setCarMakes(cachedVehicleMakes.makes);
    }
  }, []);

  if (!carMakes?.length) {
    return <Loader text={translate('please_wait')} fullScreen />;
  }

  // useEffect(() => {
  //   if(deleteBottomDrawer) {

  //   }
  // }, [deleteBottomDrawer])

  return (
    <div className="vehicle-page m-b-65">
      <StickyBackButton />
      <BottomDrawer
        isOpen={deleteBottomDrawer}
        title={translate('delete_vehicle')}
        text={translate('delete_vehicle_text')}
        onClose={() => setDeleteBottomDrawer(false)}
      >
        <Button
          label={translate('delete_vehicle')}
          priority="primary"
          variant="cancel"
          icon={<TrashIcon fill="light" height={15} />}
          iconPosition="left"
          fullWidth
          onClick={handleDeleteVehicleById}
          className="m-b-10"
        />
      </BottomDrawer>

      <Typography variant="h4" className="w-10 m-b-30">
        {vehicleType === 'new-car' ? translate('new_vehicle') : translate('change_details')}
      </Typography>
      <Input
        {...register('license')}
        hasValue={Boolean(licenseValue)}
        type="text"
        confirmed={licenseValue?.length >= 3}
        upperCase
        label={translate('your_plate_number')}
        placeholder={translate('plate_number')}
        className="m-b-24 repetitive"
        defaultValue={licenseValue}
        data-testid="input_edit-license"
        iconLeft={<CarIcon />}
      />
      <Controller
        name="carBrand"
        control={control}
        render={({ field: { value, onChange, onBlur } }) => (
          <Dropdown
            placeholder={translate('choose_brand')}
            label={translate('car_brand')}
            className="m-b-24"
            data={carMakes}
            value={value}
            onChange={(brand: IData) => {
              onChange(brand.value);
            }}
            onBlur={onBlur}
            confirmed={!!carBrandValue}
            isSearchable
          />
        )}
      />

      <Controller
        name="carType"
        control={control}
        render={({ field: { value, onChange, onBlur } }) => (
          <Dropdown
            placeholder={translate('choose_type')}
            label={translate('car_type')}
            className="m-t-24"
            data={carSeriesDropdown}
            disabled={Boolean(carBrandValue === 0)}
            value={value}
            onChange={(type: IData) => {
              onChange(type.value);
            }}
            onBlur={onBlur}
            confirmed={!!carTypeValue}
            isSearchable
          />
        )}
      />
      {vehicleType === 'update-car' && (
        <div className="d-flex align-items-center justify-content-center m-t-36">
          <div
            data-testid="button_delete-vehicle"
            className="d-flex align-items-center delete-button"
            onClick={() => setDeleteBottomDrawer(true)}
          >
            <TrashIcon width={19} color="red5" />
            <Typography variant="button" color="red5" className="m-l-15 m-t-1">
              {translate('delete_vehicle')}
            </Typography>
          </div>
        </div>
      )}
      <div className="button-wrapper-bottom w-12 position-fixed bottom-0 left-0 p-16 z-3">
        <Button
          label={vehicleType === 'new-car' ? translate('add_vehicle') : translate('update_details')}
          priority="primary"
          variant="info"
          fullWidth
          // type="submit"
          iconPosition="left"
          onClick={handleAddOrUpdateVehicle}
          disabled={!licenseValue || carBrandValue === 0 || !carTypeValue || carTypeValue === 0}
        />
      </div>
    </div>
  );
};

export default VehiclePage;
