import { useMutation } from '@apollo/client';

import { assignJourneyVehicleQuery } from '../../models/graphql';
import { useAuthenticatedQuery } from './index';
import { useOfflineProofQuery } from './useOfflineProofQuery';

export const useAssignVehicle = () => {
  const authenticatedQuery = useAuthenticatedQuery();
  const offlineProofQuery = useOfflineProofQuery();
  const [assignVehicle] = useMutation(assignJourneyVehicleQuery, {
    fetchPolicy: 'no-cache',
  });

  const handleNewData = (
    res: any,
    callback: (data: any) => void,
  ) => {
    if (callback) callback(res?.data?.assignJourneyVehicle);
  };

  return (
    id: number,
    vehicleId: number,
    callback: (data: any) => void,
  ) => offlineProofQuery(() => authenticatedQuery((accessToken) => {
    assignVehicle({
      variables: {
        journeyId: id,
        vehicleId,
        accessToken,
      },
    }).then((res) => {
      handleNewData(res, callback);
    });
  }));
};
