import { gql } from '@apollo/client/core';

const deleteJourneyQuery = gql`mutation deleteJourney($sessionToken: String!, $journeyDriverCode: String!) {
  deleteJourney(sessionToken: $sessionToken, journeyDriverCode: $journeyDriverCode) {
    success
    message
  }
}`;

export default deleteJourneyQuery;
