import { useQuery } from '@apollo/client';
import { Button } from '@transferz/fe-component-library/button';
import CircleCheckIcon from '@transferz/fe-component-library/circle-check-icon';
import Input from '@transferz/fe-component-library/input';
import PhoneInput from '@transferz/fe-component-library/phone-input';
import Typography from '@transferz/fe-component-library/typography';
import { useLocalize } from 'localize-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import StickyBackButton from '../components/StickyBackButton';
import { GetCacheProfile } from '../models/graphql';
import { useEditDriverData } from '../services/apolloNetworkHooks';
import { routes } from '../services/constant';

const EditProfile: FunctionComponent = () => {
  const navigate = useNavigate();
  const { path } = useParams();
  const { translate } = useLocalize();

  const { data: cachedProfileData } = useQuery(GetCacheProfile);
  const [phoneInput, setPhoneInput] = useState(cachedProfileData?.phone);
  const [isNameValid, setIsNameValid] = useState<boolean>(false);

  const updateDriver = useEditDriverData();

  const { register, watch } = useForm({ defaultValues: { driverName: cachedProfileData.name } });
  const driverName = watch('driverName');

  useEffect(() => {
    if (driverName?.length >= 2) {
      setIsNameValid(true);
    }
    else {
      setIsNameValid(false);
    }
  }, [driverName]);

  const handleClick = () => {
    const driverData = { ...cachedProfileData };

    if (path === 'name') {
      driverData.name = driverName;
    }
    else {
      driverData.phone = phoneInput;
    }

    updateDriver(driverData.id, driverData.phone, driverData.name, () => {
      console.log('INFO Driver data updated successfully');
      navigate(routes.profile, { state: { notification: 'updateProfile' } });
    });
  };

  return (
    <div data-testid="driver-name-page" className="driver-name-page">
      <StickyBackButton />
      <Typography variant="h4" className="m-b-40">
        {path === 'name' ? translate('your_name_label') : translate('mobile_number')}
      </Typography>
      {path === 'name' ? (
        <Input
          {...register('driverName', { required: 'This field is required.' })}
          label={translate('your_name_label')}
          type="text"
          className="m-b-20"
          confirmed={isNameValid}
        />
      ) : (
        <PhoneInput
          onChange={(phone: string) => setPhoneInput(phone)}
          initialValue={phoneInput}
          label={translate('mobile_number')}
          className="custom-phone-input"
          path={`${window.location.origin}/flags/4x3`}
        />
      )}

      <div className="d-flex align-items-center">
        <div className="button-wrapper position-fixed p-16">
          <Button
            label={translate('save')}
            icon={<CircleCheckIcon width={22} />}
            iconPosition="left"
            variant="info"
            priority="primary"
            fullWidth
            disabled={path === 'name' ? driverName?.length < 2 : !phoneInput}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
