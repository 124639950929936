export type NotificationType = {
  text: string,
  variant: 'error' | 'neutral' | 'success'
}

export enum LocationServices {
  UNKNOWN = 'unknown',
  DISABLED = 'disabled',
  ENABLED = 'enabled'
}
